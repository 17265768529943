import {
  Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment,
  InputLabel, MenuItem, Modal, Paper, Select, Tab, Table, TableBody,
  TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography,
} from '@mui/material';
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { localGetItem } from '../../../../Utility/Services/CacheProviderService'
import { Days, FoodUsage, Medication_dosages, Months, TabletQty, Weeks, Year, language } from '../../../../Utility/Constants'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTime } from 'luxon'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'
import ShowComponents from './ShowComponent';
import moment from 'moment/moment'
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Loader from '../../../../Components/Loader'
const Eye_Type = [
  { value: "RE", label: "RE" },
  { value: "LE", label: "LE" },
  { value: "BE", label: "BE" }
]

const Usage_Type = [
  { value: "ES", label: "Empty Stomach" },
  { value: "BF", label: "Before Food" },
  { value: "WF", label: "With Food" },
  { value: "AF", label: "After Food" },
  { value: "EA", label: "External Application" },
  { value: "IA", label: "Internal Application" },
]

const dropFreq = [
  { value: "1", label: "1 time a day" },
  { value: "2", label: "2 times a day" },
  { value: "3", label: "3 times a day" },
  { value: "4", label: "4 times a day" },
  { value: "5", label: "5 times a day" },
  { value: "6", label: "6 times a day" },
  { value: "10", label: "10 times a day" },
  { value: "12", label: "12 times a day" }
];

const duration_type = [
  { value: "Day(s)", label: "Days" },
  { value: "Week(s)", label: "Weeks" },
  { value: "Month(s)", label: "Months" },
];

const medicDurationList = [
  { value: "Days", label: "Days" },
  { value: "Weeks", label: "Weeks" },
  { value: "Months", label: "Months" },
  { value: "Until_the_Next_Visit", label: "Until the Next Visit" },
  { value: "Long_Term", label: "Long Term" }
];

export default class DocMedication extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTemplate: '',
      languageList: language,
      selectedLanguage: 'english',
      openPopupAddNew: false,
      tabletQty: TabletQty,
      foodUsage: FoodUsage,
      tempType: '',
      appointmentId: this.props.appointmentId,
      clinicId: this.props.clinicId,
      patientId: this.props.patientId,
      appointmentDate: this.props.appointmentDate,
      isLocked: false,
      prescriptionData: [],
      prescription: [],
      pharmacyList: [],
      selectedPharmacy: '',
      drugList: [],
      selectedDrug: '',
      searchedBrandName: null,
      searchedGenericName: null,
      genericList: [],
      dosageStrength: '',
      dosageType: '',
      morningQty: '0',
      afternoonQty: '0',
      eveningQty: '0',
      nightQty: '0',
      selectedUsage: '',
      selectedDays: '1',
      selectedDuration: 'Days',
      durationDays: Days,
      durationWeeks: Weeks,
      durationMonts: Months,
      durationYears: Year,
      prescriptionList: [],
      comments: '',
      favourite: false,
      selectedDrugId: null,
      prescriptionId: null,
      dupliPrescriptionData: '[]',
      prescriptionFav: [],
      openFav: false,
      selectedPrescripFav: [],
      openTransmit: false,
      open: false,
      selectedTransmitPharmacy: '',
      selectedFollowup: null,

      prevPrescripData: [],
      previousPrescripDate: [],
      selectedPrevDate: null,
      page: 0,
      pageSize: 10,
      deletePopup: false,
      removeData: false,
      selectedId: null,
      isEdit: false,
      selectedIndex: null,
      transmitted: false,

      openMedicPopUp: false,
      value: "One",
      MedicationFav: [],
      MedicFavorite: [],
      age: "",
      MedDuration: "",
      TapperDrugList: [],
      MedicDrugList: [],
      tapp_Gen_Details: {},
      Medic_Gen_Details: {},
      taperFromDate: new Date(),
      Frequency: "",
      tapDuration: "",
      durationType: "Day(s)",
      TapperUnits: "",
      MedicUnits: "",
      unitType: "",
      reducedBy: "",
      tapperQty: "",
      tapperComm: "",
      is_MefiFav: false,
      is_TapperFav: false,
      taperAddedDetails: [],
      selectedEditIndex: null,
      TapperMedList: [],
      Medfrequency: "",
      frequencyTemplateList: [],
      NewText: "",
      selectedText: false,
      MedQty: "",
      medCommends: "",
      Morning: "",
      Afternoon: "",
      Evening: "",
      Night: "",
      MedUsage: "",
      MedicData: [],
      eyeType: "",
      no_of_Dosage: null,
      openPrevsMedic: false,
      MedicationTemplateData: [],
      MedicationTemplateList: [],
      previousMedicData: [],
      editItem: null,
      ExternalDrugAdd: false,
      External_Drug_Details: {
        DrugName: "",
        GenericName: "",
        DosageStrength: "",
      },
      EditIndex: null,
      AddClicked: false,
      FrequencyText: "",
      DrugSuggestionList: [],
      AllowEdit: false,
      Recalled: false,
      recallPopup: false,
      ExternalBrandName: null,
      ExternalDrugList: [],
      GenericSuggestionList: [],
      GenericNameSearch: null,
      GenericSearch: "",
      newMedication: false,
      selectedPrescripID: null,
      RecallPresId: null,
      frequency_tablet: "",
      frequency_list: [],
      tapUsage:'',
      drugSearchFor:1,
      newTapMedListData: [],
      leftSelectedIndex: null,
      newLftTapEdit: false,
      templateId: null,
      optTemplateId: null,
      isTemplateAdded: true,
    }
  }

  componentDidMount(){
    let drugSearch = localGetItem('drug_search') !== 'undefined' ? JSON.parse(localGetItem('drug_search')) : 1
    this.setState({ drugSearchFor: drugSearch })
  }

  apiCalls = () => {
    this.getPrescriptionFavouriteData()
    this.getTapperMed()
    this.getMedicFrequency()
    this.getMedicationListData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLocked !== this.props.isLocked) {
      this.setState({ isLocked: this.props.isLocked })
    }
  }

  getMedicationListData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_MEDIC_TEMPLATE_GET_POST)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              MedicationTemplateList: response?.data?.data
            })
          }
        }).catch((error) => {
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  addFrequencyKey = (Data) => {
    const newArray = Data.map((obj) => {
      if (!obj.hasOwnProperty('no_of_drops')) {
        obj = { ...obj, no_of_drops: null };
      }

      if(!this.state.isTemplateAdded && !obj.hasOwnProperty('drug_ms_id')){
        obj = { ...obj, drug_ms_id: obj?.drug_id };
      }

      return {
        ...obj,
        eye_type: obj.eye_type || "",
        is_tapper_meds: obj?.tapper_meds?.length > 0
      };
    });

    return newArray
  };

  PostNewMedicTemplate = () => {
    const states = this.state;
    const { TapperMedList, selectedPharmacy, tempType, templateId } = this.state;
    const lastTapperMed = TapperMedList?.[TapperMedList?.length - 1];
   
    try {
      const data = {
        "template_name": states?.templateName,
      }
      if (tempType === "rename" && lastTapperMed?.prescription_lines) {
        lastTapperMed.prescription_lines?.forEach(element => {
          element.template_line_id = (element.template_id === templateId)
            ? element.template_line_id
            : null;
          element.eye_type = element.eye_type || "";
        });
        data["template_id"] = states?.templateId;
      }
      const pharmaData = this.state.isTemplateAdded ? this.addFrequencyKey(lastTapperMed?.prescription_lines) : this.addFrequencyKey(this.state.MedicationTemplateData);
      const PharmaID = pharmaData?.find(item => item?.pharmacy_id === selectedPharmacy)?.pharmacy_id;

      data["prescription"] = pharmaData;
      data["pharmacy_id"] = PharmaID;

      if ((TapperMedList[TapperMedList.length - 1]?.prescription_lines?.length === 0 && this.state.tempType === 'Add')) {
        this.errorMessage("Template Should not be Empty")
      } else if (states?.templateName === "") {
        this.errorMessage("Enter Template Name");
      } else {
        RestAPIService.create(data, Serviceurls.DOC_MEDIC_TEMPLATE_GET_POST)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getTapperMed()
              this.getMedicationListData()
              this.clearTapperDetails()
              this.setState({
                openMedicPopUp: false,
                openPopupAddNew: false,
                isTemplateAdded:true,
                MedicationTemplateData: []
              })
            }
          }).catch((error) => {
            this.errorMessage(error?.response?.data?.message || error.message)
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  modifyTemplate = (Array) => {
    return Array.map(obj => {
      // removing id key from the obj
      const { id, pharmacy_id, ...rest } = obj;
      const newObj = {
        ...rest,
        opt_template_line_id: obj.line_id,
        frequency: obj.frequency || null,
        is_tapper_meds: obj.tapper_meds.length > 0,
      };
      return newObj;
    });
  };

  MedicationTemplateSave = () => {
    const states = this.state
    try {

      const NewArray = this.modifyTemplate(states.MedicationTemplateData);
      const data = {
        "appointment_id": states?.appointmentId,
        "patient_id": states?.patientId,
        "line_items": states.TapperMedList?.prescription_lines?.length > 0 ? [...NewArray, ...states.TapperMedList?.prescription_lines] : NewArray,
        "followup_date": "",
        "deleted_line_ids": [],
        "pharmacy_id": states?.selectedPharmacy,
        "from_temp": true
      }
      if (states.prescriptionId) {
        data["prescription_id"] = states.prescriptionId
      }
      if (states.MedicationTemplateData.length > 0) {
        RestAPIService.create(data, Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getTapperMed()
              this.clearTapperDetails()
              this.setState({ openMedicPopUp: false, isTemplateAdded: true })
            }
          }).catch((error) => {
            this.setState({isTemplateAdded: false})
            this.errorMessage(error?.response?.data?.message || error.message)
          })
      } else {
        this.errorMessage("Template Should not be Empty");
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicationTemplateData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_MEDIC_TEMPLATE_GET_POST + "?template_id=" + this.state.selectedTemplate?.id)
        .then((response) => {
          if (response?.data?.status === 'success') {
            const Data = response.data;
            this.setState({
              MedicationTemplateData: Data?.data?.map(item => {
                item["pharmacy_id"] = Data?.pharmacy_id;
                return item;
              })
            }, () => {
              this.MedicationTemplateSave()
            })
          }
        }).catch((error) => {
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicFrequency = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_MEDICATION_FREQUENCY)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let data = response.data.data
            const uniqueArray = [...new Set(data)];
            this.setState({
              frequencyTemplateList: uniqueArray,
              frequency_list: response.data?.tapper_data ? response.data?.tapper_data : []
            })
          }
        }).catch((error) => {
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  FindTransmittedIndex(data) {
    for (let i = 0; i < data.length; i++) {
      if (!data[i].is_transmitted) {
        return i;
      }
    }
    return -1;
  }

  getTapperMed = (FromPopup) => {
    try {
      this.setState({ DisableBtn: true })
      RestAPIService.getAll(Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST + `?patient_id=${this.props.patientId}&appointment_id=${this.props.appointmentId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let Index = this.FindTransmittedIndex(response.data.data)
            this.setState({
              prescriptionData: response.data.data,
              TapperMedList: response.data.data,
              prescriptionId: response.data.data[Index]?.prescription_id || null,
              transmitted: response.data.data[response.data?.data.length - 1]?.is_transmitted,
              Recalled: response.data?.data[response.data?.data.length - 1]?.is_billed_in_pharmacy,
              DisableBtn: false
            }, () => {
              console.log(this.state.prescriptionId, "prescription id form get taper")
              if (FromPopup) {
                let LineItems = []
                if (this.state.prescriptionData?.prescription_lines) {
                  this.state.prescriptionData?.prescription_lines?.forEach((item, index) => {
                    if (item?.tapper_meds?.length === 0) {
                      LineItems.push(item)
                    }
                  })
                }
                this.setState({ MedicData: LineItems, AddClicked: true }, () => { this.clearTapperDetails() })
              }
              if (response.data.data?.is_transmitted) {
                this.props.MedicTransmited(true)
              } else if (response.data.data?.is_transmitted == false) {
                this.props.MedicTransmited(false)
              }
            })
          }
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  fetchFavoriteDrugs = () => {
    this.getMedicationFavouriteData()
    this.getMedFavouriteData()
  }

  getPharmacyList = (prevPrescrip = false, formTransmit = false, PrescriptionItem) => {
    try {
      const LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null;
      const pharmaList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : [];
      const commonState = {
        pharmacyList: pharmaList,
        selectedPharmacy: pharmaList[0]?.pharmacy_id,
      }
      if (formTransmit) {
        this.setState({
          ...commonState,
          openTransmit: pharmaList?.length > 1
        }, () => {
          if (pharmaList?.length <= 1 && !this.state.DisableBtn) {
            this.postPrescriptionTransmit()
          }
        })
      } else {
        const prescriptionData = this.state.prescriptionData[this.state.prescriptionData.length - 1]?.prescription_id || this.state.selectedPharmacy;
       
        let updateState = {};
        if (!this.state.editItem) {
          const { LineItems, tapMedLineItem } = (PrescriptionItem?.prescription_lines || []).reduce(
            (acc, item) => {
              if (item?.tapper_meds?.length === 0) {
                acc?.LineItems.push(item);
              } else {
                acc?.tapMedLineItem.push(item);
              }
              return acc;
            }, { LineItems: [], tapMedLineItem: [] });
          updateState = {
            MedicData: LineItems,
            AddClicked: true,
            newTapMedListData: tapMedLineItem,
            prescriptionId: PrescriptionItem?.prescription_id
          }
          console.log(this.state.prescriptionId, PrescriptionItem?.prescription_id, "from get pharmacy on edit item present")
        }
        if (prescriptionData) {
          this.setState({
            ...commonState,
            prescriptionList: PrescriptionItem?.prescription_lines ? PrescriptionItem?.prescription_lines : PrescriptionItem || [],
            ...updateState
          }, () => {
            if (this.state.editItem) {
              this.onEditMedic(this.state.editItem)
            }
            this.fetchFavoriteDrugs();
          })
        } else if (prevPrescrip) {
          this.setState({
            ...commonState,
            openPrevsMedic: false,
            openMedicPopUp: true,
          }, () => {
            this.fetchFavoriteDrugs();
          })
        } else {
          this.setState({
            ...commonState,
            openMedicPopUp: true,
            AddClicked: true,
          }, () => {
            this.fetchFavoriteDrugs();
          })
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getTapperDrugSearchList = (brandName) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_FREQUENT_MEDICATION + `?brand_name=${brandName}&clinic_id=${this.props.clinicId}&pharmacy_id=${this.state.selectedPharmacy}&is_tapper_meds=true`)
        .then((response) => {
          if (response.data.status === 'success') {
            let BrandName = response?.data?.data.map(item => item?.drug_name)
            this.setState({
              TapperDrugList: BrandName
            })
          }
        }).catch((error) => {
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getExternalDrugSearchList = (brandName, genericName) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_EXTERNAL_DRUG + `?brand_name=${brandName}&generic_name=${genericName}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              ExternalDrugList: response?.data?.data
            })
          }
        }).catch((error) => {
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicDrugSearchList = (searchValue) => {
    try {
      let params = '';
      if (this.state.drugSearchFor === 1) {
        params = `?brand_name=${searchValue}&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
      } else if (this.state.drugSearchFor === 2) {
        params = `?brand_name=&generic_name=${searchValue}&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
      } else {
        params = `?search_key=${searchValue}&brand_name=&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
      }
      RestAPIService.getAll(Serviceurls.DOC_OPT_FREQUENT_MEDICATION + params)
        .then((response) => {
          if (response.data.status === 'success') {
            const data = response?.data?.data
            const foundSet = new Set();
            const uniqueArray = data?.filter((item) => {
              if (foundSet.has(item.drug_name)) return false;
              foundSet.add(item.drug_name);
              return true;
            });
            this.setState({ MedicDrugList: this.state.drugSearchFor === 1 ? uniqueArray : data })
          }
        }).catch((error) => {
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getTapperDrugDetails = (isForEdit = false, FromGeneric, DosageType, fromFreq = false) => {
    try {
      const hasGenericName = this.state.value === 'One' && !((+this.state.drugSearchFor === 1) || (fromFreq));
      let params = `?brand_name=${encodeURIComponent(this.state.selectedDrug)}&clinic_id=${this.props.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`;
      if (hasGenericName) {
        params = `${params}&generic_name=${encodeURIComponent(this.state.GenericNameSearch)}`
      }
      RestAPIService.getAll(Serviceurls.DOC_OPT_MEDICATION_DETAILS + params)
        .then((response) => {
          if (response.data.status === 'success') {
            const Data = response?.data?.data;
            var strength = Data[0]['dosage_strength'] ? Data[0]['dosage_strength'] : '';
            var numericAlone = strength.match(/(\d+)/);
            if (this.state.value === "One") {
              if (FromGeneric && DosageType) {
                const results = Data?.filter(item => (item?.generic_name?.toLowerCase() === FromGeneric?.toLowerCase() && item?.dosage_type?.toLowerCase() === DosageType?.toLowerCase()));
                const result = results[0] || {};
                const drugDetail = { drug_name: result?.drug_name, generic_name: result?.generic_name };
                this.setState({
                  searchedBrandName: drugDetail,
                  GenericNameSearch: drugDetail,
                  DrugSuggestionList: Data,
                  selectedDrugId: isForEdit ? this.state.selectedDrugId : (Data?.length > 0 ? result?.drug_id : this.state.selectedDrugId),
                  Medic_Gen_Details: isForEdit ? { ...this.state.Medic_Gen_Details, stock_in_quantity: result?.quantity }
                    : (Data?.length > 0 ? { ...result, stock_in_quantity: result?.quantity } : this.state.Medic_Gen_Details),
                }, () => {
                  if (!isForEdit) {
                    if (Object.keys(this.state.Medic_Gen_Details).length > 0) {
                      this.autofetchDerugDetails(this.state.Medic_Gen_Details)
                    }
                  }
                })
              } else if (Array.isArray(Data) && Data?.length > 0) {
                const firstDrug = Data[0];
                const drugDetails = { drug_name: firstDrug?.drug_name, generic_name: Data?.length > 1 ? "" : firstDrug?.generic_name }
                this.setState({
                  searchedBrandName: isForEdit ? this.state.searchedBrandName : drugDetails,
                  GenericNameSearch: isForEdit ? this.state.GenericNameSearch : drugDetails,
                  GenericSuggestionList: Data.length > 1 ? Data : [],
                  DrugSuggestionList: Data,
                  selectedDrugId: isForEdit ? this.state.selectedDrugId : (Data?.length > 0 ? firstDrug?.drug_id : this.state.selectedDrugId),
                  Medic_Gen_Details: isForEdit ? {
                    ...this.state.Medic_Gen_Details,
                    stock_in_quantity: Data?.length === 1 ? firstDrug?.quantity
                      : Data?.find(item => item.drug_id === this.state.Medic_Gen_Details.drug_id)?.quantity
                      || this.state.Medic_Gen_Details?.stock_in_quantity
                  }
                    : (Data?.length > 1 ? {} : Data?.length === 1 ? { ...firstDrug, stock_in_quantity: firstDrug?.quantity }
                      : this.state.Medic_Gen_Details || {}),
                }, () => {
                  if (!isForEdit) {
                    if (Object.keys(this.state.Medic_Gen_Details).length > 0) {
                      this.autofetchDerugDetails(this.state.Medic_Gen_Details)
                    }
                  }
                })
              }
            } else if (this.state.value === "Two") {
              const firstDrug = Data[0];
              this.setState({
                tapp_Gen_Details: isForEdit ? { ...this.state.tapp_Gen_Details, stock_in_quantity: firstDrug?.quantity }
                  : { ...firstDrug, stock_in_quantity: firstDrug?.quantity },
                TapperUnits: this.state.isEdit ? "" :
                  (firstDrug['dosage_type'] ? (firstDrug['dosage_type']).toUpperCase() === "STEROID TABLETS" ?
                    numericAlone[0] : (firstDrug['dosage_type']) === "Drop" ? "1" : "" : ""),
                is_TapperFav: firstDrug['is_favorite']
              })
            }
          }
        }).catch((error) => {
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  autofetchDerugDetails = (item) => {
    const Pre_durgList = item?.latest_prescription ? item?.latest_prescription : {}
    if (Object.keys(Pre_durgList).length > 0) {
      const drugValue = Pre_durgList?.duration?.split(" ");
      const [numStr, textStr] = drugValue?.length >= 2 ? drugValue : ["", Pre_durgList?.duration];
      this.setState({
        eyeType: Pre_durgList?.eye_type,
        Medfrequency: Pre_durgList?.frequency__description,
        MedDuration: numStr,
        selectedDuration: textStr,
        MedUsage: Pre_durgList?.instructions,
        MedQty: Pre_durgList?.quantity,
        medCommends: Pre_durgList?.comments,
      })
    }
  }

  getGenericNameList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_GENERIC_NAME_GET + `?brand_name=${this.state.selectedDrug}&generic_name=&clinic_id=${this.props.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              genericList: response.data.data,
              searchedGenericName: response?.data?.data?.length === 1 ? response?.data?.data[0] : '',
            }, () => {
              this.setState({
                dosageStrength: this.state.searchedGenericName?.dosage_strength,
                dosageType: this.state.searchedGenericName?.dosage_type
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPrescriptionPrint = () => {
    try {
      this.setState({ DisableBtn: true })
      RestAPIService.getAllTwo(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_PRINT + `?prescription_id=${this.state.prescriptionId}&clinic_id=${this.props.clinicId}&language=${this.state.selectedLanguage}&appointment_id=${this.props.appointmentId}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ DisableBtn: false })
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicationPrint = (id) => {
    try {
      this.setState({ DisableBtn: true })
      RestAPIService.getAllTwo(Serviceurls.OPT_PRESCRIPTION_PRINT + `?prescription_id=${id}&clinic_id=${this.props.clinicId}&language=${this.state.selectedLanguage}&appointment_id=${this.props.appointmentId}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ DisableBtn: false })
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPrescriptionFavouriteData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_FAV)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              prescriptionFav: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicationFavouriteData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_FREQUENT_MEDICATION + `?pharmacy_id=${this.state.selectedPharmacy}&is_tapper_meds=true`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let BrandName = response?.data?.data.map(item => item?.drug_name)
            this.setState({
              MedicationFav: BrandName
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedFavouriteData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_FREQUENT_MEDICATION + `?pharmacy_id=${this.state.selectedPharmacy}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              MedicFavorite: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousPrescriptionDates = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT_DATE_GET + `?patient_id=${this.props.patientId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              previousPrescripDate: response.data.prescription,
              selectedPrevDate: response?.data?.prescription[0],
              selectedPrevAppointmentId: response?.data?.prescription[0]?.appointment
            }, () => {
              if (this.state.previousPrescripDate?.length > 0) {
                this.getPreviousPrescriptionDatas()
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousMedicDatas = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_MEDICATION_HISTORY + `?patient_id=${this.props.patientId}&appointment_id=${this.props.appointmentId}&appointment_date=${this.props.appointmentDate}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              previousMedicData: response.data.data,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousPrescriptionDatas = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT + `?patient_id=${this.props.patientId}&appointment_id=${this.state.selectedPrevDate?.appointment}&date=${this.state.selectedPrevDate?.prescription_date}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              prevPrescripData: response.data.prescription_lines
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousPrescriptionPrint = () => {
    try {
      let appointmentId = this.state.selectedPrevDate?.appointment ? this.state.selectedPrevDate?.appointment : this.props.appointmentId
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT + `?patient_id=${this.props.patientId}&appointment_id=${appointmentId}&date=${this.state.selectedPrevDate?.prescription_date}&export_type=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteMedic = (item, FromPopup = false) => {
    try {
      RestAPIService.deleteAll(`${Serviceurls.DOC_MEDICATION_DLT}?id=${item?.id}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getTapperMed(FromPopup)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postTemplate = () => {
    try {
      let data = {}
      if (this.state.tempType === 'Add') {
        let prescriptionList = []
        this.state.prescriptionData?.prescription_lines?.forEach(element => {
          prescriptionList?.push({
            afternoon: +element?.afternoon,
            dosage_strength: element?.dosage_strength,
            dosage_type: element?.dosage_type,
            drug_name: element?.brand_name,
            duration: element?.duration,
            duration_mode: element?.duration_mode,
            evening: +element?.evening,
            generic_name: element?.generic_name,
            instructions: element?.instructions,
            morning: +element?.morning,
            night: +element?.night
          })
        })
        data = {
          pharmacy_id: this.state.selectedPharmacy,
          template_name: this.state.templateName,
          prescription: prescriptionList
        }
      } else if (this.state.tempType === 'rename') {
        data = {
          template_name: this.state.templateName,
          template_id: this.state.selectedTemplate?.id
        }
      }
      if (this.state.templateName === '') {
        this.errorMessage('Enter Template Name')
      } else {
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_TEMPLATE)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.setState({ tempType: '', openPopupAddNew: false, selectedTemplate: '', templateName: '' })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  removedPrescription = () => {
    try {
      let removedIds = []; let prescriptionHash = {};
      let duplicate = JSON.parse(this.state.dupliPrescriptionData)
      this.state.prescriptionList?.forEach(element => {
        prescriptionHash[element.id] = element
      })

      duplicate?.prescription_lines?.forEach(element => {
        if (!prescriptionHash[element.id]) {
          removedIds.push(element.id)
        }
      })

      return removedIds;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postPrescriptionData = (fromFav = false, fromTemplate = false) => {
    try {
      let removedIds = []
      if (this.state.selectedPrescripFav?.length < 0) {
        removedIds = this.removedPrescription()
      } else if (this.state.prescriptionId) {
        removedIds = this.removedPrescription()
      }
      let date = this.state.selectedFollowup ? new Date(this.state.selectedFollowup) : null
      let followUpDate = this.state.selectedFollowup ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') : null
      let data = {
        appointment_id: this.props.appointmentId,
        deleted_line_ids: removedIds,
        followup_date: this.state.selectedFollowup ? followUpDate : null,
        patient_id: this.props.patientId,
      }
      console.log(this.state.prescriptionId, "from post prescription")
      if (this.state.prescriptionId) {
        data.prescription_id = this.state.prescriptionId
      } else {
        data.pharmacy_id = this.state.selectedPharmacy
      }
      if (this.state.selectedPrescripFav?.length > 0 && fromFav) {
        data.line_items = this.state.selectedPrescripFav
      } else if (this.state.selectedTemplate && fromTemplate) {
        let prescriptionData = this.state.prescriptionData?.map(element => {
          return ({
            afternoon: element?.afternoon ? (element?.afternoon)?.toString() : '0',
            brand_name: element?.brand_name ? element?.brand_name : '',
            comments: element?.comments ? element?.comments : '',
            dosage_strength: element.dosage_strength ? element.dosage_strength : '',
            dosage_type: element.dosage_type ? element.dosage_type : '',
            drug_id: element.drug_id ? element.drug_id : '',
            duration: element.duration ? element.duration : '',
            duration_mode: element.duration_mode ? element.duration_mode : '1',
            evening: element.evening ? (element.evening).toString() : '0',
            generic_name: element.generic_name ? element.generic_name : '',
            instructions: element.instructions ? element.instructions : '',
            is_favorite: element.is_favorite,
            morning: element.morning ? (element.morning.toString()) : '0',
            night: element.night ? (element.night).toString() : '0'
          })
        })
        data.line_items = prescriptionData
      } else {
        data.line_items = this.state.prescriptionList
      }
      this.setState({ DisableBtn: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({
              openPrescriptionPopUp: false,
              prescriptionList: [],
              selectedPrescripFav: [],
              openFav: false,
              selectedId: null,
              DisableBtn: false
            })
            if (fromFav || fromTemplate) {
              this.setState({ selectedTemplate: '' })
            }
            this.getPrescriptionFavouriteData()
            removedIds = []
          }
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postPrescriptionTransmit = (recalled) => {
    try {
      let data = {}
      if (recalled) {
        data = {
          appointment_id: this.props.appointmentId,
          prescription_id: this.state.RecallPresId,
          is_recall: true,
          patient_id: this.props.patientId
        }
      } else {
        data = {
          appointment_id: this.props.appointmentId,
          patient_id: this.props.patientId,
          pharmacy_id: this.state?.selectedTransmitPharmacy ? this.state?.selectedTransmitPharmacy : this.state.selectedPharmacy,
          prescription_id: this.state.prescriptionId
        }
      }
      this.setState({ DisableBtn: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_TRANSMIT)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ openTransmit: false, selectedTransmitPharmacy: '', transmitted: true, recallPopup: false, DisableBtn: false, RecallPresId: null }, () => {
              this.getTapperMed()
            })
          }
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  deleteTemplate = () => {
    try {
      let data = {}
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.DOC_MEDIC_TEMPLATE_GET_POST + `?template_id=${this.state.selectedTemplate?.id}`, data)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({
              removeData: false,
              deletePopup: false,
              tempType: '',
              openPopupAddNew: false,
              selectedTemplate: '',
              templateName: ""
            })
            this.getMedicationListData()
            this.getTapperMed()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  deleteFrequencysugg = () => {
    try {
      this.setState({ removeData: true })
      RestAPIService.delete(`${Serviceurls.DOC_OPT_MEDICATION_FREQUENCY}?description=${this.state.SelectedDeleteChip}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getMedicFrequency()
            this.setState({ deletePopup: false, SelectedDeleteChip: null, removeData: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopup: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let message = null;
      let states = this.state
      if (states.selectedDrug === '') {
        message = 'Select Brand name'
      } else if (states.searchedGenericName === null) {
        message = 'Select Generic Name'
      } else if (states.dosageStrength === '' || states.dosageType === '') {
        message = 'Dosage Strength and Dosage Type is Empty'
      } else if ((states.selectedDrug !== '') && (states.searchedGenericName === null) && (states.dosageStrength === '') && (states.dosageType === '')) {
        if ((states.morningQty !== '0') || (states.afternoonQty !== '0') || (states.eveningQty !== '0') || (states.nightQty !== '0')) {
          message = null
        } else {
          message = 'Please Select MAEN Dosage Quantity'
        }
      } else if (states.selectedDays === '' || states.selectedDuration === '') {
        message = 'Select duration'
      } else if (states.selectedUsage === '') {
        message = 'Select Usage'
      }

      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRecallPopup = () => {
    return (
      <Dialog
        open={this.state.recallPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0.5vw', marginBottom: '0vw' }}>
          <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={() => { this.setState({ recallPopup: false }) }} />
        </div>
        <DialogTitle id="alert-dialog-title">
          {`Are you sure ? Do you want to Edit Medication`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: '0.8vw' }} id="alert-dialog-description">
            {""}
          </DialogContentText>
        </DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "flex-end" }}>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button size="small" variant="outlined" onClick={() => { this.setState({ recallPopup: false }) }}>
              {"CLOSE"}
            </Button>
            <Button size="small" variant="contained" onClick={() => { this.postPrescriptionTransmit(true) }}>
              {"RECALL"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }

  handleDatePicker = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB');
    return formattedDate.split("/").reverse().join("-");
  }

  taperDetailAdd = () => {
    var data = {}
    var states = this.state
    const dosageType = (states.tapp_Gen_Details?.dosage_type)?.toUpperCase() === "STEROID TABLETS";
    var validDate = false
    var timeValid = true
    var taperAddedDetail = states.taperAddedDetails
    var frequency =  dosageType ? "" : states.Frequency
    var fromDate = this.handleDatePicker(states.taperFromDate)
    var reducedBy = dosageType ? states.reducedBy : ""
    var d = new Date(this.handleDatePicker(states.taperFromDate))
    if (d.toString() != "Invalid Date") {
      validDate = true
    }
    if (Object.keys(this.state.tapp_Gen_Details)?.length > 0) {
      if (validDate && ((states.TapperUnits)?.trim() !== "") && ((states.tapDuration)?.trim() !== "") &&
        (
          // this is for checking drops dosage type
          ((states.tapp_Gen_Details?.dosage_type)?.toUpperCase() !== "STEROID TABLETS" && (states.Frequency)?.trim() !== "" && states.eyeType) ||
          // this is for checking steroid tablet dosage type
          (dosageType && (states.reducedBy)?.trim() !== "")
        ) && timeValid && states.tapperQty
      ) {

        var duration = states.tapDuration

        if (states.durationType === "Week(s)") {
          duration = parseInt(states.tapDuration) * 7
        } else if (states.durationType === "Month(s)") {
          duration = parseInt(states.tapDuration) * 30
        }

        var date = new Date(fromDate);
        date.setDate(date.getDate() + parseInt(duration));

        var dosageCountLabel = dosageType ? 'Mg' : 'Drop(s)'

        var frequencyLabel = dosageCountLabel === "Drop(s)" ? (frequency == 1) ? ' time a day' : ' times a day' : this.state.frequency_tablet

        data = {
          start_date: fromDate,
          no_of_dosage: states.TapperUnits + " " + dosageCountLabel,
          duration: states.tapDuration + " " + states.durationType,
          end_date: moment(date).format("YYYY-MM-DD"),
          frequency: frequency + frequencyLabel,
          reducedBy: reducedBy,
          instructions : states.tapUsage ? states.tapUsage : '',
          eye_type: states.eyeType,
        }

        if ((states.selectedEditIndex || states.selectedEditIndex === 0)) {
          taperAddedDetail[states.selectedEditIndex]["start_date"] = fromDate
          taperAddedDetail[states.selectedEditIndex]["no_of_dosage"] = states.TapperUnits + " " + dosageCountLabel
          taperAddedDetail[states.selectedEditIndex]["duration"] = states.tapDuration + " " + states.durationType
          taperAddedDetail[states.selectedEditIndex]["end_date"] = moment(date).format("YYYY-MM-DD")
          taperAddedDetail[states.selectedEditIndex]["frequency"] = dosageType ? states.frequency_tablet : states.Frequency + frequencyLabel
          taperAddedDetail[states.selectedEditIndex]["reducedBy"] = states.reducedBy
          taperAddedDetail[states.selectedEditIndex]['instructions'] = states.tapUsage ? states.tapUsage : ''

        } else if (!states.selectedEditIndex && states.selectedEditIndex !== 0) {
          taperAddedDetail.push(data)
        }

        if (frequency == 1) {
          frequency = frequency
        } else if (frequency == 12) {
          frequency = 10
        } else if (frequency == 10) {
          frequency = 6
        } else {
          frequency = parseInt(frequency) - 1
        }

        var unitVal = ""
        var calc = parseInt(states.TapperUnits) - parseInt(states.reducedBy)
        if (dosageCountLabel === "Mg" && calc > 0) {
          unitVal = calc
        } else {
          unitVal = states.TapperUnits
        }

        var nextStartDate = new Date(date)
        nextStartDate.setDate(nextStartDate.getDate() + 1)
        fromDate = moment(nextStartDate).format("YYYY-MM-DD")


        this.setState({
          taperAddedDetails: taperAddedDetail,
          taperFromDate: moment(fromDate).format("YYYY-MM-DD"),
          TapperUnits: unitVal + "",
          hour12Formet: "00",
          minutes: "00",
          taperInterval: "",
          Frequency: frequency + "",
          selectedEditIndex: null,
        })
      } else {
        if (!timeValid) {
          this.errorMessage("Invalid Time Interval");
        } else if (!validDate) {
          this.errorMessage("Invalid Date");
        } else if (!states.tapDuration) {
          this.errorMessage('Enter Duration');
        } else if (!states.TapperUnits) {
          this.errorMessage('Enter Unit');
        } else if (!states.reducedBy && dosageType) {
          this.errorMessage('Enter Reduce By');
        } else if (!states.Frequency && !dosageType){
          this.errorMessage('Select Frequency');
        } else if (!states.tapperQty) {
          this.errorMessage('Enter Quantity');
        } else if (!states.eyeType && !dosageType ){
          this.errorMessage('Select Eye Type');
        } else {
          this.errorMessage("Invalid Details can't be added");
        }
      }
    } else {
      this.errorMessage("Select Drug Name");
    }

  }

  MedicTableDetails = () => {
    let states = this.state
    try {
      let DosageType = states?.ExternalDrugAdd ? (this.state.External_Drug_Details?.dosage_type ? this.state.External_Drug_Details?.dosage_type : "") : (states.Medic_Gen_Details?.dosage_type ? states.Medic_Gen_Details?.dosage_type : "");
      let Duration = `${this.state.MedDuration} ${this.state.selectedDuration == "Until_the_Next_Visit" ? "Until the Next Visit" : this.state.selectedDuration == "Long_Term" ? "Long Term" : this.state.selectedDuration}`

      let Medication = [{
        "eye_type": this.state.eyeType ? this.state.eyeType : "",
        "brand_name": states?.ExternalDrugAdd ? this.state.External_Drug_Details?.DrugName : (states?.Medic_Gen_Details?.drug_name ? states?.Medic_Gen_Details?.drug_name : states?.Medic_Gen_Details?.brand_name),
        "generic_name": states?.ExternalDrugAdd ? this.state.External_Drug_Details?.GenericName : states?.Medic_Gen_Details?.generic_name,
        "dosage_strength": states?.ExternalDrugAdd ? this.state.External_Drug_Details?.DosageStrength : states.Medic_Gen_Details?.dosage_strength,
        "drug_id": states?.ExternalDrugAdd ? null : (states.Medic_Gen_Details?.drug_ms_id ? states.Medic_Gen_Details?.drug_ms_id : states.Medic_Gen_Details?.drug_id),
        "dosage_type": DosageType === 'Drops' ? 'Drop' : DosageType,
        "frequency": this.state.Medfrequency,
        "duration": Duration,
        "instructions": states?.MedUsage,
        "comments": this.state.medCommends,
        "quantity": this.state.MedQty,
        "tapper_meds": [],
        "pharmacy_id": this.state.selectedPharmacy,
        "isAddNewClick": false,
        "is_favorite": states?.ExternalDrugAdd ? false : (this.state.Medic_Gen_Details?.is_favorite ? this.state.Medic_Gen_Details?.is_favorite : false),
        "morning": states?.Morning ? states?.Morning : 0,
        "afternoon": states?.Afternoon ? states?.Afternoon : 0,
        "evening": states?.Evening ? states?.Evening : 0,
        "night": states?.Night ? states?.Night : 0,
        "no_of_dosage": states?.ExternalDrugAdd ? (states.External_Drug_Details?.no_of_dosage ? states.External_Drug_Details?.no_of_dosage : 0) : (states.Medic_Gen_Details?.no_of_dosage ? states.Medic_Gen_Details?.no_of_dosage : 0),
        "opt_template_line_id": states.optTemplateId ? states.optTemplateId : null
      }]
      let data = {
        "appointment_id": this.props?.appointmentId,
        "patient_id": this.props?.patientId,
        "line_items": this.state.AddClicked ? this.state?.MedicData : Medication,
        "followup_date": "",
        "deleted_line_ids": [],
        "pharmacy_id": states?.selectedPharmacy
      }

      if (this.state?.medicId && this.state?.isEdit) {
        Medication[0]["id"] = this.state.medicId
      }
      if (this.state.prescriptionId) {
        data["prescription_id"] = this.state.prescriptionId
      }
      this.setState({ DisableBtn: true })
      RestAPIService.create(data, Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getTapperMed()
            this.onClearHandler()
            this.clearTapperDetails()
            this.getPrescriptionFavouriteData()
            this.setState({ openMedicPopUp: false, EditIndex: null, AddClicked: false, DisableBtn: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ DisableBtn: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ DisableBtn: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  TapperTableDetails = (Favourite, removeID) => {
    let states = this.state
    var tapper = []
    try {
      if (removeID && Favourite) {
        var NewArray = this.modifyHistory(Favourite)
      }
      if (!Favourite) {
        tapper = [{
          "id": (this.state?.tapperID && this.state?.isEdit) ? this.state?.tapperID : undefined,
          ...this.generateTapperObject()
        }];
      }

      const data = {
        "appointment_id": this.props?.appointmentId,
        "patient_id": this.props?.patientId,
        "line_items": removeID ? NewArray : Favourite ? Favourite : tapper,
        "followup_date": "",
        "deleted_line_ids": [],
        "pharmacy_id": states?.selectedPharmacy,
      }
      if (removeID) {
        data["from_history"] = true
      }
      if (this.state.prescriptionId) {
        data["prescription_id"] = this.state.prescriptionId
      }

      if ((Object.keys(this.state.tapp_Gen_Details).length > 0 && this.state.taperAddedDetails.length > 0) || Favourite) {
        this.setState({ DisableBtn: true })
        RestAPIService.create(data, Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getTapperMed()
              this.clearTapperDetails()
              this.getPrescriptionFavouriteData()
              this.getMedicFrequency()
              this.setState({ openMedicPopUp: false, DisableBtn: false, optTemplateId: null })
            }
          }).catch((error) => {
            this.setState({ DisableBtn: false })
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage("Enter Tapper Details");
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = (item) => {
    try {
      this.setState({
        afternoonQty: typeof (item?.afternoon) === 'string' ? item?.afternoon : (item?.afternoon).toString(),
        selectedDrug: item?.brand_name,
        comments: item?.comments,
        dosageStrength: item?.dosage_strength,
        dosageType: item?.dosage_type,
        selectedDuration: item?.duration,
        selectedDays: item?.duration_mode,
        eveningQty: typeof (item?.evening) === 'string' ? item?.evening : (item?.evening).toString(),
        nightQty: typeof (item?.night) === 'string' ? item?.night : (item?.night).toString(),
        morningQty: typeof (item?.morning) === 'string' ? item?.morning : (item?.morning).toString(),
        favourite: JSON.parse(JSON.stringify(item?.is_favorite)),
        searchedGenericName: item?.generic_name,
        selectedUsage: item?.instructions,
        selectedDrugId: item?.drug_id,
        selectedId: item?.id ? item?.id : null,
        isEdit: true
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = () => {
    try {
      this.setState({
        selectedDrug: '',
        searchedBrandName: null,
        searchedGenericName: '',
        genericList: [],
        dosageStrength: '',
        dosageType: '',
        morningQty: '0',
        afternoonQty: '0',
        eveningQty: '0',
        nightQty: '0',
        selectedUsage: '',
        selectedDays: '1',
        selectedDuration: '',
        comments: '',
        favourite: false,
        selectedDrugId: null,
        isEdit: false,
        selectedIndex: null,
        selectedId: null,
        disableTapper: false,
        disableMedic: false,
        Morning: "",
        Afternoon: "",
        Evening: "",
        Night: "",
        no_of_Dosage: null,
        selectedDuration: "Days",
        MedUsage: "",
        MedDuration: "",
        MedQty: "",
        medCommends: "",
        MedicData: [],
        is_MefiFav: false,
        is_TapperFav: false,
        drugList: [],
        GenericNameSearch: null,
        frequency_tablet:""
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPharmaSlct = (width = '12dvw', marginRight = '0.5dvw', statekey = "selectedPharmacy", isDisabled = false) => {
    return (
      <FormControl size='small' sx={{ width: width, mr: marginRight }}>
        <InputLabel>Select Pharmacy</InputLabel>
        <Select
          label='select Pharmacy'
          disabled = {isDisabled}
          value={this.state[statekey]}
          onChange={(e) => this.setState({ [statekey]: e.target.value })}
        >
          {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
            <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
          ))}
        </Select>
      </FormControl>)
  }

  renderNewTemplatePopup = () => {
    try {
      const { tempType } = this.state;
      const closePopUp = () => {
        this.setState({
          tempType: '',
          openPopupAddNew: false,
          templateName: ""
        })
      };
      return (
        <Box component={'div'}>
          <Modal open={this.state.openPopupAddNew} sx={{ outline: "none" }}>
            <Box className='eMed_Doc_Notes_Test_Trans_Pop_Up' sx={{ height: "fit-content", padding: "0.5dvw" }}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontWeight={600} color={Colors.SecondaryText}>
                  {tempType === 'Add' ? 'Add New Template' :
                    tempType === 'rename' ? 'Update Template Name'
                      : 'Delete Template'}
                </Typography>
              </Box>
              <Box component={'div'} mt={"0.5dvw"}>
                <Box component={'div'} display={'flex'} justifyContent={'center'} marginBottom={"0.5dvw"}>
                  <TextField
                    size='small'
                    sx={{ width: '25dvw' }}
                    value={this.state.templateName}
                    label='Enter Template Name'
                    onChange={(e) => this.setState({ templateName: e.target.value })}
                  />
                </Box>
                <Box component={'div'} display={'flex'} justifyContent={'center'} my={'1vw'} columnGap={"1dvw"}>
                  <Button variant='contained'
                    sx={{ width: '5dvw', height: '2dvw', textTransform: 'capitalize' }}
                    onClick={() => { this.PostNewMedicTemplate() }}
                  >{tempType === 'rename' ? 'Update' : 'Add'}</Button>
                  <Button variant='outlined'
                    sx={{ width: '5dvw', height: '2dvw', textTransform: 'capitalize' }}
                    onClick={closePopUp}
                  >Close</Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditTapper = (item, index) => {  
    this.setState({
      tapDuration: item?.duration ? item?.duration?.split(" ")[0] : "",
      durationType: item?.duration ? item?.duration?.split(" ")[1] : "",
      TapperUnits: item?.no_of_dosage ? item?.no_of_dosage?.split(" ")[0] : "",
      Frequency: item?.no_of_dosage?.includes("Drop(s)") ? item?.frequency?.split(" ")[0] : item?.frequency,
      reducedBy: item?.reducedBy,
      taperFromDate: moment(item?.start_date),
      selectedEditIndex: index,
      frequency_tablet:item?.frequency,
      tapUsage:item?.instructions ? item?.instructions : ''
    })
  }

  onDltTapper = (indexToRemove) => {
    let { taperAddedDetails } = this.state
    let newArray = taperAddedDetails.filter((_, index) => index !== indexToRemove);
    this.setState({
      taperAddedDetails: []
    }, () => {
      this.setState({
        taperAddedDetails: newArray
      })
    })
  }

  renderChip = (values, key, Type) => {
    let states = this.state
    return (
      <div>
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
          {
            values && values.map((item, index) => (
              <Chip
                className={`eMed_chip ${Type == "EyeChip" ? (item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border') : ((item === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'))}`}
                style={{ width: "7vw", display: 'flex', justifyContent: 'space-between' }}
                label={Type == "EyeChip" ? item?.label === "RE" ? "Right Eye (OD)" : item?.label === "LE" ? "Left Eye (OS)" : item?.label === "BE" ? "Both Eye (OU)" : item?.label : item}
                variant={Type == "EyeChip" ? (item.label === key ? 'contained' : 'outlined') : (item === key ? 'contained' : 'outlined')}
                key={index}
                onClick={() => {
                  if (Type == "EyeChip") {
                    if (item.label === key) {
                      states.eyeType = ""
                      this.setState({ states })
                    } else {
                      states.eyeType = item?.value
                      this.setState({ states })
                    }
                  } else {
                    if (item === key) {
                      states.Medfrequency = ""
                      states.FrequencyText = ""
                      this.setState({ states })
                    } else {
                      states.Medfrequency = item
                      states.FrequencyText = ""
                      this.setState({ states })
                    }
                  }
                }}
                clickable
                onDelete={Type === "Frequency" ? () => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) } : null}
              />
            ))
          }
          {
            Type === "Frequency" ?
              <TextField
                size='small'
                value={this.state.FrequencyText}
                onChange={(e) => {
                  this.setState({ FrequencyText: e.target.value })
                }}
                inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && this.state.FrequencyText !== "") {
                    let List = this.state.frequencyTemplateList
                    if (!List.includes(this.state.FrequencyText)) {
                      List.push(this.state.FrequencyText)
                    }
                    this.setState({
                      frequencyTemplateList: List,
                      Medfrequency: this.state.FrequencyText,
                      FrequencyText: ""
                    })
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={this.state.FrequencyText === ""}
                        onClick={() => {
                          let List = this.state.frequencyTemplateList
                          if (!List.includes(this.state.FrequencyText)) {
                            List.push(this.state.FrequencyText)
                          }
                          this.setState({
                            frequencyTemplateList: List,
                            Medfrequency: this.state.FrequencyText,
                            FrequencyText: ""
                          })
                        }}>
                        <AddIcon color={this.state.FrequencyText === "" ? 'grey' : 'primary'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }} /> : null
          }
        </div>
      </div>
    )
  }

  checkDuplicate = () => {
    try {
      let message = null
      let states = this.state
      states.drugList?.forEach(element => {
        if ((element?.name === states.searchedBrandName) || (element === states.searchedBrandName)) {
          message = 'Brand Name Already Exists'
        }
      })
      return message
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNewText = (Heading, values, Tab) => {
    let states = this.state
    return (
      <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw" }}>
        <TextField
          label={(`Add ${Heading}`)}
          size="small"
          style={{ marginTop: "0.5vw", width: "20vw" }}
          variant="outlined"
          value={states.NewText}
          onChange={(event) => {
            let value = event.target.value
            this.setState({ NewText: value })
          }}
        />
        <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
          onClick={() => {
            if (this.state.NewText) {
              let data = CommonValidation.removeSpace(this.state.NewText)
              values.push(data)
              states[Tab] = this.state.NewText
              this.setState({ states }, () => { this.setState({ selectedText: false, NewText: "" }) })
            }
          }}>
          <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
        </div>
        <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
          onClick={() => {
            this.setState({ selectedText: false, NewText: "" })
          }}>
          <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
        </div>
      </div>
    )
  }

  AddExternalDrug = () => {
    let states = this.state
    return (
      <Box>
        <Autocomplete
          size='small'
          sx={{ marginTop: '0.5vw', width: '19vw', mr: '0.5vw' }}
          clearIcon
          freeSolo
          autoSelect={false}
          options={this.state.ExternalDrugList?.map((item) => item?.drug_name)}
          value={this.state.ExternalBrandName ? this.state.ExternalBrandName : null}
          onChange={(e, value) => {
            let selectedDrug = this.state.ExternalDrugList.filter((item) => item?.drug_name == value)
            this.getExternalDrugSearchList(value, '')
            this.setState({
              ExternalBrandName: value
            }, () => {
              this.setState({
                External_Drug_Details: {
                  DrugName: selectedDrug[0]?.drug_name,
                  GenericName: selectedDrug[0]?.generic_name,
                  DosageStrength: selectedDrug[0]?.dosage_strength,
                  dosage_type: selectedDrug[0]?.dosage_type
                }
              })
            })
          }}
          renderInput={(params) => <TextField {...params}
            onChange={(e) => {
              if (e.target.value?.length > 0) {
                states.External_Drug_Details["DrugName"] = e.target.value
                this.setState({
                  states
                }, () => this.getExternalDrugSearchList(e.target.value, ''))
              } else {
                this.clearDrugDetails()
              }
            }}
            label='Drug Name*' />}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: '0.5vw' }}>
          {this.renderNestedStateTxt('Generic Name', "External_Drug_Details", "GenericName", '19vw')}
          {this.renderNestedStateTxt('Dosage Strength', "External_Drug_Details", "DosageStrength", '17vw')}
        </Box>
      </Box>
    )
  }

  isDrugIsDuplicate() {
    try {
      let Flag = false
      let i = 0;
      let states = this.state;
      while (i < states.MedicData.length) {
        if (this.state.ExternalDrugAdd) {
          if (states.MedicData[i]?.brand_name === states.External_Drug_Details?.DrugName && states.MedicData[i]?.generic_name === states.External_Drug_Details?.GenericName && states.MedicData[i]?.dosage_strength === states.External_Drug_Details?.DosageStrength && states.MedicData[i]?.dosage_type === states.External_Drug_Details?.dosage_type && this.state.EditIndex !== i) {
            Flag = true;
            break;
          }
        } else {
          if ((states.MedicData[i]?.drug_id === states?.selectedDrugId || states.MedicData[i]?.drug_ms_id === states?.selectedDrugId) && this.state.EditIndex !== i) {
            Flag = true;
            break;
          }
        }
        i++;
      }
      return Flag
    }
    catch (e) {

    }
  }

  clearDrugDetails = () => {
    this.setState({
      TapperDrugList: [],
      Medic_Gen_Details: {},
      selectedDrugId: null,
      MedicDrugList: [],
      DrugSuggestionList: [],
      selectedDrug: "",
      ExternalBrandName: null,
      GenericSuggestionList: []
    })
  }

  checkNewTapvalidation = (list) => {
    try {
      let message = null
      if (list?.brand_name === '') {
        message = 'Enter Brand Name'
      } else if (list?.tapper_meds?.length === 0) {
        message = 'Enter Tapper Details'
      } else if (this.state.newTapMedListData?.length > 0) {
        this.state.newTapMedListData?.forEach((element, index) => {
          if (index !== this.state.leftSelectedIndex) {
            if (element.drug_id === list?.drug_id) {
              message = 'Drug Details Already Exists'
            } else {
              message = null
            }
          }
        })
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearRightTaperHandler = () => {
    try {
      this.setState({
        eyeType: '',
        tapp_Gen_Details: {},
        taperFromDate: new Date(),
        Frequency: "",
        tapDuration: "",
        durationType: "Day(s)",
        TapperUnits: "",
        unitType: "",
        TapperDrugList: [],
        reducedBy: "",
        tapperQty: "",
        is_TapperFav: false,
        taperAddedDetails: [],
        selectedDrug: '',
        searchedBrandName: null,
        tapperComm: "",
        selectedDrugId: null,
        editItem: null,
        EditIndex: null,
        DrugSuggestionList: [],
        AllowEdit: false,
        drugList: [],
        GenericNameSearch: null,
        frequency_tablet: "",
        tapUsage: '',
        disableMedic: false,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  generateTapperObject = () => {
    const states = this.state;
    const dosage_type = states.tapp_Gen_Details?.dosage_type ? states.tapp_Gen_Details?.dosage_type : "";
    return {
      "eye_type": states.eyeType ? states.eyeType : "",
      "brand_name": states?.tapp_Gen_Details?.drug_name || states?.tapp_Gen_Details?.brand_name,
      "generic_name": states?.tapp_Gen_Details?.generic_name,
      "dosage_strength": states.tapp_Gen_Details?.dosage_strength,
      "drug_id": states.tapp_Gen_Details?.drug_ms_id,
      "dosage_type": states.tapp_Gen_Details?.dosage_type === 'Drops' ? 'Drop' : dosage_type,
      "frequency": this.state.Frequency ? this.state.Frequency : "",
      "duration": states.durationType === "Day(s)" ? "Days" : states.durationType === "Week(s)" ? "Weeks" : "Months",
      "instructions": states.tapUsage ? states.tapUsage : '',
      "comments": states.tapperComm,
      "quantity": states.tapperQty,
      "tapper_meds": states.taperAddedDetails,
      "pharmacy_id": states.selectedPharmacy,
      "isAddNewClick": false,
      "is_favorite": states.is_TapperFav,
      "morning": 0,
      "afternoon": 0,
      "evening": 0,
      "night": 0,
      "no_of_dosage": 0,
      "is_tapper_meds":states.tapp_Gen_Details?.dosage_type === 'Drop' ? false : true,
      "available_qty": states.tapp_Gen_Details?.quantity ? states.tapp_Gen_Details?.quantity : 0,
      "opt_template_line_id": states.optTemplateId ? states.optTemplateId : null 
    }
  }

  onAddDrugListHandler = () => {
    try {
      const list = this.generateTapperObject();
      if (this.state.newTapMedId) {
        list['id'] = this.state.newTapMedId
      }
      const check = this.checkNewTapvalidation(list)
      if (check !== null) {
        this.errorMessage(check)
      } else {
        const listData = CommonValidation.updateOrInsertList(this.state.newTapMedListData, list, this.state.leftSelectedIndex)
        this.setState({
          newTapMedListData: listData,
          newLftTapEdit: false,
          leftSelectedIndex: null,
          newTapMedId: null,
          disableMedic: false,
        }, () => { this.onClearRightTaperHandler(); })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onLftTapMedEditHandler = (item, index) => {
    try {
      this.setState({
        disableMedic: true,
        leftSelectedIndex: index,
        taperAddedDetails: item?.tapper_meds ? JSON.parse(JSON.stringify(item?.tapper_meds)) : [],
        tapperQty: item?.quantity ? item?.quantity : '',
        tapUsage: item?.instructions ? item?.instructions : '',
        tapperComm: item?.comments ? item?.comments : '',
        tapp_Gen_Details: item ? JSON.parse(JSON.stringify(item)) : {},
        selectedDrug: item?.brand_name ? item?.brand_name : '',
        eyeType: item?.eye_type ? item?.eye_type : '',
        prescriptionId: item?.prescription_id ? item?.prescription_id : null,
        newTapMedId: item?.id ? item?.id : null,
      }, () => {this.getTapperDrugDetails(true); console.log(this.state.prescriptionId, "on taper edit")})
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postTapperTblDetails = () => {
    try {
      const { newTapMedListData, prescriptionId, selectedPharmacy } = this.state;
      const updatedLineItems = newTapMedListData?.map(element => ({
        ...element,
        is_tapper_meds: element?.tapper_meds?.length > 0
      }));
      let data = {
        "appointment_id": this.props?.appointmentId,
        "patient_id": this.props?.patientId,
        "line_items": updatedLineItems,
        "followup_date": "",
        "deleted_line_ids": [],
        "pharmacy_id": selectedPharmacy,
      }
      console.log(this.state.prescriptionId, "postTapperTblDetails")
      if (prescriptionId) {
        data["prescription_id"] = prescriptionId
      }
      RestAPIService.create(data, Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getTapperMed()
            this.getPrescriptionFavouriteData()
            this.getMedicFrequency()
            this.setState({
              openMedicPopUp: false,
              newTapMedListData: [],
              prescriptionId: null,
              editItem: null,
              AllowEdit: false,
              disableMedic: false,
              isEdit: false,
              value: 'One',
              saveLoad: false
            }, () => { this.clearTapperDetails() })
          }
        }).catch((error) => {
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  updateNestedState = (parentKey, childKey, value) => {
    this.setState((prevState) => ({
      [parentKey]: {
        ...prevState[parentKey],
        [childKey]: value,
      },
    }));
  };

  onChangeHandler = (stateKey, value, isNumber = false) => {
    switch (stateKey) {
      case "MedDuration":
        if ((CommonValidation.numberOnly(value) && +value < 999) || value === "") {
          this.setState({ MedDuration: value })
        }
        break;
      case "selectedDuration":
        this.setState({ selectedDuration: value, MedDuration: "" })
        break;
      default:
        if (isNumber) {
          if (CommonValidation.numberOnly(value) || value == "") {
            this.setState({ [stateKey]: value })
          }
        } else {
          this.setState({ [stateKey]: value })
        }
        break;
    }
  }

  renderTextField = (label, statekey, width, isNumber = false, is_disable = false) => {
    const maxList = new Set(["medCommends", "tapperComm"]);
    const states = this.state;
    return (
      <TextField
        disabled={is_disable}
        autoComplete='off'
        size='small'
        inputProps={maxList.has(statekey) ? { maxLength: 250 } : {}}  
        sx={{ width: width }}
        value={states[statekey] ? states[statekey] : ""}
        label={label}
        onChange={(e) => {this.onChangeHandler(statekey, e.target.value, isNumber)}}
      />)
  }

  renderNestedStateTxt = (label, parentKey, childKey, width, is_disable = false) => {
    const states = this.state;
    return (
      <TextField
        disabled={is_disable}
        autoComplete='off'
        size='small'
        sx={{ width: width }}
        value={states[parentKey][childKey] ? states[parentKey][childKey] : ""}
        label={label}
        onChange={(e) => {this.updateNestedState(parentKey, childKey, e.target.value)}}
      />)
  }

  renderSelect = (label, stateKey, list, showKey, valueKey, width, isDisable = false) => {
    const states = this.state;
    return (
      <Select
        value={states[stateKey]}
        onChange={(e) => {this.onChangeHandler(stateKey, e.target.value)}}
        disabled={isDisable}
        displayEmpty
        style={{ width: width }}
        inputProps={{ 'aria-label': 'Without label' }}
        size='small'
      >
        <MenuItem value="">{label}</MenuItem>
        {list?.map((item, index) => (
          <MenuItem key={index} value={item[valueKey]}>{item[showKey]}</MenuItem>
        ))}
      </Select>
    )
  }

  renderMedicTbl = (isLocked, txtStyle) => {
    return (
      <Box flex={0.6} sx={{ height: "100%", overflow: "auto" }}>
        <Table stickyHeader size='small' sx={{ marginTop: "0.5vw" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={txtStyle}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <div style={{ marginLeft: "3dvw" }}>Brand Name</div>
                </div></TableCell>
              <TableCell sx={txtStyle}>Strength</TableCell>
              <TableCell sx={txtStyle}>Frequency</TableCell>
              <TableCell sx={txtStyle}>Duration</TableCell>
              <TableCell sx={txtStyle} align='center'>Usage</TableCell>
              <TableCell sx={txtStyle} align='center'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.MedicData?.length > 0 ? this.state.MedicData?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Button sx={{ visibility: (item?.drug_id || item?.drug_ms_id) ? "visible" : "hidden", justifyContent: 'flex-start', paddingTop: '0.5dvw', }}
                      className='Common_Btn_Min_Width'
                      onClick={() => {
                        this.setState((prevState) => {
                          const updatedMedicData = [...prevState.MedicData];
                          updatedMedicData[index].is_favorite = !updatedMedicData[index].is_favorite;
                          return { MedicData: updatedMedicData };
                        });
                      }}>
                      <Box component={'img'} src={item?.is_favorite ? ImagePaths.StarFill.default : ImagePaths.StarUnfill.default} height={'1.5dvw'} width={'1.5dvw'} />
                    </Button>
                    <div style={{ marginLeft: "0.65dvw" }}>
                      <div style={{ fontWeight: "600" }}>{item?.brand_name ? item?.brand_name : "-"}</div>
                      <div>{`${item?.generic_name ? item?.generic_name : "-"} | ${item?.dosage_type}`}</div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>{item?.dosage_strength ? item?.dosage_strength : "-"}</TableCell>
                <TableCell style={{ fontWeight: "600" }}>{item?.frequency ? item?.frequency : "-"}</TableCell>
                <TableCell>{item?.duration ? (item?.duration.split(" ")[1] === "Until_the_Next_Visit") ? "Until the Next Visit" : (item?.duration.split(" ")[1] === "Long_Term") ? "Long Term" : item?.duration : "-"}</TableCell>
                <TableCell align='center'>{item?.instructions ? item?.instructions : "-"}</TableCell>
                <TableCell align='center'>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                      className='Common_Btn_Min_Width'
                      onClick={() => {
                        this.setState({ editItem: item, EditIndex: index, AllowEdit: true }, () => {
                          this.getPharmacyList()
                        })
                      }}
                      disabled={isLocked || this.state.transmitted}
                    >
                      <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                    </Button>
                    <Button
                      className='Common_Btn_Min_Width'
                      onClick={() => {
                        if (this.state.AllowEdit) {
                          this.errorMessage('You clicked edit button.So you cannot able to delete')
                        } else {
                          if (item?.id) {
                            this.onDeleteMedic(item, true)
                          } else {
                            this.state.MedicData.splice(index, 1)
                            this.setState({
                              MedicData: this.state.MedicData
                            }, () => { this.clearTapperDetails() })
                          }
                        }
                      }}
                      disabled={isLocked || this.state.transmitted}
                    >
                      <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2vw'} width={'1.2vw'} />
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            )) : null}
          </TableBody>
        </Table>
      </Box>)
  }

  handleDosageChange = (operation, changeCount) => {
    const statesKey = this.state.ExternalDrugAdd ? "External_Drug_Details" : "Medic_Gen_Details";
    const states = this.state[statesKey];
    const value = states?.no_of_dosage ?
      ((operation === "increment") ? (states?.no_of_dosage + changeCount) : (states?.no_of_dosage - changeCount))
      : (changeCount);
    states.no_of_dosage = value;
    this.setState({ states });
  }

  renderMedicRightDiv = (flexVal, medicPharmaId) => {
    return (
      <Box flex={flexVal} sx={{ height: "100%", overflow: "scroll", padding: "0.625dvw" }}>
        <Box component={'div'} mt={'0.5vw'} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {this.renderPharmaSlct('15dvw', 0, "selectedPharmacy", ((this.state.MedicData?.length > 0 && medicPharmaId) ||
            (this.state.taperAddedDetails?.length > 0 && this.state.taperAddedDetails[0]?.pharmacy_id === this.state.selectedPharmacy) ||
            (this.state.newTapMedListData?.length > 0 && this.state.newTapMedListData[0]?.pharmacy_id === this.state.selectedPharmacy) ||
            this.state.AllowEdit))}
          <Button variant='outlined' disabled={this.state.AllowEdit} onClick={() => {
            this.setState({ ExternalDrugAdd: !this.state.ExternalDrugAdd }, () => { this.clearTapperDetails(true) })
          }}>{this.state.ExternalDrugAdd ? "Pharmacy Drugs" : "External Drugs"}</Button>
        </Box>
        {
          this.state.ExternalDrugAdd ? this.AddExternalDrug() :
            <Box>
              <Box component={'div'} display={'flex'} alignItems={"center"}>
                <Autocomplete
                  size='small'
                  sx={{ marginTop: '0.5dvw', width: '19dvw', mr: '0.5dvw' }}
                  clearIcon
                  options={this.state.MedicDrugList || []}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option :
                    (this.state.drugSearchFor === 1 ? option.drug_name :
                      `${option.drug_name} ${option?.generic_name ? `- ${option?.generic_name}` : ''}`)}
                  value={this.state.searchedBrandName ? this.state.searchedBrandName : null}
                  onChange={(e, value) => {
                    this.setState({ searchedBrandName: value }, () => {
                      if (value) {
                        this.setState({ selectedDrug: value?.drug_name }, () => {
                          const updateDrugList = [...this.state.drugList, value?.drug_name]
                          let stateUpdate = {}
                          if (this.state.drugSearchFor === 1) {
                            stateUpdate = { drugList: updateDrugList };
                          } else {
                            stateUpdate = { GenericNameSearch: value?.generic_name };
                          }
                          this.setState(stateUpdate, () => this.getTapperDrugDetails());
                        })
                      } else {
                        this.setState({
                          GenericNameSearch: null,
                          Medic_Gen_Details: {},
                          searchedBrandName: null,
                          drugList: [],
                          GenericSuggestionList: []
                        })
                      }
                    })
                  }}
                  renderInput={(params) => <TextField {...params} label='Search Drug *'
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        this.setState({ searchedBrandName: e.target.value, selectedDrug: '', fromFreq: false }, () => this.getMedicDrugSearchList(this.state.searchedBrandName))
                      } else {
                        this.setState({ searchedBrandName: null, MedicDrugList: [], selectedDrug: '' })
                      }
                    }}
                  />}
                  renderOption={(props, option) => (
                    <li {...props} key={props['data-option-index']}>
                      <Typography fontSize={'0.9vw'}>{this.state.drugSearchFor === 1 ? option.drug_name : `${option.drug_name} - ${option?.generic_name}`}</Typography>
                    </li>
                  )}
                />
                <Typography fontWeight={600}>Avl. Qty:{this.state.Medic_Gen_Details?.stock_in_quantity ||  0}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: '0.5dvw', columnGap: "0.5dvw" }}>
                <Autocomplete
                  size='small'
                  sx={{ width: '18dvw' }}
                  clearIcon
                  readOnly={(this.state.GenericSuggestionList?.length > 1) ? false : true}
                  options={this.state.GenericSuggestionList}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.generic_name}
                  value={this.state.GenericNameSearch ? this.state.GenericNameSearch : null}
                  onChange={(e, value) => {
                    this.setState({
                      GenericNameSearch: value,
                      Medic_Gen_Details: {...value, stock_in_quantity: value?.quantity}
                    }, () => this.autofetchDerugDetails(this.state.GenericNameSearch))
                  }}
                  renderInput={(params) => <TextField {...params} label='Generic Name *' />}
                />
                {this.renderNestedStateTxt('Dosage Strength', "Medic_Gen_Details", "dosage_strength", '18vw', true)}
              </Box>
              <div style={{ marginTop: "0.65dvw", display: "flex", flexWrap: "wrap", overflow: "scroll" }}>
                {this.state.MedicFavorite?.length > 0 && this.state.MedicFavorite?.map((item, index) => (
                  <Chip
                    className={`eMed_chip ${(item?.drug_name === this.state.selectedDrug) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={item.drug_name ? item?.drug_name : item}
                    style={{ width: "9dvw" }}
                    variant={item.drug_name === this.state.selectedDrug ? 'contained' : 'outlined'}
                    key={index}
                    onClick={() => {
                      this.setState({
                        selectedDrug: item?.drug_name, searchedBrandName: null, selectedDrugId: null, fromFreq: true,
                        MedicDrugList: [], DrugSuggestionList: [], GenericNameSearch: null, Medic_Gen_Details: {}
                      }, () => {
                        this.getTapperDrugDetails(false, this.state.selectedDrug?.generic_name, this.state.selectedDrug?.dosage_type, true)
                      })
                    }}
                    clickable
                  />
                ))}
              </div>
            </Box>
        }
        <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5dvw" }}>
          <Typography fontSize={'0.9dvw'} fontWeight={600} color={Colors.SecondaryText}>{`Dosage Type ${this.state.ExternalDrugAdd ? "*" : ""}`}</Typography>
          <div style={{ marginTop: "0.65dvw", display: "flex", flexWrap: "wrap", overflow: "scroll" }}>
            {
              Medication_dosages?.length > 0 && Medication_dosages?.map((item, index) => {
                const isExternalDrug = this.state.ExternalDrugAdd;
                const targetState = isExternalDrug ? "External_Drug_Details" : "Medic_Gen_Details";
                const currentDetails = this.state[targetState];
                const isSelected = item?.label === currentDetails?.dosage_type;

                if (isSelected && !currentDetails?.no_of_dosage && item?.change_count) {
                  this.setState({
                    [targetState]: { ...currentDetails, no_of_dosage: item?.change_count },
                  });
                }
                return (
                  <Box key={index} className={isSelected ? "eMed_Dosage_Type_Selected_Box" : this.state.ExternalDrugAdd ? "eMed_Dosage_Type_Box_Selectable" : "eMed_Dosage_Type_Box"}
                    onClick={() => {
                      if ((this.state.ExternalDrugAdd && this.state.External_Drug_Details?.no_of_dosage === undefined) || (this.state.ExternalDrugAdd && this.state.External_Drug_Details?.dosage_type !== item?.label)) {
                        let states = this.state.External_Drug_Details
                        states["dosage_type"] = item?.label
                        states["no_of_dosage"] = item?.change_count
                        this.setState({ states })
                      }
                    }}>
                    {isSelected && item?.change_count != 0 && <IconButton size='small'
                      disabled={this.state.ExternalDrugAdd ? this.state.External_Drug_Details?.no_of_dosage == item?.change_count : this.state.Medic_Gen_Details?.no_of_dosage == item?.change_count}
                      onClick={() => { this.handleDosageChange("decrement", item?.change_count) }}>
                      <RemoveCircleOutlineIcon sx={{ color: 'white', fontSize: '0.5vw' }} />
                    </IconButton>}

                    <Typography width={isSelected ? "5vw" : "auto"} fontSize={'0.75vw'} fontWeight={600} textAlign={'center'} color={isSelected ? "white" : "black"}>{`${isSelected ? (this.state.ExternalDrugAdd ? this.state.External_Drug_Details?.no_of_dosage ? this.state.External_Drug_Details?.no_of_dosage : "" : this.state.Medic_Gen_Details?.no_of_dosage ? this.state.Medic_Gen_Details?.no_of_dosage : "") : ""} ${item?.label}`}</Typography>
                    {isSelected && item?.change_count != 0 && <IconButton
                      onClick={() => { this.handleDosageChange("increment", item?.change_count) }}
                      size='small'><AddCircleOutlineIcon sx={{ color: 'white', fontSize: '0.5vw' }} />
                    </IconButton>}
                  </Box>
                )
              })}
            {!this.state.ExternalDrugAdd && this.state.Medic_Gen_Details?.dosage_type && Medication_dosages.every((item) => item.label !== this.state.Medic_Gen_Details?.dosage_type) ? <Box className={"eMed_Dosage_Type_Box"} bgcolor={"#04B7B1"}>
              <Typography width={"auto"} fontSize={'0.75vw'} fontWeight={600} textAlign={'center'} color={"white"}>{this.state.Medic_Gen_Details?.dosage_type}</Typography>
            </Box> : null}
          </div>
        </Box>
        <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5vw" }}>
          <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>
            {`Select Eye ${this.state.Medic_Gen_Details?.dosage_type === "Drops" ? "*" : ""}`}</Typography>
          <Box component={'div'} style={{ paddingLeft: "-0.5vw" }}>
            {this.renderChip(Eye_Type, this.state.eyeType, "EyeChip")}
          </Box>
        </Box>
        <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5vw" }}>
          <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Frequency *</Typography>
          <Box component={'div'} style={{ paddingLeft: "-0.5vw" }}>
            {this.renderChip(this.state.frequencyTemplateList, this.state.Medfrequency, "Frequency")}
          </Box>
        </Box>
        <Box>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: flexVal === 0.4 ? "space-between" : "flex-start", columnGap: "0.5dvw"  }}>
            <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5vw", marginLeft: "0.5vw" }}>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Duration *</Typography>
              <div style={{ display: "flex", flexDirection: "row", marginTop: "0.5vw" }}>
                {this.renderTextField('Duration', "MedDuration", '8vw', false, (this.state?.selectedDuration === "Until_the_Next_Visit" || this.state?.selectedDuration === "Long_Term"))}
                <FormControl sx={{ minWidth: 80, marginLeft: "-0.1vw" }}>
                  {this.renderSelect("", "selectedDuration", medicDurationList, "label", "value", "7vw")}
                </FormControl>
              </div>
            </Box>
            <Box sx={{ marginTop: "0.5vw" }}>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Usage</Typography>
              <div style={{ marginTop: "0.5vw" }}>
                <FormControl sx={{ minWidth: 80, marginLeft: "0.5vw" }}>
                  {this.renderSelect("Select Usage", "MedUsage", Usage_Type, "label", "value", '14vw')}
                </FormControl>
              </div>
            </Box>
          </div>
        </Box>
        <div style={{ marginTop: '0.5vw' }}>
          <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Other Details</Typography>
          <div style={{ marginTop: "0.5vw", display: "flex", alignItems: "center", justifyContent: flexVal === 0.4 ? "space-between" : "flex-start", columnGap: "0.5dvw" }}>
            {this.renderTextField('Quantity', "MedQty", '10vw', true)}
            {this.renderTextField('Comments', "medCommends", '22vw')}
          </div>
        </div>
        <Typography sx={{ display: this.state.ExternalDrugAdd ? "flex" : "none", justifyContent: 'center', paddingTop: '0.5vw', fontSize: "0.8vw", color: Colors.red, fontWeight: 600 }}>Note : This Drug is not available in our pharmacy</Typography>
        <Box style={{ display: "flex", justifyContent: 'center', marginTop: "0.5vw" }}>
          <Button
            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', ml: '1vw', visibility: (this.state.EditIndex !== null ? false : this.state.isEdit) ? 'hidden' : "visible" }}
            variant='contained'
            disabled={this.state.EditIndex !== null ? false : this.state.isEdit}
            onClick={() => {
              let states = this.state
              let Duration = `${this.state.MedDuration} ${this.state.selectedDuration == "Until_the_Next_Visit" ? "Until the Next Visit" : this.state.selectedDuration == "Long_Term" ? "Long Term" : this.state.selectedDuration}`
              let DosageType = states?.ExternalDrugAdd ? (this.state.External_Drug_Details?.dosage_type ? this.state.External_Drug_Details?.dosage_type : "") : (states.Medic_Gen_Details?.dosage_type ? states.Medic_Gen_Details?.dosage_type : "");
              if ((Object.keys(this.state.Medic_Gen_Details).length > 0 || (this.state.ExternalDrugAdd && this.state.External_Drug_Details?.DrugName && this.state.External_Drug_Details?.dosage_type))
                && (states.Medfrequency != "")
                && (states?.MedDuration != "" || (states?.selectedDuration === "Until_the_Next_Visit" || states?.selectedDuration === "Long_Term"))
                && !this.isDrugIsDuplicate()
                && (this.state.Medic_Gen_Details?.dosage_type !== "Drops" || this.state.eyeType)) {
                let object = {
                  "eye_type": this.state.eyeType ? this.state.eyeType : "",
                  "brand_name": states?.ExternalDrugAdd ? this.state.External_Drug_Details?.DrugName : (states?.Medic_Gen_Details?.drug_name ? states?.Medic_Gen_Details?.drug_name : states?.Medic_Gen_Details?.brand_name),
                  "generic_name": states?.ExternalDrugAdd ? (this.state.External_Drug_Details?.GenericName ? this.state.External_Drug_Details?.GenericName : "") : states?.Medic_Gen_Details?.generic_name,
                  "dosage_strength": states?.ExternalDrugAdd ? (this.state.External_Drug_Details?.DosageStrength ? this.state.External_Drug_Details?.DosageStrength : null) : states.Medic_Gen_Details?.dosage_strength,
                  "drug_id": states?.ExternalDrugAdd ? null : (states.Medic_Gen_Details?.drug_ms_id ? states.Medic_Gen_Details?.drug_ms_id : states.Medic_Gen_Details?.drug_id),
                  "dosage_type": DosageType === 'Drops' ? 'Drop' : DosageType,
                  "frequency": this.state.Medfrequency,
                  "duration": Duration,
                  "instructions": states?.MedUsage,
                  "comments": this.state.medCommends,
                  "quantity": this.state.MedQty,
                  "tapper_meds": [],
                  "pharmacy_id": this.state.selectedPharmacy,
                  "isAddNewClick": false,
                  "is_favorite": states?.ExternalDrugAdd ? false : (this.state.Medic_Gen_Details?.is_favorite ? this.state.Medic_Gen_Details?.is_favorite : false),
                  "morning": states?.Morning ? states?.Morning : 0,
                  "afternoon": states?.Afternoon ? states?.Afternoon : 0,
                  "evening": states?.Evening ? states?.Evening : 0,
                  "night": states?.Night ? states?.Night : 0,
                  "no_of_dosage": states?.ExternalDrugAdd ? (states.External_Drug_Details?.no_of_dosage ? states.External_Drug_Details?.no_of_dosage : 0) :
                    (states.Medic_Gen_Details?.no_of_dosage ? states.Medic_Gen_Details?.no_of_dosage : 0),
                  "opt_template_line_id": states?.optTemplateId ? states.optTemplateId : null
                }
                if (this.state.EditIndex !== null) {
                  if (states.MedicData[this.state.EditIndex].id) {
                    object["id"] = states.MedicData[this.state.EditIndex].id
                  }
                  states.MedicData[this.state.EditIndex] = object
                } else {
                  states?.MedicData.push(object)
                }
                this.setState({
                  states,
                  Medic_Gen_Details: {},
                  eyeType: "",
                  Morning: "",
                  Afternoon: "",
                  Evening: "",
                  Night: "",
                  MedUsage: "",
                  MedQty: "",
                  medCommends: "",
                  MedDuration: "",
                  Medfrequency: "",
                  selectedDuration: "Days",
                  TapperDrugList: [],
                  MedicDrugList: [],
                  selectedDrugId: null,
                  selectedDrug: "",
                  ExternalDrugAdd: false,
                  External_Drug_Details: {
                    DrugName: "",
                    GenericName: "",
                    DosageType: "",
                    DosageStrength: "",
                  },
                  isEdit: false,
                  EditIndex: null,
                  AllowEdit: false,
                  disableTapper: false,
                  GenericNameSearch: null,
                  searchedBrandName: null,
                  fromFreq: false,
                  GenericSuggestionList: []
                })
              } else {
                if (Object.keys(this.state.Medic_Gen_Details).length <= 0 && !this.state.ExternalDrugAdd) {
                  this.errorMessage("Select Drug Name and Generic Name")
                }
                else if (this.state.ExternalDrugAdd && !this.state.External_Drug_Details?.DrugName) { this.errorMessage("Enter External Drug Name") }
                else if (this.state.ExternalDrugAdd && !this.state.External_Drug_Details?.dosage_type) { this.errorMessage("Select External Doasge Type") }
                else if (states.Medfrequency == "") {
                  this.errorMessage("Select Frequency")
                } else if (states?.MedDuration == "" && (states?.selectedDuration !== "Until_the_Next_Visit" || states?.selectedDuration !== "Long_Term")) {
                  this.errorMessage("Select Duration")
                } else if(this.state.Medic_Gen_Details?.dosage_type === "Drops" && !this.state.eyeType){
                  this.errorMessage("Select Eye Type")
                } else if (this.isDrugIsDuplicate()) {
                  this.errorMessage("Drug Already Added")
                }
              }
            }}
          >Add</Button>
        </Box>
      </Box>
    )
  }

  renderTapperTbl = () => {
    return (<Box flex={0.6} sx={{ height: "100%", overflow: "auto" }}>
      <Table size='small' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: Colors.Background, width: '20%' }}>
              <Typography fontSize={'0.9dvw'} fontWeight={600}>Brand Name</Typography>
            </TableCell>
            <TableCell sx={{ backgroundColor: Colors.Background, width: '25%' }}>
              <Typography fontSize={'0.9dvw'} fontWeight={600}>Tapper Details</Typography>
            </TableCell>
            <TableCell sx={{ backgroundColor: Colors.Background, width: '25%' }}>
              <Typography fontSize={'0.9dvw'} fontWeight={600}>Frequency/Unit</Typography>
            </TableCell>
            <TableCell sx={{ backgroundColor: Colors.Background, width: '15%' }}>
              <Typography fontSize={'0.9dvw'} fontWeight={600}>Quantity</Typography>
            </TableCell>
            <TableCell sx={{ backgroundColor: Colors.Background, width: '15%' }}>
              <Typography fontSize={'0.9dvw'} fontWeight={600}>Action</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ height: "100%" }}>
          {this.state.newTapMedListData?.length > 0 ? this.state.newTapMedListData?.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  {item?.brand_name ? item?.brand_name?.length > 15 ?
                    <Tooltip placement='top' title={item?.brand_name}>
                      <Typography>{item?.brand_name?.slice(0, 15) + '...'}</Typography>
                    </Tooltip> :
                    <Typography fontSize={'0.8dvw'}>{item?.brand_name}</Typography> : '-'}
                </TableCell>
                <TableCell colSpan={2} >
                  <Table>
                    <TableRow>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {item?.tapper_meds?.length > 0 ? item?.tapper_meds?.map((tapList, tapIndex) => {
                          return (
                            <Box component={'div'} key={tapIndex} sx={{ mb: '0.5vw' }}>
                              <Typography fontSize={'0.8dvw'}>{`${tapList?.eye_type ? `${tapList?.eye_type} -` : ''} ${tapList?.duration ? tapList?.duration : '-'}`}</Typography>
                              <Typography fontSize={'0.8dvw'}>{`${tapList?.start_date ? formatDate(tapList?.start_date) : '-'} | ${tapList?.end_date ? formatDate(tapList?.end_date) : '-'}`}</Typography>
                            </Box>
                          )
                        }) : '-'}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {item?.tapper_meds?.length > 0 ? item?.tapper_meds?.map((tapList, tapIndex2) => {
                          return (
                            <Box component={'div'} key={tapIndex2} sx={{ mb: '0.5dvw' }}>
                              <Typography fontSize={'0.8dvw'}>{`${tapList?.frequency ? tapList?.frequency : '-'}`}</Typography>
                              <Typography fontSize={'0.8dvw'}>{`${tapList?.no_of_dosage ? tapList?.no_of_dosage : '-'}`}</Typography>
                            </Box>
                          )
                        }) : '-'}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableCell>
                <TableCell>
                  <Typography fontSize={'0.8dvw'}>{item?.quantity ? item?.quantity : ''}</Typography>
                </TableCell>
                <TableCell>
                  <Box component={'div'} display={'flex'}>
                    <Button className='Common_Btn_Min_Width'
                      onClick={() => { this.setState({ newLftTapEdit: true }, () => this.onLftTapMedEditHandler(item, index)) }}
                    >
                      <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.3dvw'} width={'1.3dvw'} />
                    </Button>
                    <Button className='Common_Btn_Min_Width'
                      disabled={this.state.newLftTapEdit}
                      onClick={() => {
                        if (!this.state.newLftTapEdit) {
                          const updatedList = [...this.state.newTapMedListData];
                          updatedList.splice(index, 1);
                          this.setState({ newTapMedListData: updatedList });
                        } else {
                          this.errorMessage('You clicked the edit button. So, you cannot delete.');
                        }
                      }}
                    >
                      <Box component={'img'} src={this.state.newLftTapEdit ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} height={'1.3dvw'} width={'1.3dvw'} />
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            )
          }) : <TableRow sx={{ height: "100%" }}><TableCell align='center' colSpan={5}>No Records To Be Shown</TableCell></TableRow>}
        </TableBody>
      </Table>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginY={"0.625dvw"}>
        <Button variant='contained' size="small"
          sx={{ height: '2dvw', width: '5dvw', textTransform: 'capitalize' }}
          disabled={!this.state.newTapMedListData?.length || this.state.saveLoad}
          onClick={() => this.setState({ saveLoad: true },
            () => this.postTapperTblDetails())}>
          Save
        </Button>
      </Box>
    </Box>);
  }

  checkDrugClear = () => {
    if (Object.keys(this.state.tapp_Gen_Details)?.length > 0) {
      this.onClearRightTaperHandler();
      this.errorMessage("Existing added drug details were cleared as a result of a new drug update.");
    }
  }

  renderTapperRightDiv = (flexVal, medicPharmaId, txtStyle, isLocked) => {
    const len = Object.keys(this.state?.tapp_Gen_Details).length;
    let taperUnit = "";
    if (len > 0) {
      if ((this.state?.tapp_Gen_Details?.dosage_type)?.toUpperCase() === "STEROID TABLETS") {
        taperUnit = "Mg"
      } else {
        taperUnit = "Drop(s)"
      }
    }
    return (
      <Box flex={flexVal} sx={{ height: "100%", padding: "0.6dvw", overflowY: "scroll", overflowX: "hidden" }}>
        <Box component={'div'} my={'0.5vw'} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {this.renderPharmaSlct('19dvw', 0, "selectedPharmacy", ((this.state.MedicData?.length > 0 && medicPharmaId) ||
            (this.state.newTapMedListData?.length > 0 && this.state.newTapMedListData[0]?.pharmacy_id === this.state.selectedPharmacy)))}
          <Typography fontWeight={600}>Available Quantity :{this.state.tapp_Gen_Details?.stock_in_quantity || 0}</Typography>
        </Box>
        <Autocomplete
          size='small'
          sx={{ width: '19dvw' }}
          clearIcon
          freeSolo
          options={this.state.TapperDrugList}
          getOptionLabel={(option) => typeof (option) === 'string' ? option : option}
          value={this.state.searchedBrandName || null}
          onChange={(e, value) => {
            this.checkDrugClear();
            this.setState({
              searchedBrandName: value
            }, () => {
              if (this.state.searchedBrandName?.length > 0) {
                let check = this.checkDuplicate()
                this.setState({
                  drugList: []
                }, () => {
                  if (check === null) {
                    const updatedDrugList = [...this.state.drugList, this.state.searchedBrandName];
                    this.setState({
                      drugList: updatedDrugList,
                      selectedDrug: this.state.searchedBrandName,
                      searchedBrandName: null,
                      TapperDrugList: []
                    }, () => {
                      this.getTapperDrugDetails();
                    })
                  } else {
                    this.errorMessage(check);
                    this.setState({ searchedBrandName: null, TapperDrugList: [] });
                  }
                })
              } else {
                this.setState({
                  TapperDrugList: []
                });
              }
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => {
                this.checkDrugClear();
                if (e.target.value?.length > 0) {
                  this.setState({
                    searchedBrandName: e.target.value
                  }, () => this.getTapperDrugSearchList(this.state.searchedBrandName));
                } else {
                  this.setState({
                    TapperDrugList: []
                  });
                }
              }}
              label='Search Drug Name *'
            />
          )}
        />

        <div style={{ marginTop: "0.5dvw", display: "flex", flexWrap: "wrap" }}>
          {this.state.MedicationFav?.length > 0 && this.state.MedicationFav?.map((item, index) => (
            <Chip
              className={`eMed_chip ${(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
              label={item.name ? item?.name : item}
              style={{ width: "9dvw" }}
              variant={(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'contained' : 'outlined'}
              key={index}
              onClick={() => {
                this.checkDrugClear();
                this.setState({
                  selectedDrug: item,
                  drugList: [],
                  searchedBrandName: null,
                  TapperDrugList: []
                }, () => {
                  this.getTapperDrugDetails();
                });
              }}
              clickable
            />
          ))}
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: "0.5dvw", columnGap: "0.5dvw" }}>
          {this.renderTextField('Quantity *', "tapperQty", '10dvw', true)}
          {this.renderSelect("Select Usage", "tapUsage", Usage_Type, "label", "value", '10dvw')}
          {this.renderTextField('Comments', "tapperComm", '15dvw')}
        </div>
        <div>
          {Object.keys(this.state.tapp_Gen_Details)?.length > 0 &&
            <Box margin={"0.5dvw"}>
              <Typography fontWeight={600} color={Colors.SecondaryText}>Drug Details</Typography>
              <div style={{ marginTop: "0.5dvw", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Box component={'img'} src={ImagePaths.Tablet.default} height={'1.5dvw'} width={'1.5dvw'} />
                <div style={{ marginLeft: "1dvw" }}>
                  <div>{this.state?.tapp_Gen_Details?.drug_name}</div>
                  <div>{`${this.state?.tapp_Gen_Details?.generic_name} | ${this.state?.tapp_Gen_Details?.dosage_type}`}</div>
                </div>
                <div style={{ marginLeft: "3dvw" }}>
                  <div>{this.state?.tapp_Gen_Details?.dosage_strength}</div>
                  <div>Strength</div>
                </div>
                <Button className='Common_Btn_Min_Width' sx={{ marginLeft: "1dvw" }}
                  onClick={() => this.setState(prevState => ({ is_TapperFav: !prevState.is_TapperFav }))}>
                  <Box component={'img'} src={this.state?.is_TapperFav ? ImagePaths.StarFill.default : ImagePaths.StarUnfill.default} height={'1.5dvw'} width={'1.5dvw'} />
                </Button>
              </div>
            </Box>}
          {taperUnit === "Drop(s)" && <div>
            <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5dvw" }}>
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Eye *</Typography>
              {this.renderChip(Eye_Type, this.state.eyeType, "EyeChip")}
            </Box>
          </div>}
          <div style={{ marginTop: "0.5dvw" }}>
            <Typography fontSize={'0.9dvw'} fontWeight={600} color={Colors.SecondaryText}>Tapper Details</Typography>
            <div style={{ display: "flex", marginTop: "0.65dvw", columnGap: "0.5dvw" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={this.state.taperFromDate ? moment(this.state.taperFromDate) : null}
                  inputFormat='DD-MM-YYYY'
                  style={{ width: '10dvw' }}
                  label={"Select Date"}
                  disabled={Object.keys(this.state.tapp_Gen_Details)?.length > 0 ? false : true}
                  minDate={new Date()}
                  onChange={
                    (newDate) => {
                      if (newDate == null) {
                        this.setState({
                          taperFromDate: new Date()
                        });
                      } else {
                        this.setState({
                          taperFromDate: newDate.$d
                        });
                      }
                    }}
                  renderInput={(params) => <TextField size='small'
                    onChange={(newDate) => {
                      if (newDate.$d === "Invalid Date") {
                        this.setState({ taperFromDate: this.state.taperFromDate })
                      }
                    }}
                    {...params} />}
                />
              </LocalizationProvider>
              <div style={{ display: "flex", alignItems: "center" }}>
                {this.renderTextField('Duration *', "tapDuration", '7dvw', true, Object.keys(this.state.tapp_Gen_Details)?.length === 0)}
                {this.renderSelect("", "durationType", duration_type, "label", "value", "6dvw", Object.keys(this.state.tapp_Gen_Details)?.length === 0)}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {this.renderTextField('Unit *', "TapperUnits", '5.5dvw', true, (taperUnit === "Mg" || Object.keys(this.state.tapp_Gen_Details)?.length === 0))}
                <Select
                  value={this.state.unitType}
                  sx={{ width: "5.5dvw" }}
                  onChange={(event) => { this.setState({ unitType: event.target.value }) }}
                  displayEmpty
                  disabled
                  size='small'
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="">{taperUnit}</MenuItem>
                </Select>
              </div>
            </div>
            <div style={{ display: "flex", height: "fit-content", alignItems: "center", marginTop: "0.5dvw", columnGap: "0.5dvw", flexWrap: "wrap" }}>
              {taperUnit === "Drop(s)" ?
                this.renderSelect("Select Frequency *", "Frequency", dropFreq, "label", "value", "15dvw", Object.keys(this.state.tapp_Gen_Details)?.length === 0)
                :
                <div style={{ display: "inline-flex", columnGap: "0.5dvw" }}>
                  {this.renderTextField('Reduced by *', "reducedBy", '12dvw', true, (Object.keys(this.state.tapp_Gen_Details)?.length === 0))}
                  <Autocomplete
                    size='small'
                    sx={{ width: '12dvw' }}
                    clearIcon
                    freeSolo
                    options={this.state.frequency_list}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.frequency_name}
                    value={this.state.frequency_tablet}
                    onChange={(e, value) => {
                      this.setState({
                        frequency_tablet: value
                      })
                    }}

                    renderInput={(params) => <TextField {...params}
                      value={this.state.frequency_tablet}
                      onChange={(e) => {
                        this.setState({
                          frequency_tablet: e.target.value
                        })
                      }}
                      inputProps={{ ...params.inputProps, maxLength: 50 }}
                      label='Frequency' />}
                  />
                </div>}
              <Button
                sx={{ height: '2dvw', width: '5dvw', textTransform: 'capitalize', mr: '1dvw' }}
                variant='contained'
                onClick={this.taperDetailAdd}
              >Add</Button>
            </div>
            <Table size='small' sx={{ marginTop: "0.5dvw", width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={txtStyle}>Days</TableCell>
                  <TableCell sx={txtStyle}>Unit</TableCell>
                  <TableCell sx={txtStyle}>Frequency</TableCell>
                  <TableCell sx={txtStyle}>Reduced By</TableCell>
                  <TableCell sx={txtStyle}>Usage</TableCell>
                  <TableCell sx={{...txtStyle, width: '10%', textAlign: "center"}}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.taperAddedDetails?.length > 0 ? this.state.taperAddedDetails?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div>
                        <div style={{ fontWeight: "600", fontSize: "0.9dvw" }}>{item?.duration.split(" ")[1] === "Day(s)" ? "Days" : item?.duration.split(" ")[1] === "Week(s)" ? "Weeks" : "Months"}</div>
                        <div style = {{fontSize: "0.9dvw"}}>{(item.start_date).split("-").reverse().join("-") + " | " + (item.end_date).split("-").reverse().join("-")}</div>
                      </div>
                    </TableCell>
                    <TableCell>{item?.no_of_dosage ? item?.no_of_dosage : "-"}</TableCell>
                    <TableCell>{item?.frequency ? item?.frequency : "-"}</TableCell>
                    <TableCell>{item?.reducedBy ? item?.reducedBy : "-"}</TableCell>
                    <TableCell>{item?.instructions ? item?.instructions : "-"}</TableCell>
                    <TableCell sx={{width: '10%'}}>
                      <Box component={'div'} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} alignItems={'center'}>
                        <Button
                          className='Common_Btn_Min_Width'
                          onClick={() => {
                            this.onEditTapper(item, index)
                          }}
                          disabled={isLocked}
                        >
                          <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                        </Button>
                        <Button
                          className='Common_Btn_Min_Width'
                          onClick={() => this.onDltTapper(index)}
                          disabled={isLocked}
                        >
                          <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2vw'} width={'1.2vw'} />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                )) : <TableRow>
                  <TableCell colSpan={6} align="center" sx={{ position: 'relative' }}>
                    No Records To Be Shown
                  </TableCell>
                </TableRow>}
              </TableBody>
            </Table>
            <Box display={'flex'} justifyContent={'center'} alignItems={"center"} marginY={"0.5dvw"} columnGap={'0.5dvw'}>
              {(this.state.EditIndex || this.state.isEdit) ?
                <Button
                  sx={{ textTransform: 'capitalize', height: '2dvw' }}
                  disabled={this.state.DisableBtn}
                  onClick={() => this.TapperTableDetails()}
                  variant='contained'>Update</Button>
                :
                <>
                  <Button
                    sx={{ textTransform: 'capitalize', height: '2dvw' }}
                    onClick={this.onClearRightTaperHandler}
                    variant='outlined'>Clear</Button>
                  <Button
                    sx={{ textTransform: 'capitalize', height: '2dvw' }}
                    onClick={this.onAddDrugListHandler}
                    variant='contained'>Add</Button>
                </>}
            </Box>
          </div>
        </div>
      </Box>
    )
  }

  renderMedicationPopup = () => {
    let states = this.state;
    const txtStyle = { fontSize: '0.9dvw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background };
    const isLocked = (this.state.isLocked || this.props.isLocked);

    const medicPharmaId = this.state.MedicData?.find(item => item.pharmacy_id !== null)?.pharmacy_id === this.state.selectedPharmacy;
    const isEdit = (this.state.EditIndex === null && this.state.isEdit);
    try {
      return (
        <Modal open={this.state.openMedicPopUp} sx={{ outline: "none" }} disableEnforceFocus>
          <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Pop_Up'
            sx={{ width: "90dvw", height: "52dvw", display: "flex", flexDirection: "column" }}>
            <Box component={'div'} p={'0.5dvw'} height={'2dvw'} display={'flex'} mt={"0.5dvw"} justifyContent={'space-between'} alignItems={'center'}>
              <Typography style={{ paddingLeft: "0.5dvw" }} fontWeight={600} color={Colors.SecondaryText}>Add Medication</Typography>
              <Button sx={{ marginRight: "-0.5dvw" }} onClick={() => this.setState({ openMedicPopUp: false, EditIndex: null, AddClicked: false, AllowEdit: false }, () => {
                this.onClearHandler()
                this.clearTapperDetails()
                this.setState({
                  prescriptionList: [],
                  prescriptionData: this.state.prescriptionData,
                  selectedPharmacy: '',
                  selectedIndex: null,
                  editItem: null
                })
              })}>
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1dvw'} width={'1.1dvw'} />
              </Button>
            </Box>
            <Tabs
              value={this.state.value}
              onChange={(e, newValue) => this.setState({
                value: newValue,
                selectedDrug: "",
                searchedBrandName: "",
                drugList: [],
                tapp_Gen_Details: {},
                GenericNameSearch: ""
              }, () => {
                this.clearDrugDetails();
                this.onClearRightTaperHandler();
              })}
            >
              <Tab disabled={this.state.disableMedic} value="One" label={"Medication"} className='eMed_tab_header' />
              <Tab disabled={this.state.disableTapper} value="Two" label={"Taper Med"} className='eMed_tab_header' />
            </Tabs>
            {this.state.value === "One" ?
              <Box component={"div"} style={{ padding: "0.5dvw", flex: 1, overflow: "hidden", display: 'flex', columnGap: "0.5dvw" }}>
                {(isEdit) ?
                  this.renderMedicRightDiv(1, medicPharmaId) :
                  <>
                    {this.renderMedicTbl(isLocked, txtStyle)}
                    {this.renderMedicRightDiv(0.4, medicPharmaId)}
                  </>}
              </Box> :
              <Box component={"div"} style={{ padding: "0.5dvw", flex: 1, overflow: "hidden", display: 'flex', columnGap: "0.5dvw" }}>
                {(isEdit) ?
                  this.renderTapperRightDiv(1, medicPharmaId, txtStyle, isLocked) :
                  <>
                    {this.renderTapperTbl()}
                    {this.renderTapperRightDiv(0.4, medicPharmaId, txtStyle, isLocked)}
                  </>}
              </Box>}
            {this.state.value === "One" && <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3dvw'} padding={"0.5dvw"}>
              <Button
                sx={{ height: '2dvw', width: '5dvw', textTransform: 'capitalize' }}
                variant='contained'
                disabled={this.disableFunction() || this.state.DisableBtn}
                onClick={() => {
                  if (this.state.value === "One") {
                    if ((this.state.MedicData.length > 0 || (this.state.isEdit && (Object.keys(this.state.Medic_Gen_Details).length > 0 || this.state.External_Drug_Details?.DrugName !== "") && (states.Medfrequency != "") && states?.MedDuration != "" || (states?.selectedDuration === "Until_the_Next_Visit" || states?.selectedDuration === "Long_Term")))) {
                      this.MedicTableDetails()
                    } else if (Object.keys(this.state.Medic_Gen_Details)?.length < 0) {
                      this.errorMessage("Select Drug Name")
                    } else if ((states.Medfrequency == "")) {
                      this.errorMessage("Select Frequency")
                    } else if (states?.MedDuration == "") {
                      this.errorMessage("Select Duration")
                    }
                  }
                }}
              >Add</Button>
            </Box>}
          </Box>
        </Modal>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  disableFunction = () => {
    if (this.state.value === "One") {
      if (this.state.MedicData.length < 0 && !this.state.isEdit) {
        return true
      } else {
        return false
      }
    } else {
      if (Object.keys(this.state.tapp_Gen_Details).length < 0) {
        return true
      } else {
        return false
      }
    }
  }

  clearTapperDetails = (FromButton = false) => {
    this.setState({
      tapp_Gen_Details: {},
      taperFromDate: new Date(),
      Frequency: "",
      tapDuration: "",
      durationType: "Day(s)",
      TapperUnits: "",
      unitType: "",
      TapperDrugList: [],
      reducedBy: "",
      tapperQty: "",
      is_MefiFav: false,
      is_TapperFav: false,
      taperAddedDetails: [],
      selectedEditIndex: null,
      selectedDrug: '',
      Medic_Gen_Details: {},
      eyeType: "",
      Medfrequency: "",
      searchedBrandName: null,
      MedicDrugList: [],
      tapperComm: "",
      openPrevsMedic: false,
      selectedDrugId: null,
      editItem: null,
      ExternalDrugAdd: FromButton ? this.state.ExternalDrugAdd : false,
      External_Drug_Details: {
        DrugName: "",
        GenericName: "",
        DosageType: "",
        DosageStrength: "",
      },
      EditIndex: null,
      DrugSuggestionList: [],
      AllowEdit: false,
      value: "One",
      drugList: [],
      ExternalBrandName: null,
      ExternalDrugList: [],
      GenericNameSearch: null,
      frequency_tablet:"",
      tapUsage:'',
      newTapMedListData: [],
      saveLoad: false,
    })
  }

  instructions = (item) => {
    let value = '';
    switch (item) {
      case 'BF':
        value = 'Before Food'
        break;
      case 'AF':
        value = 'After Food'
        break;
      case 'EA':
        value = 'External Application'
        break;
      case 'ES':
        value = 'Empty Stomach'
        break;
      case 'WF':
        value = 'With Food'
        break;
      case 'IA':
        value = 'Internal Application'
        break;
      default: break;
    }
    return value;
  }

  onEditMedic = (item) => {
    if (item?.tapper_meds.length === 0) {
      const externalDrugDetail = item?.drug_id ? {
        DrugName: "",
        GenericName: "",
        DosageType: "",
        DosageStrength: "",
      } : {
        DrugName: item?.doctor_drug_name ? item?.doctor_drug_name : item?.brand_name,
        GenericName: item?.doctor_generic_name ? item?.doctor_generic_name : item?.generic_name,
        dosage_type: item?.doctor_dosagetype ? item?.doctor_dosagetype : item?.dosage_type,
        DosageStrength: item?.doctor_drug_strength ? item?.doctor_drug_strength : item?.dosage_strength,
        no_of_dosage: item?.no_of_dosage,
      }
      this.setState({
        disableTapper: true,
        value: "One",
        selectedDrug: item?.brand_name,
        selectedDrugId: item?.drug_id,
        medicId: item?.id,
        eyeType: item?.eye_type,
        selectedDuration: item?.duration.split(" ")[1] === "Until" ? "Until_the_Next_Visit" : item?.duration.split(" ")[1] === "Long" ? "Long_Term" : item?.duration.split(" ")[1],
        MedUsage: item?.instructions,
        MedDuration: item?.duration.split(" ")[1] === "Until" ? "" : item?.duration.split(" ")[1] === "Long" ? "" : +item?.duration.split(" ")[0],
        MedQty: item?.quantity,
        medCommends: item?.comments,
        isEdit: true,
        Morning: +item?.morning,
        Afternoon: +item?.afternoon,
        Evening: +item?.evening,
        Night: +item?.night,
        no_of_Dosage: item?.no_of_dosage,
        Medfrequency: item?.frequency,
        Medic_Gen_Details: item?.drug_id ? item : this.state.Medic_Gen_Details,
        searchedBrandName:item?.brandName,
        GenericNameSearch:item?.generic_name,
        optTemplateId: item?.template_line_id ? item?.template_line_id : null,
        ExternalDrugAdd: item?.drug_id ? false : true,
        External_Drug_Details: externalDrugDetail,
        ExternalBrandName: item?.drug_id ? null : (item?.doctor_drug_name || item?.brand_name),
      }, () => {
        if (item?.drug_id) {
          this.getTapperDrugDetails(true)
        }
      })
    } else {
      this.setState({
        disableMedic: true,
        value: "Two",
        selectedDrug: item?.brand_name,
        TapperId: item?.id,
        taperAddedDetails: item?.tapper_meds,
        tapperQty: item?.quantity,
        tapperComm: item?.comments,
        tapperID: item?.id,
        isEdit: true,
        eyeType: item?.eye_type
      }, () => { this.getTapperDrugDetails() })
    }
  }

  onDeleteHandler = () => {
    try {
      RestAPIService.deleteAll(Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST + `?prescription_id=${this.state.selectedPrescripID}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.setState({
              deletePopup: false, selectedPrescripID: null
            }, () => { this.getTapperMed() })
          }
        }).catch((error) => {
          this.errorMessage(error?.response?.data?.message || error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderMedicationTable = () => {
    try {
      const isLocked = (this.state.isLocked || this.props.isLocked);
      const txtStyle = { fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }
      return (
        <div>
          {this.state.TapperMedList.length > 0 ? this.state.TapperMedList?.map((data, index) => (
            <div>
              {index !== 0 &&
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Box component={'div'} display={'flex'} alignItems={'center'}>
                      <Button className='Common_Btn_Min_Width' disabled={isLocked}
                        onClick={() => {
                          if (data?.is_billed_in_pharmacy == false && !data?.is_transmitted) {
                            this.setState({ selectedPrescripID: data?.prescription_id, deletePopup: true })
                          }
                        }}
                      >
                        {(data?.is_billed_in_pharmacy == true || data?.is_transmitted || isLocked) ?
                          <Box height={'1.2vw'} width={'1.2vw'} component={'img'} src={ImagePaths.DisabledDelete.default} /> :
                          <Box height={'1vw'} width={'1vw'} component={'img'} src={ImagePaths.DeleteOutline.default} />}
                      </Button>
                      <Typography fontSize={'0.8vw'} fontWeight={600}>New Medication</Typography>
                    </Box>
                    {(data?.is_billed_in_pharmacy == false && data?.is_transmitted && !this.props.isLocked) ? <Button
                        className='Common_Btn_Min_Width'
                        onClick={() => {
                          this.setState({ recallPopup: true, RecallPresId: data?.prescription_id })
                        }}
                      >
                        <Tooltip placement='top' title='Recall'>
                          <div style={{ backgroundColor: "#b8170d", borderRadius: "0.2vw", width: "1.4vw", height: "1.5vw" }}>
                            <Box component={'img'} src={ImagePaths.Recall.default} height={'1.1vw'} width={'1.1vw'} />
                          </div>
                        </Tooltip>
                      </Button> :
                        <Button
                          className='Common_Btn_Min_Width'
                          disabled={isLocked || data[index]?.is_transmitted || this.state.DisableBtn}
                          onClick={() => this.getPharmacyList(false, true)}
                        >
                          <Tooltip placement='top' title='Transmit'>
                            <Box component={'img'} src={(isLocked || data[index]?.is_transmitted) ? ImagePaths.medicationTransmitOff.default : ImagePaths.medicationTransmitOn.default} height={'1.1vw'} width={'1.1vw'} />
                          </Tooltip>
                        </Button>}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      className='Common_Btn_Min_Width'
                      onClick={() => {
                        if (!this.state.DisableBtn) {
                          this.getMedicationPrint(data?.prescription_id)
                        }
                      }} disabled={data?.length > 0 || data?.prescription_lines?.length > 0 ? false : true}
                    >
                      <Tooltip placement='top' title='Print'>
                        <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                      </Tooltip>
                    </Button>
                    <Button
                      variant='contained'
                      sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                      onClick={() => {
                        this.setState({ openMedicPopUp: true, tempType: "Add" },
                          () => { this.getPharmacyList(false, false, data) })
                      }}
                      disabled={isLocked || data?.is_transmitted || this.state.DisableBtn}
                    >Add</Button>
                  </div>
                </div>}
              <Box component={'div'} border={'1px solid lightgray'} maxHeight={'15vw'} overflow={'auto'}>
                <Table stickyHeader size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell width={'5%'} sx={{ ...txtStyle, paddingLeft: '3vw' }}>Eye</TableCell>
                      <TableCell width={'25%'} sx={txtStyle}>Drug Name</TableCell>
                      <TableCell width={'24%'} sx={txtStyle}>Frequency</TableCell>
                      <TableCell width={'20%'} sx={txtStyle}>Duration/Unit</TableCell>
                      <TableCell width={'13%'} sx={txtStyle}>Quantity</TableCell>
                      <TableCell width={'13%'} sx={txtStyle}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.prescription_lines?.length > 0 ? data?.prescription_lines?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Box component={'div'} style={{ display: "flex", flexDirection: "row" }}>
                            <Box component={'img'} src={item?.is_favorite ? ImagePaths.StarFill.default : ImagePaths.StarUnfill.default} height={'1.5vw'} width={'1.5vw'} />
                            <div style={{ paddingLeft: "0.5vw" }}>{item?.eye_type ? item?.eye_type : ""}</div>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {item?.brand_name ? (
                            <Box component={'div'}>
                              <Typography fontSize={'0.9vw'} fontWeight={600}>
                                <Tooltip placement='top' title={item?.brand_name}>
                                  <div>
                                    {item?.brand_name.length > 15
                                      ? `${item?.brand_name.slice(0, 15)}... `
                                      : item?.brand_name}
                                  </div>
                                </Tooltip>
                              </Typography>
                              {item?.generic_name && (
                                <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>
                                  {`${typeof item.generic_name === 'object'
                                    ? JSON.stringify(item.generic_name)
                                    : item?.generic_name?.length > 15
                                      ? `${item?.generic_name.slice(0, 15)}...`
                                      : item?.generic_name} | ${item?.dosage_type ? item?.dosage_type : ''}`}
                                </Typography>
                              )}
                            </Box>
                          ) : '-'}
                        </TableCell>
                        <TableCell>{
                          item?.tapper_meds?.length > 0 ? (
                            item?.tapper_meds?.map((list, listIndex) => (
                              <Box key={listIndex} component={'div'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>{list?.no_of_dosage ? list?.no_of_dosage : "-"}</Typography>
                                <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${list?.frequency ? list?.frequency : "-"}`}</Typography>
                              </Box>
                            ))) : <div style={{ fontWeight: "600" }}>{item?.frequency ? item?.frequency : "-"}</div>
                        }</TableCell>
                        <TableCell>
                          {
                            item?.tapper_meds?.length > 0 ? (
                              item?.tapper_meds?.map((list, listIndex) => (
                                <Box key={listIndex} component={'div'}>
                                  <Typography fontSize={'0.9vw'} fontWeight={600}>{list?.duration}</Typography>
                                  <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${list?.start_date ? (list?.start_date).split("-").reverse().join("-") : "-"} | ${list?.end_date ? (list?.end_date).split("-").reverse().join("-") : '-'}`}</Typography>
                                </Box>
                              ))) : <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.duration ? (item?.duration.split(" ")[1] === "Until_the_Next_Visit") ? "Until the Next Visit" : item?.duration : "-"}</Typography>
                          }
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.quantity ? item?.quantity : "-"}</Typography>
                        </TableCell>
                        <TableCell>
                          <Box component={'div'} style={{ marginLeft: "-0.65vw" }} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} alignItems={'center'}>
                            <Button
                              className='Common_Btn_Min_Width'
                              onClick={() => {
                                this.setState({ openMedicPopUp: true, editItem: item, AllowEdit: true }, () => {
                                  this.getPharmacyList()
                                })
                              }}
                              disabled={isLocked || data?.is_transmitted}
                            >
                              <Box component={'img'} src={(isLocked || data?.is_transmitted) ? ImagePaths.DisabledEdit.default : ImagePaths.UserEditOn.default} height={'1.2dvw'} width={'1.2dvw'} />
                            </Button>
                            <Button
                              className='Common_Btn_Min_Width'
                              onClick={() => this.onDeleteMedic(item)}
                              disabled={isLocked || data?.is_transmitted}
                            >
                              <Box component={'img'} src={(isLocked || data?.is_transmitted) ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} height={'1.2dvw'} width={'1.2dvw'} />
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )) :
                      <TableRow>
                        <TableCell colSpan={5} align="center" sx={{ position: 'relative' }}>
                          No Records To Be Shown
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </Box>
            </div>)) :
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>{"No Records To Be Shown"}</div>}
        </div>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPrescriptionTable = () => {
    try {
      let prescriptionData = this.state.prescriptionData?.prescription_lines ? this.state.prescriptionData?.prescription_lines : this.state.prescriptionData;
      const txtStyle = { fontWeight: 600, fontSize: '0.8vw', color: Colors.SecondaryText, backgroundColor: Colors.Background };
      return (
        <Box component={'div'} border={'1px solid lightgray'} maxHeight={'15vw'} overflow={'auto'}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell width={'10%'} sx={txtStyle}>Favourite</TableCell>
                <TableCell width={'30%'} sx={txtStyle}>Prescription</TableCell>
                <TableCell width={'20%'} sx={txtStyle}>M-A-E-N</TableCell>
                <TableCell width={'20%'} sx={txtStyle}>Duration</TableCell>
                <TableCell width={'20%'} sx={txtStyle}>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prescriptionData?.length > 0 ? prescriptionData?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box component={'div'}>
                      <Box component={'img'} src={item?.is_favorite ? ImagePaths.StarFill.default : ImagePaths.StarUnfill.default} height={'1.5vw'} width={'1.5vw'} />
                    </Box>
                  </TableCell>
                  <TableCell>{item?.brand_name ?
                    <Box component={'div'}>
                      <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.brand_name?.length > 15 ?
                        <Tooltip placement='top' title={item?.brand_name}><div>{item?.brand_name?.slice(0, 15) + '... '}</div></Tooltip> :
                        item?.brand_name
                      }</Typography>
                      <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${item?.generic_name ?
                        item?.generic_name?.length > 15 ? <Tooltip placement='top' title={item?.generic_name}><div>{item?.generic_name?.slice(0, 15) + '...'}</div></Tooltip> :
                          item?.generic_name : '-'
                        } | ${item?.dosage_strength ? item?.dosage_strength : '-'} ${item?.dosage_type ? item?.dosage_type : ''}`}</Typography>
                    </Box> : '-'
                  }</TableCell>
                  <TableCell>
                    <Box component={'div'}>
                      <Typography fontSize={'0.8vw'} fontWeight={600}>{`${item?.morning ? item?.morning : '0'} - ${item?.afternoon ? item?.afternoon : '0'} - ${item?.evening ? item?.evening : '0'} - ${item?.night ? item?.night : '0'}`}</Typography>
                      <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{this.instructions(item?.instructions)}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ fontSize: '0.8vw', fontWeight: 600 }}>{`${item?.duration} ${item?.duration_mode === '1' ? 'Days' : item?.duration_mode === '2' ? 'Weeks' : item?.duration_mode === '3' ? 'Months' : 'Years'}`}</TableCell>
                  <TableCell sx={{ fontSize: '0.8vw', fontWeight: 600 }}>{item?.comments ? item?.comments?.length > 10 ?
                    <Tooltip placement='top' title={item?.comments}><div>{item?.comments?.slice(0, 10) + '...'}</div></Tooltip> :
                    item?.comments : '-'}</TableCell>
                </TableRow>
              )) :
                <TableRow>
                  <TableCell
                    colSpan={5}
                    align="center"
                    sx={{ position: 'relative', left: '6.5vw', fontSize: '0.7vw' }}
                  >
                    No Records To Be Shown
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderFavPopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openFav}>
            <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Fav_Pop_Up'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} mt={'0.5vw'}>
                <Typography pl={'0.5vw'} fontWeight={600} color={Colors.SecondaryText}>Select From Favourite</Typography>
                <Button
                  onClick={() => this.setState({ openFav: false, selectedPrescripFav: [] })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                </Button>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} mt={'1vw'}>
                <Autocomplete
                  clearIcon
                  size='small'
                  multiple
                  sx={{ width: '20vw' }}
                  options={this.state.prescriptionFav}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.brand_name}
                  value={this.state.selectedPrescripFav}
                  limitTags={3}
                  autoComplete='off'
                  onChange={(event, newValue) => {
                    this.setState({ selectedPrescripFav: newValue })
                  }}
                  renderInput={(params) => <TextField {...params} label='Select Prescription' />}
                />
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => this.setState({ selectedPrescripFav: [] })}
                >Clear</Button>
                <Button
                  disabled={this.state.DisableBtn || this.state.selectedPrescripFav?.length < 0 || this.state.transmitted ? true : false}
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  variant='contained'
                  onClick={() => {this.TapperTableDetails(this.state.selectedPrescripFav)}}
                >Save</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPrescripTransmitPopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openTransmit}>
            <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Fav_Pop_Up'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} mt={'0.5vw'}>
                <Typography pl={'0.5vw'} fontWeight={600} color={Colors.SecondaryText}>Transmit Prescription</Typography>
                <Button
                  onClick={() => this.setState({ openTransmit: false })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                </Button>
              </Box>
              <Box component={'div'} mt={'1dvw'} ml={'4dvw'} display={'flex'}>
                {this.renderPharmaSlct('20dvw', 0, "selectedTransmitPharmacy")}
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'} columnGap = {'1vw'}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  variant='outlined'
                  onClick={() => this.setState({ selectedTransmitPharmacy: '' })}
                >Clear</Button>
                <Button
                  disabled={this.state.selectedTransmitPharmacy ? false : true}
                  sx={{ height: '2vw', textTransform: 'capitalize' }}
                  variant='contained'
                  onClick={() => this.postPrescriptionTransmit()}
                >Transmit</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  modifyHistory = (Array) => {
    const keysToRemove = ["prescription_id", "appointment_id", "id", "pharmacy_id", "prescription_date"];
    var newArrayWithoutKey = Array.map(obj => {
      const newObj = Object.keys(obj).reduce((accumulator, key) => {
        if (!keysToRemove.includes(key)) {
          accumulator[key] = obj[key];
        }
        return accumulator;
      }, {});

      newObj["frequency"] = obj.frequency_desc;
      newObj['is_tapper_meds'] = obj.tapper_meds?.length === 0 ? false : true

      return newObj;
    })
    return newArrayWithoutKey
  }

  renderPreviousMedicationPopup = () => {
    try {
      const is_locked = (this.state.isLocked || this.props.isLocked);
      const txtStyle = { fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background };
      return (
        <Box component={'div'}>
          <Modal open={this.state.openPrevsMedic}>
            <Box component={'div'} className='eMed_Doc_Notes_Counsellor_Pop_Up'>
              <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={'0.5vw'}>
                <Typography pl={'0.5vw'} fontWeight={600} color={Colors.SecondaryText}>Previous Medication</Typography>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Button variant='contained'
                    sx={{ textTransform: 'capitalize' }}
                    onClick={() => this.TapperTableDetails(this.state.previousMedicData, true)}
                    disabled={this.state.DisableBtn || is_locked || (!this.state.previousMedicData?.length) || (!this.state.Recalled && this.state.transmitted)}
                  >Add</Button>
                  <Button
                    onClick={() => {
                      this.setState({ openPrevsMedic: false })
                    }}
                  >
                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1dvw'} width={'1.1dvw'} />
                  </Button>
                </div>
              </Box>
                <Box height={'21dvw'} overflow={'auto'} margin={"0.5dvw"}>
                  <Table stickyHeader size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell width={'30%'} sx={txtStyle}>Drug Name</TableCell>
                        <TableCell width={'25%'} sx={txtStyle}>Frequency</TableCell>
                        <TableCell width={'25%'} sx={txtStyle}>Duration/Unit</TableCell>
                        <TableCell width={'20%'} sx={txtStyle}>Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.previousMedicData?.length > 0 ? this.state.previousMedicData?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {item?.brand_name ? (
                              <Box component={'div'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>
                                  <Tooltip placement='top' title={item?.brand_name}>
                                    <div>
                                      {item?.brand_name.length > 15
                                        ? `${item?.brand_name.slice(0, 15)}... `
                                        : item?.brand_name}
                                    </div>
                                  </Tooltip>
                                </Typography>
                                {item?.generic_name && (
                                  <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>
                                    {`${typeof item.generic_name === 'object'
                                      ? JSON.stringify(item.generic_name)
                                      : item?.generic_name?.length > 15
                                        ? `${item?.generic_name.slice(0, 15)}...`
                                        : item?.generic_name} | ${item?.dosage_type ? item?.dosage_type : ''}`}
                                  </Typography>
                                )}
                              </Box>
                            ) : '-'}
                          </TableCell>
                          <TableCell>{
                            item?.tapper_meds.length > 0 ? (
                              item?.tapper_meds?.map((list, listIndex) => (
                                <Box key={listIndex} component={'div'}>
                                  <Typography fontSize={'0.9vw'} fontWeight={600}>{list?.no_of_dosage ? list?.no_of_dosage : "-"}</Typography>
                                  <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${list?.frequency ? list?.frequency : "-"}`}</Typography>
                                </Box>
                              ))) : <div style={{ fontWeight: "600" }}>{item?.frequency_desc ? item?.frequency_desc : "-"}</div>
                          }</TableCell>
                          <TableCell>
                            {
                              item?.tapper_meds.length > 0 ? (
                                item?.tapper_meds?.map((list, listIndex) => (
                                  <Box key={listIndex} component={'div'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{list?.duration}</Typography>
                                    <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${list?.start_date ? formatDate(list?.start_date) : "-"} | ${list?.end_date ? formatDate(list?.end_date) : '-'}`}</Typography>
                                  </Box>
                                ))) : <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.duration ? item?.duration : "-"}</Typography>
                            }
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.quantity ? item?.quantity : "-"}</Typography>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan={4} align="center" sx={{ position: 'relative' }}>
                          No Records To Be Shown
                        </TableCell>
                      </TableRow>
                    }
                    </TableBody>
                  </Table>
                </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    // let prescriptionData = (this.state.TapperMedList?.prescription_id || this.state.selectedPharmacy);
    let prescription = (this.state.prescriptionData?.prescription_lines || this.state.prescriptionData);
    const isLocked = (this.state.isLocked || this.props.isLocked);
    return (
      <ShowComponents onIntersection={this.apiCalls.bind(this)}>
        <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
          <Box component={'div'} width = {"100%"} height={'3.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{ backgroundColor: Colors.Background, paddingX: "0.5dvw" }}>
            <Box component={'div'} display={'flex'} alignItems={'center'}>
                <Typography fontWeight={600}>Medication</Typography>
              <Button
                className='Common_Btn_Min_Width'
                disabled={isLocked || this.state.TapperMedList[0]?.is_transmitted || this.state.DisableBtn}
                onClick={() => this.getPharmacyList(false, true)}
              >
                <Tooltip placement='top' title='Transmit'>
                  <Box component={'img'} src={(isLocked || this.state.TapperMedList[0]?.is_transmitted) ? ImagePaths.medicationTransmitOff.default : ImagePaths.medicationTransmitOn.default} height={'1.1vw'} width={'1.1vw'} />
                </Tooltip>
              </Button>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => {
                  this.setState({ openPrevsMedic: true}, () => {
                    this.getPreviousMedicDatas()
                  })
                }}
              >
                <Tooltip placement='top' title={'Previous Medication'}>
                  <Box component={'img'} src={ImagePaths.PreviousPrescription.default} height={'1.1vw'} width={'1.1vw'} />
                </Tooltip>
              </Button>
              {this.state.TapperMedList[0]?.is_billed_in_pharmacy == false && this.state.TapperMedList[0]?.is_transmitted && !this.props.isLocked ? <Button
                className='Common_Btn_Min_Width'
                onClick={() => { this.setState({ recallPopup: true, RecallPresId: this.state.TapperMedList[0]?.prescription_id }) }}
              >
                <Tooltip placement='top' title='Recall'>
                  <div style={{ backgroundColor: "#b8170d", borderRadius: "0.2vw", width: "1.4vw", height: "1.5vw" }}>
                    <Box component={'img'} src={ImagePaths.Recall.default} height={'1.1vw'} width={'1.1vw'} />
                  </div>
                </Tooltip>
              </Button> : null}
            </Box>
            <Box component={'div'} display={'flex'} height = {"fit-content"} alignItems={"center"}>
              <Button
                className='Common_Btn_Min_Width'
                disabled={isLocked || this.state.transmitted}
                onClick={() => this.setState({ openFav: true })}
              >
                <Tooltip placement='top' title='favorite'>
                  <Box component={'img'} src={ImagePaths.StarUnfill.default} height={'1.3vw'} width={'1.3vw'} />
                </Tooltip>
              </Button>
              <Box component={'div'}>
                  <Autocomplete
                    size='small'
                    disabled={isLocked || (this.state.transmitted && !this.state.Recalled)}
                    sx={{ width: '13vw', mr: '0.5vw' }}
                    options={this.state.MedicationTemplateList}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option.template_name}
                    value={this.state.selectedTemplate}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedTemplate: newValue
                      }, () => {
                        if (this.state.selectedTemplate) {
                          this.getMedicationTemplateData()
                        }
                      })
                    }}
                    renderInput={(params) => <TextField {...params} label='Select Template' />}
                    PaperComponent={({ children }) => {
                      return (
                        <Paper component={'div'}>
                          {children}
                          {!this.state.selectedTemplate && <Button
                            color="primary"
                            fullWidth
                            sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                            onMouseDown={(e) => {
                              this.setState({ openPopupAddNew: true, tempType: 'Add', templateName: "" }, () => { this.getPharmacyList() })
                            }}
                          >
                            Add Template
                          </Button>}
                          {this.state.selectedTemplate &&
                            <Box component={'div'}>
                              <Button
                                color="primary"
                                fullWidth
                                sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                onMouseDown={(e) => {
                                  this.setState({
                                    openPopupAddNew: true,
                                    tempType: 'rename',
                                    templateName: this.state.selectedTemplate?.template_name,
                                    templateId: this.state.selectedTemplate?.id
                                  })
                                }}
                              >
                                Update
                              </Button>
                              <Button
                                color="primary"
                                fullWidth
                                sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                onMouseDown={(e) => {
                                  this.setState({
                                    deletePopup: true,
                                    templateName: this.state.selectedTemplate?.template_name
                                  })
                                }}
                              >
                                Delete
                              </Button>
                            </Box>
                          }
                        </Paper>
                      )
                    }}
                  />
              </Box>
              {this.renderSelect("","selectedLanguage", this.state.languageList, "label", "value", '10vw')}
              <Box component={'div'}>
                <Button
                  className='Common_Btn_Min_Width'
                  onClick={() => {
                    if (!this.state.DisableBtn) {
                      this.getMedicationPrint(this.state.TapperMedList[0]?.prescription_id)
                    }
                  }}
                  disabled={(prescription?.length > 0 || this.state.TapperMedList?.prescription_lines?.length > 0) ? false : true}
                >
                  <Tooltip placement='top' title='Print'>
                    <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                  </Tooltip>
                </Button>
              </Box>
              <Box component={'div'}>
                <Button
                  variant='contained'
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize'}}
                  onClick={() => {
                    this.setState({ openMedicPopUp: true, tempType: "Add" },
                      () => { this.getPharmacyList(false, false, this.state.prescriptionData[0]) })
                  }}
                  disabled={isLocked || this.state.TapperMedList[0]?.is_transmitted || this.state.DisableBtn}
                >Add</Button>
              </Box>
            </Box>
          </Box>
          <Box component={'div'} p={'0.5vw'}>
            {this.renderMedicationTable()}
          </Box>
          {this.state.Recalled && <Button className='Common_Btn_Min_Width'
            sx={{ height: '2vw', marginLeft: '0.5vw', textTransform: 'capitalize', marginBottom: "0.5vw" }}
            variant='outlined'
            onClick={() => { this.setState({ newMedication: true, openMedicPopUp: true, tempType: "Add" }, () => { this.getPharmacyList() }) }}
          >
            <Box component={'img'} style={{ marginRight: "0.5vw", borderRadius: "1vw" }} src={ImagePaths.AddIcon.default} height={'1.5vw'} width={'1.5vw'} />
            Add New Medication Table
          </Button>}
          {this.renderNewTemplatePopup()}
          {this.renderMedicationPopup()}
          {this.renderFavPopup()}
          {this.renderPrescripTransmitPopup()}
          {this.renderPreviousMedicationPopup()}
          {this.renderRecallPopup()}
          {this.state.isErrorMsg && 
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />}
          {this.state.successMsg &&
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />}
          {this.state.deletePopup && <DeletePopup
            DeletTitle={this.state.SelectedDeleteChip ?
              `Are you sure ? Do you want to remove ${this.state.SelectedDeleteChip} from suggestion ?` :
              this.state.templateName ? `Are you sure you want to delete ${this.state.templateName}?` :
                `Are you sure you want to delete`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.state.SelectedDeleteChip ? this.deleteFrequencysugg.bind(this) :
              this.state.templateName ? this.deleteTemplate.bind(this) :
                this.onDeleteHandler.bind(this)}
            disable={this.state.removeData}
          />}
          <Loader loaderOpen={this.state.DisableBtn} />
        </Box>
      </ShowComponents>
    )
  }
}