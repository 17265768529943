import {
    Autocomplete, Box, Button, Checkbox, Collapse, FormControl,
    FormControlLabel, FormHelperText, IconButton, InputAdornment,
    InputLabel, MenuItem, Modal, Radio, RadioGroup, Select,
    Table, TableBody, TableCell, TableHead, TableRow, TextField,
    Tooltip, Typography, TablePagination
} from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { Colors } from '../../../Styles/Colors'
import { ImagePaths } from '../../../Utility/ImagePaths'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { AddCircle } from '@material-ui/icons'
import { TimePicker } from 'antd'
import moment from 'moment/moment'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import Loader from '../../../Components/Loader';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents'
import SearchIcon from '@mui/icons-material/Search';
import { AmountFormat, CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { CurrencySymbol, OP_IP_CODE_LENGTH, gstPercentage } from '../../../Utility/Constants'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'
const unitList = [
    { label: 'Nos', value: 'nos' },
    { label: 'Hrs', value: 'hrs' }
]

const SymbolList = [
    { label: `${CurrencySymbol}`, value: 'rupee' },
    { label: '%', value: 'percentage' }
]

const calInclusiveGstAmt = (percentage, amount) => {
    const gstPercentage = Number(percentage || 0);
    const baseAmount = Number(amount);

    const gstAmount = (gstPercentage / 100) * baseAmount;
    return parseFloat((baseAmount + gstAmount).toFixed(2));
}

const targetCategories = new Set([
    'Doctor Consultation Charges',
    'Surgery Charges',
    'Anasthesia Charges',
]);

export default class IPNewServiceConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEdit: false,
            selectedIndex: null,
            ipServiceCategoryData: [],
            ipServiceConfigData: [],
            ipServiceCategory: null,
            doctorData: [],
            fromViewAdvance: false,
            isEdit: false,
            duplicate_data_list: '[]',
            deletePopup: false,
            amountType: null,
            //Consultation Category state
            con_service_type: '',
            con_doctor_name: null,
            con_selected_ward: "All",
            con_hospital_fee: '',
            con_doctor_fee: '',
            con_doctor_percent: '',
            con_hospital_percent: '',
            con_ward_total_amt: '',
            con_holiday_doctor_fee: '',
            con_holiday_hospital_fee: '',
            con_holiday_doctor_percent: '',
            con_holiday_hospital_percent: '',
            con_holiday_total_amt: '',
            con_emergency_doctor_fee: '',
            con_emergency_hospital_fee: '',
            con_emergency_doctor_percent: '',
            con_emergency_hospital_percent: '',
            con_emergency_total_amt: '',
            con_is_edit_amount: false,
            con_is_edit_unit: false,
            con_is_edit_discount: false,
            con_data_list: [],
            con_selected_symbol: 'rupee',
            con_click_advance: false,
            con_advance_ward_list: [],
            dup_con_advance_ward_list: '[]',
            con_final_advance_fee_list: [],
            con_select_method: 'all',
            con_service_list_edit: null,
            con_selected_doctor_name: '',
            con_gst_percent: 0,
            con_selected_view_list: [],
            click_view_advance: false,
            con_selected_id: null,
            con_doctor_id: null,
            //Other Category State
            other_service_name: '',
            other_service_type: '',
            other_selected_time: '',
            other_selected_unit: 'nos',
            other_selected_ward: 'All',
            other_selected_service_amt: '',
            other_holiday_amount: '',
            other_emergency_amount: '',
            other_is_edit_amount: false,
            other_is_edit_unit: false,
            other_is_edit_discount: false,
            other_data_list: [],
            other_selected_method: 'all',
            other_advance_ward_list: [],
            dup_other_advance_ward_list: '[]',
            other_final_advance_fee_list: [],
            other_service_edit_index: null,
            other_view_list: [],
            other_service_id: null,
            duplicate_other_list: '[]',
            dup_advance_ward_list: '[]',
            dup_other_ward_list: '[]',
            searchText: '',
            duplicateIpServiceData: '[]',
            selectedUnitCount: '1',
            surgeryNameList: [],
            surgeryName: null,
            removableIds: [],
            surgery_id: null,
            minimum_amt : '',
            selectedMinTime:null,
            disableBtn: false,
            gstPercentage : "",
            is_gst_enabled: JSON.parse(localGetItem("is_gst_enabled")),
            ipServiceCodeCon:'',
            ipServiceCodeOth:'',
            showCode:false,
            ipServiceCategoryMap: new Map(),
            page: 0,
            rowsPerPage: 10,
        }
        this.TimeListArray = [];
        for (let i = 1; i <= 60; i++) {
            this.TimeListArray[i] = i < 10 ? `0${i}` : i;
        }
    }

    componentDidMount() {
        let showCode = JSON.parse(localGetItem('op_ip_service_code')) ? JSON.parse(localGetItem('op_ip_service_code')) : false
        this.setState({ showCode: showCode })
        this.getIpServiceCategoryData()
        this.getDoctorDataList()
        this.getWardConfigDataList()
        this.getIpServiceConfigData()
        this.getSurgeryNameListData()
    }

    getIpServiceCategoryData = () => {
        try {
            RestAPIService.getAll(Serviceurls.IP_NEW_SERVICE_CATEGORY_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const dataList = response?.data?.data || [];
                        const itemMap = new Map();
                        dataList?.forEach(item => itemMap.set(item?.service_category, item));
                        this.setState({
                            ipServiceCategoryData: dataList,
                            ipServiceCategoryMap: itemMap,
                            ipServiceCategory: itemMap.get('Doctor Consultation Charges')
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.data) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDoctorDataList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data
                        let doctorList = [];
                        let surgeon_fees = {doctor_id:null,
                            doctor_name:"Surgeon fees"}
                            doctorList.push(surgeon_fees)
                        data?.forEach(element => {
                            if (((this.state.ipServiceCategory?.service_category || this.state.ipServiceCategory) === "Anasthesia Charges")) {
                                if (element.specialization_name === 'ANAESTHETIST') {
                                    doctorList.push(element)
                                }
                            } else {
                                doctorList.push(element)
                            }
                        })
                        this.setState({ doctorData: doctorList })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getWardConfigDataList = () => {
        try {
            RestAPIService.getAll(Serviceurls.WARD_CONFIG)
                .then((response) => {
                    this.LoaderFunction(true)
                    if (response.data.status === 'success') {
                        let data = response.data.data
                        data.forEach(element => {
                            element.doctor_fee = ''
                            element.hospital_fee = ''
                            element.total_amount = ''
                            element.doctor_percent = ''
                            element.hospital_percent = ''
                            element.service_amount = ''
                            element.min_service_amount = ''
                            element.minimum_time = ''
                        })
                        this.setState({
                            con_advance_ward_list: data,
                            dup_con_advance_ward_list: JSON.stringify(data)
                        })
                        this.LoaderFunction(false)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getIpServiceConfigData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.IP_NEW_SERVICE_CONFIGURATION)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            ipServiceConfigData: response.data.data,
                            duplicateIpServiceData: JSON.stringify(response.data.data)
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getSurgeryNameListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.IP_SURGERY_NAME_CONFIG)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            surgeryNameList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getIpServiceExcel = () => {
        try{
            this.setState({disableBtn: true})
            RestAPIService.excelGet(Serviceurls.IP_NEW_SERVICE_CONFIGURATION + '?export_type=excel')
            .then((response) => {
                if (response.data) {
                    var pom = document.createElement('a');
                    var csvContent = response.data; //here we load our csv data 

                    let filename = response.headers["content-disposition"].split("filename=")[1]
                    // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                    var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                    var url = URL.createObjectURL(blob);
                    pom.href = url;
                    pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                    pom.click();
                    this.setState({disableBtn: false})
                }
            }).catch((error) => {
                if (error.response?.data?.message) {
                    this.errorMessage(error.response?.data?.message);
                } else {
                    this.errorMessage(error.message);
                }
                this.setState({disableBtn: false})
            })
        }catch (error){
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    deletedIdList = (item) => {
        try {
            let duplicateData = JSON.parse(this.state.duplicate_data_list)
            let removableIds = [];
            if (targetCategories.has(this.state.ipServiceCategory?.service_category || this.state.ipServiceCategory)) {
                let duplicateHash = {}
                item?.forEach(element => {
                    duplicateHash[element.id] = element
                })
                duplicateData?.forEach(element => {
                    if (!duplicateHash[element.id]) {
                        removableIds.push(element.id)
                    }
                })
            } else {
                let duplicateOtherData = JSON.parse(this.state.duplicate_other_list)
                let otherDuplicateHash = {}
                item?.forEach(element => {
                    otherDuplicateHash[element.id] = element
                })
                duplicateOtherData?.forEach(element => {
                    if (!otherDuplicateHash[element.id]) {
                        removableIds.push(element.id)
                    }
                })
            }
            return removableIds;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postIpServiceConfigData = () => {
        try {
            const serviceCategory = this.state.ipServiceCategory?.service_category || this.state.ipServiceCategory;
            let list = targetCategories.has(serviceCategory) ? this.state.con_data_list : this.state.other_data_list
            let removableIds = this.deletedIdList(list)
            // this.setState({
            //     removableIds:removableIds
            // })
            let surgeryName = this.state.surgeryName?.surgery_name ? this.state.surgeryName?.surgery_name : this.state.surgeryName ? this.state.surgeryName.trim() : null
            let surgeryId = this.state.surgeryName?.id ? this.state.surgeryName?.id : this.state.surgeryId
            let data = {
                'delete_id': removableIds,
                "service_type": ((serviceCategory) === "Surgery Charges") ? surgeryName : (targetCategories.has(serviceCategory)) ? this.state.con_service_type : this.state.other_service_type,
                "service_config": (targetCategories.has(serviceCategory)) ? this.state.con_data_list : this.state.other_data_list,
                'surgery_id': ((serviceCategory) === "Surgery Charges") ? surgeryId : null
            }
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.IP_NEW_SERVICE_CONFIGURATION)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.getIpServiceConfigData()
                        this.setState((prevState) => ({
                            ipServiceCategory: prevState?.ipServiceCategoryMap?.get('Doctor Consultation Charges'),
                            con_data_list: [],
                            other_data_list: [],
                            other_service_type: '',
                            con_service_type: '',
                            isEdit: false,
                            deletePopup: false,
                            surgeryName: null,
                        }))
                        removableIds = []
                        this.successMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        console.log(error.message, "error from catch")
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        console.log(error.message, "error from catch")
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            console.log(error.message, "error from catch")
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }


    deleteIpServiceConfigData = () => {
        try {
            let data = {}
            this.LoaderFunction(true)
            RestAPIService.delete(Serviceurls.IP_NEW_SERVICE_CONFIGURATION + `?service_type=${this.state.deletedText}`, data)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                        this.getIpServiceConfigData()
                        this.setState({ deletePopup: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    UsersOption = (Access) => {
        if (Access?.is_edit_discount && Access?.is_edit_unit && Access?.is_edit_amount) {
            return "Unit & Amount & Discount"
        } else if (Access?.is_edit_discount && Access?.is_edit_unit) {
            return "Discount & Unit"
        } else if (Access?.is_edit_amount && Access?.is_edit_unit) {
            return "Amount & Unit"
        } else if (Access?.is_edit_amount && Access?.is_edit_discount) {
            return "Amount & Discount"
        } else if (Access?.is_edit_unit) {
            return "Unit"
        } else if (Access?.is_edit_discount) {
            return "Discount"
        } else if (Access?.is_edit_amount) {
            return "Amount"
        }
    }

    viewAdvancePopup = () => {
        try {
            let states = this.state
            return (
                <Modal open={states.fromViewAdvance}>
                    <Box className='eMed_New_Ip_Popup'>
                        <Box component={'div'} mb={'0.5vw'} position={'sticky'}>
                            <Box display={'flex'} justifyContent={'space-between'} paddingLeft={'1vw'} alignItems={'center'} height={'3vw'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Advanced Fee Structure - {this.state.con_selected_doctor_name}</Typography>
                                <Button onClick={() => this.setState({ fromViewAdvance: false })}>
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                        </Box>
                        {((this.state.con_selected_category_type === 'Doctor Consultation Charges') || (this.state.con_selected_category_type === 'Surgery Charges') || (this.state.con_selected_category_type === 'Anasthesia Charges')) ?
                            <Box component={'div'} ml={'1vw'} height={'23vw'} maxHeight={'23vw'} overflow={'scroll'}>
                                {this.state.con_selected_view_list?.map((list, index) => {
                                    let feeOne = this.state.amountType === 'rupee' ? list?.doctor_fee : list?.doctor_percent
                                    let feeTwo = this.state.amountType === 'rupee' ? list?.hospital_fee : list?.hospital_percent
                                    return <Box component={'div'} mb={'0.5vw'} key={index} pt={'0.5vw'}>
                                        {this.advanceTextBox("ward_name", list?.ward_name, "Ward Name", '10vw', '0.5vw', true)}
                                        {this.advanceTextBox("total_amount", (this.state.con_gst_percent && this.state.is_gst_enabled ) ? CommonValidation.CalculateGSTInclude(list?.total_amount, this.state.con_gst_percent) : list?.total_amount, `Total Fee ${(this.state.con_gst_percent && this.state.is_gst_enabled ) ? ` (Inclu.GST)` : ""}`, '10vw', '0.5vw', true)}
                                        {this.advanceTextBox("doctor_fee", feeOne, "Doctor Fee", '10vw', '0.5vw', true)}
                                        {this.advanceTextBox("hospital_fee", feeTwo, "Hospital Fee", '10vw', '0.5vw', true)}
                                    </Box>
                                })}
                            </Box> :
                            <Box component={'div'} ml={'1vw'} height={'23vw'} maxHeight={'23vw'} overflow={'scroll'}>
                                {this.state.other_view_list?.map((list, index) => {
                                    return <Box component={'div'} mb={'0.5vw'} key={index} pt={'0.5vw'}>
                                        {this.advanceTextBox("ward_name", list?.ward_name, "Ward Name", '10vw', '0.5vw', true)}
                                        {this.advanceTextBox("service_amount", (this.state.con_gst_percent && this.state.is_gst_enabled ) ? CommonValidation.CalculateGSTInclude(list?.service_amount, this.state.con_gst_percent) : list?.service_amount, `Service Fee ${(this.state.con_gst_percent && this.state.is_gst_enabled ) ? ` (Inclu.GST)` : ""}`, '10vw', '0.5vw', true)}
                                        {this.advanceTextBox("min_service_amount", list?.min_service_amount, "Minimum Fee", '10vw', '0.5vw', true)}
                                        {this.advanceTextBox("minimum_time", list?.minimum_time, "Minimum Time", '10vw', '0.5vw', true)}
                                    </Box>
                                })}
                            </Box>
                        }
                        <Box component={'div'}>
                            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} p={'0.5vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.setState({ fromViewAdvance: false }) }}
                                >close</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderViewAdvBtn = (newItem, item, fromKey) => {
        return (
            <Button className='eMed_New_Ip_Btn'
                key={fromKey}
                sx={{ pl: '0vw' }}
                onClick={() => {
                    this.setState({
                        fromViewAdvance: true
                    }, () => {
                        this.setState({
                            con_selected_doctor_name: newItem?.service_name,
                            con_selected_view_list: newItem?.consultant_charge,
                            con_selected_category_type: item?.service_category,
                            amountType: newItem?.amount_type,
                            con_gst_percent : (this.state.is_gst_enabled && newItem?.gst_percentage) ? newItem?.gst_percentage : 0
                        })
                    })
                }}>View Advance</Button>
        )
    }

    renderConsultationTable = (item, index) => {
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                <Collapse in={this.state.selectedIndex === index} timeout={'auto'} unmountOnExit>
                {this.renderGStTxt()}
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                        S.No
                                    </TableCell>
                                    <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                        Doctor Name
                                    </TableCell>
                                    <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                        Doctor Fee
                                    </TableCell>
                                    <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                        Hospital Fee
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.data?.length > 0 ? item?.data?.map((newItem, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            <Box display={'flex'}>
                                                {newItem?.service_name ? newItem?.service_name?.length > 15 ?
                                                    <Tooltip placement='top' title={newItem?.service_name} arrow><div>{newItem?.service_name?.slice(0, 15) + '...'}</div></Tooltip> :
                                                    newItem?.service_name : '-'}
                                                {(newItem?.is_edit_amount || newItem?.is_edit_discount || newItem?.is_edit_unit) ?
                                                    <Tooltip placement='top' title={this.UsersOption(newItem)} arrow>
                                                        <div><Box component={'img'} src={ImagePaths.NewEditcon.default} height={'1vw'} width={'1vw'} position={'relative'} top={'0.2vw'} /></div>
                                                    </Tooltip> : null}
                                            </Box>
                                        </TableCell>
                                        {newItem?.consultant_charge[0]?.ward_name === 'All' ?
                                            newItem?.consultant_charge?.map((val) => (
                                                <><TableCell>{val?.doctor_fee ? ((this.state.is_gst_enabled && newItem?.gst_percentage) ? AmountFormat(CommonValidation.CalculateGSTInclude(val?.doctor_fee, newItem?.gst_percentage) || 0)?.replace(`${CurrencySymbol}`, "") : AmountFormat(val?.doctor_fee || 0)?.replace(`${CurrencySymbol}`, "")) : 0}</TableCell>
                                                    <TableCell>{val?.hospital_fee ? ((this.state.is_gst_enabled && newItem?.gst_percentage) ? AmountFormat(CommonValidation.CalculateGSTInclude(val?.hospital_fee, newItem?.gst_percentage) || 0)?.replace(`${CurrencySymbol}`, "") : AmountFormat(val?.hospital_fee || 0)?.replace(`${CurrencySymbol}`, "")) : 0}</TableCell></>
                                            ))
                                            :
                                            <>
                                                <TableCell>
                                                    {this.renderViewAdvBtn(newItem, item, "docAdvance")}
                                                </TableCell>
                                                <TableCell>
                                                    {this.renderViewAdvBtn(newItem, item, "hosAdvance")}
                                                </TableCell>
                                            </>
                                        }
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell></>
        )
    }

    renderGStTxt = () => {
        const enableGstDiv = this.state.is_gst_enabled ? "block" : "none";
        return (
            <Typography display={enableGstDiv} margin={'0.5dvw'} fontSize={'0.7dvw'}
                fontWeight={600} color={Colors.SecondaryText} textAlign={"right"}>*Inclusive Of GST</Typography>
        )
    }

    renderOtherTable = (item, index) => {
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                <Collapse in={this.state.selectedIndex === index} timeout={'auto'} unmountOnExit>
                    {this.renderGStTxt()}
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                        S.No
                                    </TableCell>
                                    <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                        Service Name
                                    </TableCell>
                                    <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                        Unit
                                    </TableCell>
                                    <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                        Service Amount
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.data?.length > 0 ? item?.data?.map((newItem, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            <Box display={'flex'}>
                                                {newItem?.service_name ? newItem?.service_name?.length > 15 ?
                                                    <Tooltip placement='top' title={newItem?.service_name} arrow><div>{newItem?.service_name?.slice(0, 15) + '...'}</div></Tooltip> :
                                                    newItem?.service_name : '-'}
                                                {newItem?.is_edit_amount || newItem?.is_edit_discount || newItem?.is_edit_unit ?
                                                    <Tooltip placement='top' title={this.UsersOption(newItem)} arrow>
                                                        <div><Box component={'img'} src={ImagePaths.NewEditcon.default} height={'1vw'} width={'1vw'} position={'relative'} top={'0.2vw'} /></div>
                                                    </Tooltip> : null}
                                            </Box>
                                        </TableCell>
                                        <TableCell>{newItem?.unit === 'hrs' ? `${newItem?.time_taken?.slice(0, 5)} Hrs` : newItem?.unit}</TableCell>
                                        {newItem?.consultant_charge?.length > 0 && newItem?.consultant_charge[0]?.ward_name === 'All' ?
                                            newItem?.consultant_charge?.map((val) => (
                                                <TableCell>{AmountFormat(val?.service_amount ? ((this.state.is_gst_enabled && newItem?.gst_percentage) ? CommonValidation.CalculateGSTInclude(val?.service_amount, newItem?.gst_percentage ) :  val?.service_amount) : 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                                            ))
                                            :
                                            <TableCell>
                                                <Button
                                                    sx={{ pl: '0vw' }}
                                                    className='eMed_New_Ip_Btn'
                                                    onClick={() => {
                                                        this.setState({
                                                            fromViewAdvance: true
                                                        }, () => {
                                                            this.setState({
                                                                con_selected_doctor_name: newItem?.service_name,
                                                                other_view_list: newItem?.consultant_charge,
                                                                con_selected_category_type: item?.service_category,
                                                                con_gst_percent : (this.state.is_gst_enabled && newItem?.gst_percentage) ? newItem?.gst_percentage : 0
                                                            })
                                                        })
                                                    }}>View Advance</Button>
                                            </TableCell>
                                        }
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell></>
        )
    }

    onClearServiceList = (item) => {
        try {
            this.setState({
                isEdit: true,
                con_service_type: '',
                con_doctor_name: '',
                gstPercentage: '',
                con_select_method: 'all',
                con_selected_symbol: 'rupee',
                con_ward_total_amt: '',
                con_selected_ward: 'All',
                con_advance_ward_list: JSON.parse(this.state.dup_con_advance_ward_list),
                con_data_list: []
            }, () => {
                this.onEditHandler(item)
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item) => {
        try {
            const consultantCharges = item?.data?.map(element => element.consultant_charge) || [];
            if (targetCategories.has(item?.service_category)) {
                const isSurgeryCategory = item?.service_category === 'Surgery Charges';
                this.setState((prevState)=>({
                    con_data_list: item?.data,
                    duplicate_data_list: JSON.stringify(item?.data),
                    con_service_type: isSurgeryCategory ? prevState.con_service_type : item?.service_type,
                    ipServiceCategory: item?.service_category,
                    con_final_advance_fee_list: consultantCharges,
                    surgeryName: isSurgeryCategory ? item?.service_type : prevState.surgeryName,
                    surgeryId: isSurgeryCategory ? item?.surgery_id : prevState.surgeryId
                }))
            } else {
                this.setState({
                    other_data_list: item?.data,
                    duplicate_other_list: JSON.stringify(item?.data),
                    other_service_type: item?.service_type,
                    ipServiceCategory: item?.service_category,
                    other_final_advance_fee_list: consultantCharges,
                })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onSearchHandler = (event) => {
        try {
            const searchText = event.target.value.toLowerCase();
            this.setState({ searchText: event.target.value },
                () => {
                    const normalData = JSON.parse(this.state.duplicateIpServiceData);
                    let filteredData = searchText ? normalData.filter((item) => {
                        return (
                            (item?.service_type === ''
                                ? ''
                                : item?.service_type?.toLowerCase()?.includes(searchText)) ||
                            (item?.service_category === ''
                                ? ''
                                : item?.service_category?.toLowerCase()?.includes(searchText))
                        );
                    }) : normalData;
                    this.setState({ ipServiceConfigData: filteredData });
                }
            );
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    renderLeftContent = () => {
        const {ipServiceConfigData, rowsPerPage, page} = this.state;
        const displayedData = ipServiceConfigData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        return (
            <Box component={'div'} border={'1px solid lightgray'} sx={{ backgroundColor: 'white' }} height={'78dvh'}>
                <Box component={'div'} height={'5dvh'} alignItems={'center'} display={'flex'} justifyContent={'flex-end'} pr={'1vw'} gap={'1vw'}>
                    <Tooltip title="Export" placement='top' arrow>
                        <Button onClick={() => { this.getIpServiceExcel() }} disabled={this.state.disableBtn} className='eMed_usrconf_btn' size='small'>
                            <img src={ImagePaths.ExportIcon.default} alt="upload" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                    <TextField
                        size='small'
                        variant='standard'
                        value={this.state.searchText}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>)
                        }}
                        placeholder='Search...'
                        onChange={(e) => this.onSearchHandler(e)}
                        autoComplete='off'
                    />
                </Box>
                <Box sx={{ height: '65dvh', overflowY: 'auto' }}>
                <Table size='small'stickyHeader >
                    <TableHead sx={{ height: "5vh" }}>
                        <TableRow>
                            <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                S.No
                            </TableCell>
                            <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                Category
                            </TableCell>
                            <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                Service Type
                            </TableCell>
                            <TableCell align='center' sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                Action
                            </TableCell>
                            <TableCell sx={{ backgroundColor: Colors.Background }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedData?.length > 0 ? displayedData?.map((item, index) => (
                            <> <TableRow key={index}>
                                <TableCell sx={{ fontSize: "1vw" }}>{page * rowsPerPage + index + 1}</TableCell>
                                <TableCell sx={{ fontSize: "1vw" }}>{item?.service_category}</TableCell>
                                <TableCell sx={{ fontSize: "1vw" }}>{item?.service_type ? item?.service_type?.length > 35 ?
                                    <Tooltip placement='top' title={item?.service_type} arrow><div>{item?.service_type.slice(0, 35) + '...'}</div></Tooltip> : item?.service_type : '-'}</TableCell>
                                <TableCell align='center'>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Box mr={'1vw'}>
                                            <Button
                                                className='eMed_New_Ip_Btn'
                                                sx={{ width: '1.7vw', height: '1.7vw' }}
                                                onClick={() => this.onClearServiceList(item)}
                                                disabled={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Service Name & Type Config", "SubTab")?.editAccess}
                                            >
                                                <Box component={'img'} src={CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Service Name & Type Config", "SubTab")?.editAccess ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} alt='edit' height={'1.8vw'} width={'1.8vw'} />
                                            </Button>
                                        </Box>
                                        <Box mr={'1vw'}>
                                            <Button
                                                className='eMed_New_Ip_Btn'
                                                sx={{ width: '1.7vw', height: '1.7vw' }}
                                                onClick={() => this.onDeleteHandler(item)}
                                                disabled={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Service Name & Type Config", "SubTab")?.editAccess}
                                            >
                                                <Box component={'img'} src={CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Service Name & Type Config", "SubTab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} alt='edit' height={'1.8vw'} width={'1.8vw'} />
                                            </Button>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        size='small'
                                        onClick={() => {
                                            this.setState({ selectedIndex: this.state.selectedIndex === index ? null : index })
                                        }}
                                    >
                                        {this.state.selectedIndex === index ?
                                            <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                                {<TableRow>
                                    {(targetCategories.has(item?.service_category)) ?
                                        this.renderConsultationTable(item, index) : this.renderOtherTable(item, index)}
                                </TableRow>}
                            </>
                        )) : <TableRow>
                            <TableCell colSpan={5} align="center">{"No Records To Be Shown"}</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                </Box>
                <TablePagination
                    component='div'
                    rowsPerPageOptions={[10, 20, 30]}
                    count={ipServiceConfigData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => { this.setState({ page: newPage }) }}
                    onRowsPerPageChange={(event) => { this.setState({ rowsPerPage: +event.target.value, page: 0 }) }}
                />
            </Box>
        )
    }


    //<===== RIGHT CONTENT STARTS ======>//

    updateOrInsertList = (array, newValue, selectedIndex) => {
        try {
            if (array.length === 0) {
                return [newValue]
            } else {
                const obj = array.find((item, index) => index === selectedIndex)
                if (!obj) {
                    return [...array, newValue]
                } else {
                    if (JSON.stringify(obj) === JSON.stringify(newValue)) {
                        return [...array.filter((object, index) => index !== selectedIndex), newValue]
                    } else {
                        return [...array.filter((object, index) => index !== selectedIndex), newValue]
                    }
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onCheckboxHandler = (e, key) => {
        try {
            let states = this.state
            states[key] = e.target.checked
            this.setState({ states })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateSharePercentage = (value, name) => {
        try {
            let states = this.state
            switch (name) {
                case 'con_doctor_fee':
                    states.con_hospital_percent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'con_hospital_fee':
                    states.con_doctor_percent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateShareAmount = (value, name) => {
        try {
            let states = this.state
            switch (name) {
                case 'con_doctor_fee':
                    states.con_hospital_fee = (+states.con_ward_total_amt) - (+value)
                    this.setState({ states })
                    break;
                case 'con_hospital_fee':
                    states.con_doctor_fee = (+states.con_ward_total_amt) - (+value)
                    this.setState({ states })
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e) => {
        try {
            const { name, value } = e.target
            let states = this.state
            switch (name) {
                //Other Category OnChange =====>
                case 'other_service_name':
                    if (CommonValidation.serviceType(value) || value === '') {
                        this.setState({ other_service_name: value })
                    }
                    break;
                case 'other_service_type':
                    if (CommonValidation.serviceType(value) || value === '') {
                        this.setState({ other_service_type: value })
                    }
                    break;
                case 'other_selected_service_amt':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        this.setState({ other_selected_service_amt: +value })
                    }
                    break;
                case 'minimum_amt':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        this.setState({ minimum_amt: +value })
                    }
                    break;
                //Consultation Category OnChange ====>
                case 'con_service_type':
                    if (CommonValidation.serviceType(value) || value === '') {
                        this.setState({ con_service_type: value })
                    }
                    break;
                case 'con_selected_ward':
                    this.setState({ con_selected_ward: value })
                    break;
                case 'con_ward_total_amt':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        states.con_doctor_fee = ''
                        states.con_hospital_fee = ''
                        states.con_doctor_percent = ''
                        states.con_hospital_percent = ''
                        this.setState({ con_ward_total_amt: value })
                    }
                    break;
                case 'con_doctor_fee':
                    if (states.con_selected_symbol === 'rupee') {
                        if ((CommonValidation.NumberWithDot(value) && +value <= +states.con_ward_total_amt) || value === "") {
                            this.setState({ con_doctor_fee: +value }, () => this.calculateShareAmount(value, name))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            this.setState({ con_doctor_percent: value }, () => this.calculateSharePercentage(value, name))
                        }
                    }
                    break;
                case 'con_hospital_fee':
                    if (states.con_selected_symbol === 'rupee') {
                        if ((CommonValidation.NumberWithDot(value) && +value <= +states.con_ward_total_amt) || value === '') {
                            this.setState({ con_hospital_fee: +value }, () => this.calculateShareAmount(value, name))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            this.setState({ con_hospital_percent: value }, () => this.calculateSharePercentage(value, name))
                        }
                    }
                    break;
                default:
                    this.setState({ [name]: value })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    inputTextBox = (names, values, label, width, mr, ml, disable, startAdornment, endAdornment) => {
        try {
            return (
                <TextField
                    size='small'
                    disabled={disable}
                    sx={{ width: width, mr: mr, ml: ml }}
                    label={label}
                    autoComplete='off'
                    name={names}
                    value={values}
                    onChange={(e) => this.onChangeHandler(e)}
                    InputProps={
                        startAdornment ?
                            {
                                startAdornment: <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{CurrencySymbol}</Typography>
                            } :
                            endAdornment ? {
                                endAdornment: this.state.con_selected_symbol === 'rupee' ?
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{CurrencySymbol}</Typography>
                                    : <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>%</Typography>
                            } : null
                    }
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    clearConsultationCategory = () => {
        try {
            this.setState({
                con_doctor_name: null,
                con_selected_ward: 'All',
                con_ward_total_amt: '',
                con_doctor_fee: '',
                con_hospital_fee: '',
                con_doctor_percent: '',
                con_hospital_percent: '',
                con_holiday_doctor_fee: '',
                con_holiday_hospital_fee: '',
                con_holiday_doctor_percent: '',
                con_holiday_hospital_percent: '',
                con_holiday_total_amt: '',
                con_emergency_doctor_fee: '',
                con_emergency_hospital_fee: '',
                con_emergency_doctor_percent: '',
                con_emergency_hospital_percent: '',
                con_emergency_total_amt: '',
                con_is_edit_amount: false,
                con_is_edit_discount: false,
                con_is_edit_unit: false,
                con_advance_ward_list: JSON.parse(this.state.dup_con_advance_ward_list),
                con_click_advance: false,
                con_select_method: 'all',
                con_selected_symbol: 'rupee',
                con_service_list_edit: null,
                con_doctor_id: null,
                con_selected_id: null,
                dup_advance_ward_list: '[]',
                ipServiceCodeCon:'',
                gstPercentage : ""
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateAdvanceSharePercentage = (names, value, index) => {
        try {
            let states = this.state
            switch (names) {
                case 'doctor_fee':
                    states['con_advance_ward_list'][index]['hospital_percent'] = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'hospital_fee':
                    states['con_advance_ward_list'][index]['doctor_percent'] = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calulateAdvanceShareAmount = (names, value, index) => {
        try {
            let states = this.state
            switch (names) {
                case 'doctor_fee':
                    states['con_advance_ward_list'][index]['hospital_fee'] = +((+states['con_advance_ward_list'][index]['total_amount']) - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'hospital_fee':
                    states['con_advance_ward_list'][index]['doctor_fee'] = +((+states['con_advance_ward_list'][index]['total_amount']) - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeAdvanceHandler = (e, names, index) => {
        try {
            let states = this.state
            let value = e.target.value
            switch (names) {
                case 'total_amount':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        states['con_advance_ward_list'][index]['doctor_fee'] = ''
                        states['con_advance_ward_list'][index]['hospital_fee'] = ''
                        states['con_advance_ward_list'][index]['doctor_percent'] = ''
                        states['con_advance_ward_list'][index]['hospital_percent'] = ''
                        states['con_advance_ward_list'][index]['total_amount'] = value
                        this.setState({ states })
                    }
                    break;
                case 'doctor_fee':
                    if (states.con_selected_symbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && +value <= +states['con_advance_ward_list'][index]['total_amount'] || value === '') {
                            states['con_advance_ward_list'][index]['doctor_fee'] = +value
                            this.setState({ states }, () => this.calulateAdvanceShareAmount(names, value, index))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && +value <= 100 || value === '') {
                            states['con_advance_ward_list'][index]['doctor_percent'] = value
                            this.setState({ states }, () => this.calculateAdvanceSharePercentage(names, value, index))
                        }
                    }
                    break;
                case 'hospital_fee':
                    if (states.con_selected_symbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && +value <= +states['con_advance_ward_list'][index]['total_amount'] || value === '') {
                            states['con_advance_ward_list'][index]['hospital_fee'] = +value
                            this.setState({ states }, () => this.calulateAdvanceShareAmount(names, value, index))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && +value <= 100 || value === '') {
                            states['con_advance_ward_list'][index]['hospital_percent'] = value
                            this.setState({ states }, () => this.calculateAdvanceSharePercentage(names, value, index))
                        }
                    }
                    break;
                case 'service_amount':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        states['con_advance_ward_list'][index]['service_amount'] = +value
                        this.setState({ states })
                    }
                    break;
                case 'min_service_amount':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        states['con_advance_ward_list'][index]['min_service_amount'] = +value
                        this.setState({ states })
                    }
                    break;
                case 'minimum_time':
                    if (CommonValidation.NumberWithDot(value) && value <= 59 || value === '') {
                        states['con_advance_ward_list'][index]['minimum_time'] = +value
                        this.setState({ states })
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    advanceTextBox = (names, values, label, width, mr, disable, index) => {
        try {
            return (
                <TextField
                    size='small'
                    disabled={disable}
                    sx={{ width: width, mr: mr }}
                    label={label}
                    name={names}
                    autoComplete='off'
                    value={values}
                    onChange={(e) => this.onChangeAdvanceHandler(e, names, index)}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onSaveAdvanceFee = () => {
        try {
            let states = this.state

            if (targetCategories.has(states.ipServiceCategory?.service_category || states.ipServiceCategory)) {
                let advanceFeeList = [];
                states.con_advance_ward_list.forEach(element => {
                    if (element.total_amount !== '') {
                        element.doctor_fee = states.con_selected_symbol === 'rupee' ? element.doctor_fee : CommonValidation.calculatePercentage(element.doctor_percent, element.total_amount)
                        element.hospital_fee = states.con_selected_symbol === 'rupee' ? element.hospital_fee : CommonValidation.calculatePercentage(element.hospital_percent, element.total_amount)
                        element.doctor_percent = states.con_selected_symbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(element.doctor_fee, element.total_amount) : +element.doctor_percent
                        element.hospital_percent = states.con_selected_symbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(element.hospital_fee, element.total_amount) : +element.hospital_percent
                        advanceFeeList.push(element)
                    }
                })
                if (advanceFeeList?.length === 0) {
                    this.errorMessage("Enter atleast one advance structure fee")
                } else {
                    this.setState({
                        con_final_advance_fee_list: advanceFeeList,
                        con_click_advance: false,
                        dup_advance_ward_list: JSON.stringify(this.state.con_advance_ward_list)
                    })
                }
            } else {
                let otherAdvanceFeeList = []
                let otherMinimumFeeList = []
                states.con_advance_ward_list?.forEach(element => {
                    if (element.service_amount !== '') {
                        otherAdvanceFeeList.push(element)
                    }
                    if(element.service_amount !== ''){
                        otherMinimumFeeList.push(element)
                    }
                })
                if (otherAdvanceFeeList?.length === 0) {
                    this.errorMessage("Enter atleast one Full advance structure fee")
                } else if(otherMinimumFeeList?.length === 0){
                    this.errorMessage("Enter atleast one Full advance structure fee")
                } else {
                    this.setState({
                        other_final_advance_fee_list: otherAdvanceFeeList,
                        other_final_Minimum_fee_list: otherMinimumFeeList,
                        dup_other_ward_list: JSON.stringify(this.state.con_advance_ward_list),
                        con_click_advance: false
                    })
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    addAdvanceFeePopup = () => {
        try {
            let states = this.state;
            const isTargetCategory = targetCategories.has(states.ipServiceCategory?.service_category || states.ipServiceCategory);
            return (
                <Modal open={states.con_click_advance}>
                    <Box className='eMed_New_Ip_Popup'>
                        <Box component={'div'} mb={'0.5vw'} position={'sticky'}>
                            <Box display={'flex'} justifyContent={'space-between'} paddingLeft={'1vw'} alignItems={'center'} height={'3vw'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Advanced Fee Structure - {states.con_doctor_name?.doctor_name ? states.con_doctor_name?.doctor_name : this.state.other_service_name}</Typography>
                                <Button onClick={() => {
                                    if (isTargetCategory) {
                                        let dupWardList = JSON.parse(states.dup_advance_ward_list)
                                        let data = dupWardList?.length > 0 ? dupWardList : JSON.parse(states.dup_con_advance_ward_list)
                                        this.setState({ con_advance_ward_list: data, con_click_advance: false })
                                    } else {
                                        let otherDupWardList = JSON.parse(states.dup_other_ward_list)
                                        let data = otherDupWardList?.length > 0 ? otherDupWardList : JSON.parse(states.dup_con_advance_ward_list)
                                        this.setState({ con_advance_ward_list: data, con_click_advance: false })
                                    }
                                }}>
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                        </Box>
                        {isTargetCategory ?
                            <Box component={'div'} ml={'1vw'} height={'23vw'} maxHeight={'23vw'} overflow={'scroll'}>
                                {states.con_advance_ward_list?.length > 0 ? states.con_advance_ward_list?.map((item, index) => {
                                    let fee = states['con_advance_ward_list'][index]['total_amount'] === '' ? true : false
                                    let keyOne = states.con_selected_symbol === 'rupee' ? item?.doctor_fee : item?.doctor_percent
                                    let keyTwo = states.con_selected_symbol === 'rupee' ? item?.hospital_fee : item?.hospital_percent
                                    return <Box component={'div'} mb={'0.5vw'} key={index} pt={'0.5vw'}>
                                        {this.advanceTextBox("ward_name", item?.ward_name, "Ward Name", '10vw', '0.5vw', true, index)}
                                        {this.advanceTextBox("total_amount", item?.total_amount, "Total Fee", '10vw', '0.5vw', false, index)}
                                        {this.advanceTextBox("doctor_fee", keyOne, "Doctor Fee", '10vw', '0.5vw', fee, index)}
                                        {this.advanceTextBox("hospital_fee", keyTwo, "Hospital Fee", '10vw', '0.5vw', fee, index)}
                                    </Box>
                                }) : <Typography margin={'10vw 0vw 0vw 15vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>No Records To Be Shown</Typography>}
                            </Box> :
                            <Box component={'div'} ml={'1vw'} height={'23vw'} maxHeight={'23vw'} overflow={'scroll'}>
                                {states.con_advance_ward_list?.length > 0 ? states.con_advance_ward_list?.map((item, index) => {
                                    return <Box component={'div'} mb={'0.5vw'} key={index} pt={'0.5vw'}>
                                        {this.advanceTextBox("ward_name", item?.ward_name, "Ward Name", '10vw', '0.5vw', true, index)}
                                        {this.advanceTextBox("service_amount", item?.service_amount, "Service Amount", '10vw', '0.5vw', false, index)}
                                        {this.advanceTextBox("min_service_amount", item?.min_service_amount, "Interval Amount", '10vw', '0.5vw', false, index)}
                                        {this.advanceTextBox("minimum_time", item?.minimum_time, "Interval Time", '10vw', '0.5vw', false, index)}
                                    </Box>
                                }) : <Typography margin={'10vw 0vw 0vw 15vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>No Records To Be Shown</Typography>}
                            </Box>
                        }
                        <Box component={'div'}>
                            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} p={'0.5vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => {
                                        if (isTargetCategory) {
                                            let dupWardList = JSON.parse(states.dup_advance_ward_list)
                                            let data = dupWardList?.length > 0 ? dupWardList : JSON.parse(states.dup_con_advance_ward_list)
                                            this.setState({ con_advance_ward_list: data })
                                        } else {
                                            let otherDupWardList = JSON.parse(states.dup_other_ward_list)
                                            let data = otherDupWardList?.length > 0 ? otherDupWardList : JSON.parse(states.dup_con_advance_ward_list)
                                            this.setState({ con_advance_ward_list: data })
                                        }
                                    }}
                                >Reset</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                                    variant='contained'
                                    onClick={() => this.onSaveAdvanceFee()}
                                >Save</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    consultationServiceEdit = (item, index) => {
        try {
            if (item?.fee_type === 'advance') {
                let states = this.state
                let wardlistHash = {};
                let newWardList = [];

                item?.consultant_charge?.forEach(element => {
                    wardlistHash[element.id] = element
                })
                states.con_advance_ward_list?.forEach(element => {
                    if (wardlistHash[element.id]) {
                        newWardList.push(wardlistHash[element.id])
                    } else {
                        newWardList.push(element)
                    }
                })
                this.setState({
                    con_advance_ward_list: newWardList,
                    con_selected_ward: "All",
                    con_ward_total_amt: '',
                    con_doctor_fee: '',
                    con_hospital_fee: '',
                    con_doctor_percent: '',
                    con_hospital_percent: '',
                    dup_advance_ward_list: JSON.stringify(newWardList)
                })
            } else {
                this.setState({
                    con_selected_ward: item?.consultant_charge[0]?.ward_name,
                    con_ward_total_amt: item?.consultant_charge[0]?.total_amount,
                    con_doctor_fee: item?.amount_type === 'rupee' ? item?.consultant_charge[0]?.doctor_fee : item?.consultant_charge[0]?.doctor_percent,
                    con_hospital_fee: item?.amount_type === 'rupee' ? item?.consultant_charge[0]?.hospital_fee : item?.consultant_charge[0]?.hospital_percent,
                    con_doctor_percent: item?.consultant_charge[0]?.doctor_percent,
                    con_hospital_percent: item?.consultant_charge[0]?.hospital_percent,
                    con_advance_ward_list: JSON.parse(this.state.dup_con_advance_ward_list)
                })
            }
            this.setState({
                con_service_type: item?.service_type,
                con_doctor_name: item?.service_name,
                con_selected_symbol: item?.amount_type,
                con_is_edit_amount: item?.is_edit_amount,
                con_is_edit_discount: item?.is_edit_discount,
                con_is_edit_unit: item?.is_edit_unit,
                con_service_list_edit: index,
                con_select_method: item?.fee_type,
                gstPercentage: this.state.is_gst_enabled ? item?.gst_percentage : 0,
                con_selected_id: item?.id,
                con_doctor_id: item?.doctor_id,
                is_edit_line_item: true,
                ipServiceCodeCon:item?.ip_service_code ? item?.ip_service_code : ''
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkConsultationDuplicate = () => {
        try {
            let states = this.state
            let message = null;
            states.con_data_list?.forEach((element, index) => {
                if (index !== states.con_service_list_edit) {
                    if ((CommonValidation.removeSpace(element.service_name) === CommonValidation.removeSpace(states.con_doctor_name?.doctor_name)) || (CommonValidation.removeSpace(element.service_name) === CommonValidation.removeSpace(states.con_doctor_name))) {
                        message = 'Service Name Already Exist'
                    }else if(this.state.ipServiceCodeCon !== '' && element.ip_service_code?.toLowerCase() === CommonValidation.removeSpace(this.state.ipServiceCodeCon)?.toLowerCase()){
                        message = 'Service Code Already Exist'
                    }
                }
            })
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkValidation = () => {
        try {
            let message = null;
            let states = this.state
            if (states.con_service_type === '' && states.con_doctor_name === null) {
                message = "Enter all mandatory fields"
            } else if (states.con_service_type !== '' && states.con_doctor_name !== null) {
                if (states.con_select_method === 'all') {
                    if (states.con_ward_total_amt === '') {
                        message = "Enter total amount"
                    }
                } else if (states.con_final_advance_fee_list.length === 0) {
                    message = "Enter atleast one advance fee structure"
                }
            } else if (states.con_doctor_name === null) {
                message = "Select the Doctor Name"
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    addConsulationServiceData = () => {
        try {
            let states = this.state
            let duplicate = this.checkConsultationDuplicate()
            let validate = this.checkValidation()
            if (duplicate === null && validate === null) {
                let allServiceList = [{
                    'ward_name': states.con_selected_ward,
                    'total_amount': states.con_ward_total_amt,
                    'doctor_fee': states.con_selected_symbol === 'rupee' ? states.con_doctor_fee : CommonValidation.calculatePercentage(states.con_doctor_percent, states.con_ward_total_amt),
                    'hospital_fee': states.con_selected_symbol === 'rupee' ? states.con_hospital_fee : CommonValidation.calculatePercentage(states.con_hospital_percent, states.con_ward_total_amt),
                    'doctor_percent': states.con_selected_symbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.con_doctor_fee, states.con_ward_total_amt) : +states.con_doctor_percent,
                    'hospital_percent': states.con_selected_symbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.con_hospital_fee, states.con_ward_total_amt) : +states.con_hospital_percent
                }]
                let categoryId = states.ipServiceCategoryData?.find((item) => ((item?.service_category === states.ipServiceCategory)))
                let service_list = {
                    'ip_service_code':states.ipServiceCodeCon ? CommonValidation.removeSpace(states.ipServiceCodeCon) : '',
                    'service_category_id': categoryId?.id ? categoryId?.id : states.ipServiceCategory?.id,
                    'service_type': states.surgeryName?.surgery_name ? CommonValidation.removeSpace(states.surgeryName?.surgery_name) : CommonValidation.removeSpace(states.con_service_type),
                    'surgery_id': states.surgeryName?.id ? states.surgeryName?.id : null,
                    'service_name': states.con_doctor_name?.doctor_name ? CommonValidation.removeSpace(states.con_doctor_name?.doctor_name) : CommonValidation.removeSpace(states.con_doctor_name),
                    'doctor_id': states.con_doctor_name?.doctor_id ? states.con_doctor_name?.doctor_id : states.con_doctor_id,
                    'amount_type': states.con_selected_symbol,
                    'consultant_charge': states.con_select_method === 'all' ? allServiceList : states.con_final_advance_fee_list,
                    'is_edit_amount': states.con_is_edit_amount,
                    'is_edit_unit': states.con_is_edit_unit,
                    'is_edit_discount': states.con_is_edit_discount,
                    'fee_type': states.con_select_method,
                    'id': states.con_selected_id ? states.con_selected_id : null,
                    'gst_percentage': (states?.gstPercentage && this.state.is_gst_enabled) ? +states?.gstPercentage : 0,
                    'delete_id': [],
                    'time_taken': null,
                    'unit': 1
                }
                let data = this.updateOrInsertList(states.con_data_list, service_list, states.con_service_list_edit)
                this.setState({ con_data_list: data }, () => this.clearConsultationCategory())
            } else {
                if (duplicate !== null) {
                    this.errorMessage(duplicate)
                } else if (validate !== null) {
                    this.errorMessage(validate)
                } else {
                    this.errorMessage("Enter all mandatory fields")
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderConsultationScreen = () => {
        try {
            let states = this.state
            let { con_data_list } = this.state
            let fees = states.con_ward_total_amt === '' ? true : false
            let disable = this.state.con_select_method === 'advance' ? true : false
            let keyOne = this.state.con_selected_symbol === 'rupee' ? states.con_doctor_fee : states.con_doctor_percent
            let keyTwo = this.state.con_selected_symbol === 'rupee' ? states.con_hospital_fee : states.con_hospital_percent
            return (
                <Box component={'div'} padding={'0.5vw'}>
                    <Box component={'div'} display={'flex'} mb={'1vw'}>
                        {((this.state.ipServiceCategory?.service_category || this.state.ipServiceCategory) === "Surgery Charges") ?
                            <Autocomplete
                                clearIcon
                                sx={{ width: '16vw', mr: '0.5vw' }}
                                size='small'
                                options={this.state.surgeryNameList}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option.surgery_name}
                                value={this.state.surgeryName}
                                onChange={(e, newValue) => {
                                    this.setState({
                                        surgeryName: newValue
                                    })
                                }}
                                renderInput={(params) => <TextField {...params} label='Select Surgery Name' />}
                            />
                            : this.inputTextBox("con_service_type", states.con_service_type, "Service Type *", "16vw", "0.5vw", null, null, null, null)}
                        {/* {this.inputTextBox("con_service_type", states.con_service_type, "Service Type *", "16vw", "0.5vw", null, null, null, null)} */}
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.doctorData}
                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.doctor_name}
                            value={this.state.con_doctor_name}
                            onChange={(e, newValue) => this.setState({ con_doctor_name: newValue })}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                  {option?.doctor_name}
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params} sx={{ width: '16vw' }} label='Doctor Name *' />}
                        />
                    </Box>
                    {this.state.showCode ? 
                    <Box component={'div'}>
                        <TextField
                            size='small'
                            sx={{ width: '16vw' }}
                            value={this.state.ipServiceCodeCon}
                            onChange={(e) => {
                                let value = e.target.value
                                if (value?.length <= OP_IP_CODE_LENGTH || value === '') {
                                    this.setState({ ipServiceCodeCon: value })
                                }
                            }}
                            label='Service Code'
                        />
                    </Box> : null}
                    <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'1vw'}>
                        <Box component={'div'}>
                            <RadioGroup value={states.con_select_method}
                                sx={{ display: 'flex', flexDirection: 'row' }}
                                onClick={(e) => {
                                    this.setState({
                                        con_select_method: e.target.value,
                                        con_ward_total_amt: '',
                                        con_doctor_fee: '',
                                        con_doctor_percent: '',
                                        con_hospital_fee: '',
                                        con_hospital_percent: '',
                                        con_advance_ward_list: JSON.parse(this.state.dup_con_advance_ward_list)
                                    })
                                }}
                            >
                                <FormControlLabel value={'all'} control={<Radio size='small' />} label='All' />
                                <FormControlLabel value={'advance'} control={<Radio size='small' />} label='Advance Fee Structure' />
                            </RadioGroup>
                            <FormHelperText>Please Select All or Advance Fee for ward Configuration</FormHelperText>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Type:</Typography>
                            <FormControl size='small' sx={{ width: '5vw' }}>
                                <Select
                                    sx={{ height: '2vw' }}
                                    value={this.state.con_selected_symbol}
                                    onChange={(e) => this.setState({ con_selected_symbol: e.target.value })}
                                >
                                    {SymbolList.map((item) => (
                                        <MenuItem value={item?.value}>{item?.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                    </Box>
                    <Box component={'div'}>
                        <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={'0.5vw'}>
                            <Box><Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Fee</Typography></Box>
                            <Box>{this.inputTextBox("con_ward_total_amt", states.con_ward_total_amt, "Total *", "8vw", '0.5vw', null, disable, true, null)}</Box>
                        </Box>
                        <Box component={'div'} mt={'0.5vw'} mb={'1vw'} borderBottom={'1px solid lightgray'}>
                            {this.inputTextBox("con_selected_ward", states.con_selected_ward, "Select Ward", "11.5vw", '0.5vw', null, true)}
                            {this.inputTextBox("con_doctor_fee", keyOne, "Doctor Fee", '10vw', '0.5vw', null, fees, null, true)}
                            {this.inputTextBox('con_hospital_fee', keyTwo, 'Hospital Fee', '10vw', null, null, fees, null, true)}
                            <Box component={'div'}>
                                <Button
                                    disabled={states.con_select_method === 'all'}
                                    className='eMed_New_Ip_Btn'
                                    startIcon={<AddCircle />}
                                    onClick={() => this.setState({ con_click_advance: true }, () => this.addAdvanceFeePopup())}
                                >
                                    Add Advance Fee Structure
                                </Button>
                            </Box>
                        </Box>
                        {this.renderGstTextBox()}
                        <Box component={'div'} borderBottom={'1px solid lightgray'}>
                            <Box mb={'1vw'} display={'flex'} justifyContent={'space-between'}>
                                <Box>
                                    <FormControlLabel control={<Checkbox size='small' checked={states.con_is_edit_unit} onChange={(e) => this.onCheckboxHandler(e, 'con_is_edit_unit')} />} label="Edit Unit" />
                                    <FormControlLabel control={<Checkbox size='small' checked={states.con_is_edit_amount} onChange={(e) => this.onCheckboxHandler(e, 'con_is_edit_amount')} />} label="Edit Amount" />
                                    <FormControlLabel control={<Checkbox size='small' checked={states.con_is_edit_discount} onChange={(e) => this.onCheckboxHandler(e, 'con_is_edit_discount')} />} label="Edit Discount" />
                                </Box>
                                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'1vw'}>
                                    <Button
                                        sx={{ width: '5vw', height: '2vw' }}
                                        onClick={() => {
                                            this.setState({ is_edit_line_item: false })
                                            this.addConsulationServiceData()
                                        }}
                                        variant='contained'>Add</Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'}>
                            <Box border={'1px solid lightgray'}>
                                <Table size='small' stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                                Doctor Name
                                            </TableCell>
                                            <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {con_data_list?.length > 0 ? con_data_list?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item?.service_name ? item?.service_name?.length > 15 ?
                                                    <Tooltip placement='top' title={item?.service_name} arrow><div>{item?.service_name.slice(0, 15) + '...'}</div></Tooltip> : <div>{item?.service_name}</div> : '-'}</TableCell>
                                                <TableCell>
                                                    <Box component={'div'} display={'flex'}>
                                                        <Button
                                                            className='eMed_New_Ip_Btn'
                                                            sx={{ width: '1.8vw', height: '1.8vw' }}
                                                            onClick={() => this.consultationServiceEdit(item, index)}
                                                        >
                                                            <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.5vw'} width={'1.5vw'} alt='edit' />
                                                        </Button>
                                                        <Button
                                                            sx={{ width: '1.8vw', height: '1.8vw' }}
                                                            className='eMed_New_Ip_Btn'
                                                            onClick={() => {
                                                                if (!this.state.is_edit_line_item) {
                                                                    this.state.con_data_list?.splice(index, 1)
                                                                    this.setState({ con_data_list: this.state.con_data_list })
                                                                } else {
                                                                    this.errorMessage("Service line item has in edit")
                                                                }
                                                            }}
                                                        >
                                                            <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} alt='edit' />
                                                        </Button>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell align='right'>No Records</TableCell>
                                            <TableCell />
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    clearOtherServiceData = () => {
        try {
            this.setState({
                other_service_name: '',
                other_selected_service_amt: '',
                other_selected_ward: 'All',
                other_selected_time: '',
                other_is_edit_amount: false,
                other_is_edit_discount: false,
                other_is_edit_unit: false,
                con_advance_ward_list: JSON.parse(this.state.dup_con_advance_ward_list),
                other_selected_method: 'all',
                other_selected_unit: 'nos',
                other_service_id: null,
                other_service_edit_index: null,
                dup_other_ward_list: '[]',
                minimum_amt: '',
                ipServiceCodeOth:'',
                gstPercentage : ""
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    otherServiceEdit = (item, index) => {
        try {
            if (item?.fee_type === 'all') {
                this.setState({
                    other_selected_service_amt: item?.consultant_charge[0]?.service_amount,
                    other_final_advance_fee_list: [],
                    minimum_amt: item?.consultant_charge[0]?.minimum_amount,
                    selectedMinTime:item?.consultant_charge[0]?.minimum_time ? item?.consultant_charge[0]?.minimum_time == '01:00:00' ? '60' : item?.consultant_charge[0]?.minimum_time?.split(':')[1] : item?.consultant_charge[0]?.minimum_time
                })
            } else {
                let states = this.state
                let wardlistHash = {};
                let newWardList = [];

                item?.consultant_charge?.forEach(element => {
                    wardlistHash[element.id] = element
                })
                states.con_advance_ward_list?.forEach(element => {
                    if (wardlistHash[element.id]) {
                        newWardList.push(wardlistHash[element.id])
                    } else {
                        newWardList.push(element)
                    }
                })
                this.setState({
                    con_advance_ward_list: newWardList,
                    other_selected_method: "All",
                    other_selected_ward: 'All',
                    other_selected_service_amt: '',
                    dup_other_ward_list: JSON.stringify(newWardList),
                    minimum_amt: '',
                    // selectedMinTime:null
                })
            }
            this.setState({
                other_service_edit_index: index,
                other_service_name: item?.service_name,
                other_service_type: item?.service_type,
                other_selected_unit: item?.unit === "1" ? 'nos' : item?.unit,
                other_selected_time: item?.time_taken,
                other_selected_method: item?.fee_type,
                other_is_edit_amount: item?.is_edit_amount,
                other_is_edit_discount: item?.is_edit_discount,
                other_is_edit_unit: item?.is_edit_unit,
                other_service_id: item?.id,
                is_edit_other_line_item: true,
                gstPercentage : this.state.is_gst_enabled ? item?.gst_percentage : 0,
                ipServiceCodeOth:item?.ip_service_code ? item?.ip_service_code : ''
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkOtherDuplicate = () => {
        try {
            let message = null;
            this.state.other_data_list.forEach((element, index) => {
                if (index !== this.state.other_service_edit_index) {
                    if (element.service_name === this.state.other_service_name) {
                        message = 'Service name already exists'
                    }else if(this.state.ipServiceCodeOth !== '' && element.ip_service_code?.toLowerCase() === CommonValidation.removeSpace(this.state.ipServiceCodeOth)?.toLowerCase()){
                        message = 'Service Code Already Exists'
                    }
                }
            })
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkOtherValidation = () => {
        try {
            let message = null;
            let states = this.state
            if (states.other_service_type === '' && states.other_service_name === '') {
                message = "Enter all mandatory fields"
            } else if (states.other_service_name === '') {
                message = "Enter service Name"
            } else if (states.other_service_type !== '' && states.other_service_name !== '') {
                if ((states.other_selected_unit === 'hrs' && states.other_selected_time !== '') || (states.other_selected_unit === 'nos')) {
                    if (states.other_selected_method === 'all') {
                        if (states.other_selected_service_amt === '') {
                            message = "Enter Service amount"
                        }
                        if (states.other_selected_unit === 'hrs' && states.minimum_amt === "" && states.selectedMinTime === null) {
                            message = "Enter Interval amount and Interval Time"
                        }
                        if (states.other_selected_service_amt === '' && states.other_selected_unit === 'hrs') {
                            message = "Enter Price Per Unit amount"
                        }
                    } else if (states.other_final_advance_fee_list.length === 0) {
                        message = "Enter atleast one advance fee structure"
                    }
                } else {
                    message = "Select Time"
                }
            } else {
                message = "Enter all mandatory fields"
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    addOtherServiceData = () => {
        try {
            let states = this.state
            let duplicate = this.checkOtherDuplicate()
            let otherValidate = this.checkOtherValidation()
            if (duplicate === null && otherValidate === null) {
                let allServiceList = [{
                    'ward_name': states.other_selected_ward,
                    'service_amount': states.other_selected_service_amt,
                    'minimum_amount': states.minimum_amt,
                    'minimum_time': states.selectedMinTime ? this.state.selectedMinTime == 60 ? "01:00:00" : `00:${states.selectedMinTime}` : '00:00:00'
                }]
                let categoryId = states.ipServiceCategoryData?.find((item) => item?.service_category === states.ipServiceCategory)
                let service_list = {
                    'ip_service_code':states.ipServiceCodeOth ? CommonValidation.removeSpace(states.ipServiceCodeOth) : '',
                    'service_category_id': categoryId?.id ? categoryId?.id : states.ipServiceCategory?.id,
                    'service_type': CommonValidation.removeSpace(states.other_service_type),
                    'service_name': CommonValidation.removeSpace(states.other_service_name),
                    'doctor_id': null,
                    'amount_type': null,
                    "time_taken": states.other_selected_time ? states.other_selected_time : null,
                    "unit": states.other_selected_unit === 'nos' ? "1" : states.other_selected_unit,
                    'consultant_charge': states.other_selected_method === 'all' ? allServiceList : states.other_final_advance_fee_list,
                    'is_edit_amount': states.other_is_edit_amount,
                    'is_edit_unit': states.other_is_edit_unit,
                    'is_edit_discount': states.other_is_edit_discount,
                    'fee_type': states.other_selected_method,
                    'id': states.other_service_id ? states.other_service_id : null,
                    'gst_percentage': (states?.gstPercentage && states?.is_gst_enabled) ? +states?.gstPercentage : 0,
                    'delete_id': []
                }
                let data = this.updateOrInsertList(states.other_data_list, service_list, this.state.other_service_edit_index)
                this.setState({ other_data_list: data })
                this.clearOtherServiceData()
            } else {
                if (duplicate !== null && otherValidate !== null) {
                    this.errorMessage("Enter all mandatory Fields")
                } else if (duplicate !== null) {
                    this.errorMessage(duplicate)
                } else if (otherValidate !== null) {
                    this.errorMessage(otherValidate)
                } else {
                    this.errorMessage("Enter all Mandatory fields")
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderGstTextBox = () => {
        const enableGstDiv = this.state.is_gst_enabled ? "block" : "none";
        return (
            <Box component={'div'} padding={'0.6dvw 0.5dvw'} borderBottom={'1px solid lightgray'} display={enableGstDiv}>
                <Typography marginBottom={'0.5dvw'} fontSize={'0.9dvw'} fontWeight={600} color={Colors.SecondaryText}>GST</Typography>
                <Autocomplete
                    disableClearable
                    size='small'
                    sx={{ width: '16dvw' }}
                    options={gstPercentage?.length > 0 ? gstPercentage : []}
                    getOptionLabel={(options) => typeof (options) === "string" ? options : options.label}
                    value={this.state.gstPercentage ? this.state.gstPercentage?.toString() : ""}
                    onChange={(e, newPercent) => {
                        if (typeof newPercent === 'object') {
                            this.setState({
                                gstPercentage: newPercent.value ? +newPercent.value : ''
                            });
                        } else {
                            this.setState({
                                gstPercentage: newPercent ? +newPercent : ''
                            });
                        }
                    }}
                    onInputChange={(event, newValue) => {
                        // const regex = /^\d{0,2}(\.\d{0,2})?$/;
                        // const isValid = regex.test(newValue);
                        // const numericValue = parseFloat(newValue);

                        // // Ensure the value is within 0 to 100 and valid
                        // if ((isValid && numericValue <= 100) || newValue === '') {
                        //     this.setState({ gstPercentage: newValue });
                        // }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} label={'GST (%)'}
                            autoComplete="off" sx={{ backgroundColor: 'white' }} type='search' />}
                    inputProps={{ 'emed_tid': 'Opsrv_config_gst' }}
                />
            </Box>
        )
    }

    renderOtherScreen = () => {
        try {
            let states = this.state
            let disable = this.state.other_selected_method === 'all' ? false : true
            return (
                <Box component={'div'} padding={'0.5vw'}>
                    <Box mb={'1vw'}>{this.inputTextBox("other_service_type", states.other_service_type, "Service Type *", "32.5vw", '0.5vw')}</Box>
                    <Box component={'div'}>
                        {this.state.showCode ? 
                        <Box component={'div'} display={'flex'} mb={'1vw'}>
                            {this.inputTextBox("other_service_name", states.other_service_name, "Service Name *", "16vw", '0.5vw')}
                            <TextField
                                size='small'
                                autoComplete='off'
                                sx={{ width: '16vw' }}
                                value={this.state.ipServiceCodeOth}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (value?.length <= OP_IP_CODE_LENGTH || value === '') {
                                        this.setState({ ipServiceCodeOth: value })
                                    }
                                }}
                                label='Service Code'
                            />
                        </Box> : null}
                        <Box component={'div'} display={this.state.showCode ? '' : 'flex'} mb={this.state.showCode ? '' : '1vw'}>
                            {this.state.showCode ? null : this.inputTextBox("other_service_name", states.other_service_name, "Service Name *", "16vw", '0.5vw')}
                            {this.state.other_selected_unit === 'nos' ?
                                <TextField
                                    value={this.state.selectedUnitCount}
                                    disabled
                                    size='small'
                                    sx={{ width: '7.7vw', height: '2.6vw', marginRight: '0.5vw' }}
                                /> :
                                <TimePicker
                                    style={{ width: '7.7vw', height: '2.6vw', marginRight: '0.5vw' }}
                                    disabled={states.other_selected_unit === 'nos'}
                                    format={'HH:mm'}
                                    value={states.other_selected_time ? moment(states.other_selected_time, "HH:mm") : ''}
                                    onChange={(time, timeString) => { this.setState({ other_selected_time: timeString }) }}
                                />}
                            <FormControl size='small' sx={{ width: '7.7vw' }}>
                                <InputLabel>Unit</InputLabel>
                                <Select
                                    label='unit *'
                                    value={states.other_selected_unit}
                                    onChange={(e) => this.setState({ other_selected_unit: e.target.value })}
                                >
                                    {unitList.map((item, index) => (
                                        <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box component={'div'}>
                        <RadioGroup value={states.other_selected_method}
                            sx={{ display: 'flex', flexDirection: 'row',width:'18vw' }}
                            onClick={(e) => this.setState({ other_selected_method: e.target.value },() => this.setState({minimum_amt:'',selectedMinTime:null}))}
                        >
                            <FormControlLabel value={'all'} control={<Radio size='small' />} label='All' />
                            <FormControlLabel value={'advance'} control={<Radio size='small' />} label='Advance Fee Structure' />
                        </RadioGroup>
                        <FormHelperText>Please Select All or Advance Fee for ward Configuration</FormHelperText>
                    </Box>
                    <Box>
                        <Box component={'div'} mt={'1vw'} mb={'1vw'} borderBottom={'1px solid lightgray'}>
                            {this.inputTextBox("other_selected_ward", states.other_selected_ward, "Ward Name", "16vw", '0.5vw', null, true)}
                            {this.state.other_selected_unit === 'nos' ?
                            this.inputTextBox('other_selected_service_amt', states.other_selected_service_amt, 'Service Amount *', '16vw', null, null, disable, null, true)
                            :
                            this.inputTextBox('other_selected_service_amt', states.other_selected_service_amt, 'Price Per Unit *', '16vw', null, null, disable, null, true)}
                            <Box component={'div'}>
                                <Button
                                    disabled={this.state.other_selected_method === 'all'}
                                    className='eMed_New_Ip_Btn'
                                    startIcon={<AddCircle />}
                                    onClick={() => { this.setState({ con_click_advance: true }, () => this.addAdvanceFeePopup()) }}>
                                    Add Advance Fee Structure
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    {this.state.other_selected_unit === 'nos' ? null :
                        <div style={{ marginTop: "0.5vw" }}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>Interval Time and Amount</Typography>
                            {/* <TimePicker
                                placeholder='Interval Time *'
                                style={{ width: '15vw', height: '2.6vw', marginRight: '0.5vw' }}
                                disabled={disable}
                                format={'mm'}
                                value={states.selectedMinTime ? moment(states.selectedMinTime, "mm") : ''}
                                onChange={(time, timeString) => { this.setState({ selectedMinTime: timeString }) }}
                            /> */}
                            <div style={{display:'flex',marginTop:'0.5vw'}}>
                              <Autocomplete
                                    disabled={disable}
                                    disableClearable
                                    size='medium'
                                    value={this.state.selectedMinTime}
                                    options={this.TimeListArray?.map((item) => item)}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            selectedMinTime: newValue,
                                        })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ width: '16vw', marginRight: '0.5vw' }}
                                            size='small'
                                            label={'Interval Time *'}
                                        />
                                    )} />
                            {this.inputTextBox('minimum_amt', states.minimum_amt, 'Interval Amount *', '16vw', null, null, disable, null, true)}
                            </div>

                        </div>
                    }
                     {this.renderGstTextBox()}
                    <Box component={'div'} borderBottom={'1px solid lightgray'}>
                        <Box mb={'1vw'} display={'flex'} justifyContent={'space-between'}>
                            <Box>
                                <FormControlLabel control={<Checkbox size='small' checked={states.other_is_edit_unit} onChange={(e) => this.onCheckboxHandler(e, 'other_is_edit_unit')} />} label="Edit Unit" />
                                <FormControlLabel control={<Checkbox size='small' checked={states.other_is_edit_amount} onChange={(e) => this.onCheckboxHandler(e, 'other_is_edit_amount')} />} label="Edit Amount" />
                                <FormControlLabel control={<Checkbox size='small' checked={states.other_is_edit_discount} onChange={(e) => this.onCheckboxHandler(e, 'other_is_edit_discount')} />} label="Edit Discount" />
                            </Box>
                            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'1vw'}>
                                <Button
                                    sx={{ width: '5vw', height: '2vw' }}
                                    variant='contained'
                                    onClick={() => {
                                        this.setState({ is_edit_other_line_item: false })
                                        this.addOtherServiceData()
                                    }}
                                >Add</Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box component={'div'}>
                        <Box border={'1px solid lightgray'}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                            Service Name
                                        </TableCell>
                                        <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                            Unit
                                        </TableCell>
                                        <TableCell sx={{ color: Colors.SecondaryText, backgroundColor: Colors.Background, fontWeight: '600', fontSize: '0.9vw' }}>
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {states.other_data_list?.length > 0 ? states.other_data_list?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item?.service_name ?
                                                item?.service_name?.length > 10 ?
                                                    <Tooltip placement='top' title={item?.service_name} arrow><div>{item?.service_name.slice(0, 8) + '...'}</div></Tooltip> :
                                                    <div>{item?.service_name}</div> : '-'
                                            }</TableCell>
                                            <TableCell>{item?.unit ? item?.unit === 'hrs' ? `${item?.time_taken?.slice(0, 5)} Hrs` : item?.unit : '-'}</TableCell>
                                            <TableCell>
                                                <Box component={'div'} display={'flex'}>
                                                    <Button
                                                        className='eMed_New_Ip_Btn'
                                                        sx={{ width: '1.8vw', height: '1.8vw' }}
                                                        onClick={() => this.otherServiceEdit(item, index)}
                                                    >
                                                        <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.5vw'} width={'1.5vw'} alt='edit' />
                                                    </Button>
                                                    <Button
                                                        sx={{ width: '1.8vw', height: '1.8vw' }}
                                                        className='eMed_New_Ip_Btn'
                                                        onClick={() => {
                                                            if (!this.state.is_edit_other_line_item) {
                                                                this.state.other_data_list?.splice(index, 1)
                                                                this.setState({ other_data_list: this.state.other_data_list })
                                                            } else {
                                                                this.errorMessage("Service line item has in edit")
                                                            }
                                                        }}
                                                    >
                                                        <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} alt='edit' />
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )) : <TableRow>
                                        <TableCell />
                                        <TableCell align='center'>No Records</TableCell>
                                        <TableCell />
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRightContent = () => {
        return (
            <Box height={'78vh'} border={'1px solid lightgray'} sx={{ backgroundColor: 'white' }}>
                <Box component={'div'} display={'flex'} alignItems={'center'} pl={'0.5vw'} pr={'0.5vw'} justifyContent={'space-between'} height={'3vw'} sx={{ backgroundColor: Colors.Background }}>
                    <Box>
                        <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>
                            IP Service Name & Type Configuration
                        </Typography>
                    </Box>
                    <Box>
                        <Autocomplete
                            clearIcon
                            size='small'
                            clearOnEscape
                            options={this.state.ipServiceCategoryData}
                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options?.service_category}
                            value={this.state.ipServiceCategory}
                            onChange={(e, newvalue) => {
                                this.setState({
                                    ipServiceCategory: newvalue,
                                    other_service_type: '',
                                    con_service_type: '',
                                    other_data_list: [],
                                    con_data_list: [],
                                    ipServiceCodeCon:'',ipServiceCodeOth:''
                                },() => {
                                    if(targetCategories.has(newvalue?.service_category)){
                                        this.getDoctorDataList()
                                    }
                                })
                                this.clearConsultationCategory()
                                this.clearOtherServiceData()
                            }}
                            renderInput={(params) => <TextField {...params} size='small'
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        this.setState((prevState) => (
                                            {
                                                ipServiceCategory: prevState?.ipServiceCategoryMap?.get('Doctor Consultation Charges')
                                            }))
                                    }
                                }} sx={{ width: '15vw', backgroundColor: 'white' }} />}
                        />
                    </Box>
                </Box>
                <Box component={'div'} overflow={'scroll'} height={'65.2vh'}>
                    {(targetCategories.has(this.state.ipServiceCategory?.service_category || this.state.ipServiceCategory)) ?
                        this.renderConsultationScreen() : this.renderOtherScreen()}
                </Box>
                <Box component={'div'} sx={{ backgroundColor: Colors.Background }} display={'flex'} justifyContent={'center'} height={'3vw'} alignItems={'center'}>
                    <Button
                        sx={{ width: '5vw', height: '2vw', marginRight: '1vw', textTransform: 'capitalize' }}
                        variant='outlined'
                        onClick={() => {
                            this.setState((prevState)=>({
                                isEdit: false,
                                con_data_list: [],
                                other_data_list: [],
                                con_service_type: '',
                                other_service_type: '',
                                duplicate_data_list: '[]',
                                ipServiceCategory: prevState?.ipServiceCategoryMap?.get('Doctor Consultation Charges'),
                            }), () => {
                                this.clearConsultationCategory()
                                this.clearOtherServiceData()
                            })
                        }}
                    >Clear</Button>
                    <Button
                        disabled={(this.state.con_data_list?.length > 0 || this.state.other_data_list?.length > 0) && CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Service Name & Type Config", "SubTab")?.editAccess ? false : true}
                        sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                        variant='contained'
                        onClick={() => this.postIpServiceConfigData()}
                    >{this.state.isEdit ? "Update" : "Save"}</Button>
                </Box>
            </Box>
        )
    }

    onDeleteHandler = (value) => {
        if (!this.state.isEdit) {
            this.setState({
                deletePopup: true,
                deletedText: value?.service_type
            })
        } else {
            this.errorMessage("Edit has been clicked.So,Please clear or Update service")
        }
    }

    popupClose = () => {
        this.setState({ deletePopup: false })
    }

    render() { 
        return (
            <Box component={'div'} height={'80dvh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} display={'flex'} height={'80dvh'}>
                    <Box component={'div'} flex={'0.62'} padding={'0.5vw'}>
                        {this.renderLeftContent()}
                    </Box>
                    <Box component={'div'} flex={'0.38'} padding={'0.5vw'}>
                        {this.renderRightContent()}
                    </Box>
                    {this.addAdvanceFeePopup()}
                    {this.viewAdvancePopup()}
                </Box>
                {this.state.isErrorMsg &&
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />}
                {this.state.successMsg &&
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />}
                {this.state.deletePopup && <DeletePopup
                    DeletTitle={`Are you sure you want to ${this.state.deletedText} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteIpServiceConfigData.bind(this)}
                    DeleteNotify={"It will affect entire configuration"}
                />}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}