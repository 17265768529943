import {
    Box, Button, Collapse, IconButton, Menu, MenuItem, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography
} from '@mui/material'
import React, { Component } from 'react'
import './IpPatientBillDetails.css';
import { Colors } from '../../../../../Styles/Colors';
import { BillSuccessPoPUp, CommonPatientCard, CommonPatientDetails, CommonPopUp } from '../../../../../Components/Common Components/CommonComponents';
import { getCachevalue, localGetItem, setCachevalue } from '../../../../../Utility/Services/CacheProviderService';
import { withTranslation } from 'react-i18next';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import { ImagePaths } from '../../../../../Utility/ImagePaths';
import { AmountFormat, CheckAccessFunc, ContentInTooltip, FindCreditType } from '../../../../../Components/CommonFunctions/CommonFunctions';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreditTypeChangePop from '../../../BillingTransaction/CreditTypeChangePop';
import Loader from '../../../../../Components/Loader';
import EditHistoryPopup from '../../../BillingTransaction/EditHistoryPopup';
import CreditBillCompletePoP from '../../../BillingTransaction/CreditBillCompletePoP';
import { CurrencySymbol } from '../../../../../Utility/Constants';
import CommonValidation from '../../../../../Components/CommonFunctions/CommonValidation';

class IpPatientBills extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ipPatientData: {},
            ipPatientBills: [],
            service_amount: "",
            attender_mobile: "",
            attender_name: "",
            paid_amount: "",
            balance_amount: "",
            advance_amount: "",
            ApproxAmount: 0,
            selectedIndex: null,
            ipSavedBillId: '',
            creditBillPopUp: false,
            IpCreditBill: {},
            isBillView: false,
            serviceList: [],
            selectedBillDetails: {},
            roundOff: 0,
            dataForTypeChage: {},
            receiptID: null,
            pharmacReceipt: null,
            BillModifyHistoryItem: {},
            ApprovedAmt: 0,
            withOutAppointment: {},
            is_gst_enabled: JSON.parse(localGetItem("is_gst_enabled")),
        }
    }
    componentDidMount() {
        let ipPatientData = getCachevalue("IppatientData")
        let patientData = JSON.parse(ipPatientData)
        let appointmentDetail =  JSON.parse(getCachevalue("withoutAppointment"));
        this.setState({
            ipPatientData: patientData,
            withOutAppointment: appointmentDetail?.patient_id === patientData?.patient_id ? appointmentDetail : {}
        }, () => {
            this.getPatientData()
            this.getApproxCharges()
            this.getIpPatientBillsDetails()
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getPatientData = () => {
        try {
            const admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
            RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + admission_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            service_amount: response.data.data[0].service_amount,
                            balance_amount: response.data.data[0].balance_amount,
                            paid_amount: response.data.data[0].paid_amount,
                            advance_amount: response.data.data[0].advance_amount,
                            attender_mobile: response.data.data[0].attender_mobile,
                            attender_name: response.data.data[0].attender_name,
                            PatientDetails: response.data.data[0],
                            ApprovedAmt: response.data.data[0].claimed_amount,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getApproxCharges = () => {
        try {
            const admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
            RestAPIService.getAll(Serviceurls.IP_APPROX_AMOUNT + "?ip_admission_id=" + admission_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ApproxAmount: response.data.data?.approx_room_charge
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    getIpPatientBillsDetails = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.IP_PATIENT_INDIVIDUAL_BILLS + "?patient_id=" + this.state.ipPatientData?.patient_id)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ipPatientBills: response.data.data
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    if (error.response.data.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getIpPatientBillPrint = (Module, ModuleId = null) => {
        try {
            let APIURL = ""
            if (Module === "IP") {
                APIURL = Serviceurls.IP_INVOICE_PRINT + "?ip_bill_summary_id=" + this.state.ipBillId
            } else if (Module === "PHARMACY") {
                APIURL = Serviceurls.PHARMACY_INVOICE_PRINT + "?id=" + this.state.ipBillId + "&export=pdf" + "&pharmacy_id=" + ModuleId
            } else if (Module === "LAB") {
                APIURL = Serviceurls.LAB_CREATE_BILL_COMPLETED_PRINT + "?bill_summary_id=" + this.state.ipBillId + "&export_type=pdf" + "&laboratory_id=" + ModuleId;
            } else if (Module === "OT") {
                APIURL = Serviceurls.GET_PRINT_PREVIEW + `?export=pdf&id=` + this.state.ipBillId
            } else if (Module === "RADIOLOGY") {
                APIURL = Serviceurls.RAD_BILL_POST + '?bill_summary_id=' + this.state.ipBillId + "&export_type=pdf"
            }
            RestAPIService.getAllPrint(APIURL).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.errorMessage(error.response?.data?.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    creditBillPost(message = "", receiptNo = null, phrReceipt = null, error = false) {
        this.setState({
            creditBillPopUp: false,
            receiptID: receiptNo,
            pharmacReceipt: phrReceipt
        }, () => {
            if (error) {
                this.errorMessage(message)
            } else {
                this.successMessage(message)
            }
            this.getIpPatientBillsDetails()
            this.getPatientData()
        })
    }

    CrebillTypeChaged(message) {
        this.setState({
            creditBillPopUp: false,
            CreditTypeChangePopup: false,
            dataForTypeChage: {}
        }, () => {
            this.successMessage(message)
            this.getIpPatientBillsDetails()
            this.getPatientData()
        })
    }

    ClosePopUp() {
        this.setState({
            creditBillPopUp: false,
            CreditTypeChangePopup: false,
            dataForTypeChage: {},
            receiptID: null,
            pharmacReceipt: null,
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    MenuItem = (data, index) => {
        const { t } = this.props
        const multi_option = [
            { value: "ChangeCreditType", label: t("Change Credit Type") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        disabled={data?.balance_amount >= 0}
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: index,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == index) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option} onClick={() => {
                                this.setState({ anchorEl: null }, () => {
                                    if (option.value === "ChangeCreditType") {
                                        let corporate_detail = Object.keys(this.state.ipPatientData?.primary_payment)?.length > 0 ? this.state.ipPatientData?.primary_payment?.corporate_detail : {};
                                        let dataobj = {
                                            "patient_name": this.state.ipPatientData?.patient_name,
                                            "uhid": this.state.ipPatientData?.patient_account_number,
                                            "mobile_number": this.state.ipPatientData?.patient__mobile_number,
                                            "patient_age": this.state.ipPatientData?.patient_age,
                                            "patient_id": this.state.ipPatientData?.patient_id,
                                            "module_id": data?.ip_admission_id,
                                            "total_amount": data?.total_bill_amount ? data?.total_bill_amount : 0,
                                            "pending_amount": data?.balance_amount ? Math.abs(data?.balance_amount) : 0,
                                            "bill_types": "In Patient",
                                            "credit_type": FindCreditType(data?.bill_detail),
                                            "employee_id": Object.keys(corporate_detail)?.length > 0 ? corporate_detail?.id_no : "",
                                            "employer_id": Object.keys(corporate_detail)?.length > 0 ? corporate_detail?.employer_id : "",
                                            "is_final_bill_generated": data?.is_final_bill_generated,
                                        }
                                        this.setState({ CreditTypeChangePopup: true, dataForTypeChage: dataobj })
                                    }
                                })
                            }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    renderTable = () => {
        const { t } = this.props
        return (
            <TableContainer component={'div'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' />
                            <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Admission And Date")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{t("NoOfBill")}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{`${t("TotalBillAmount")} (${CurrencySymbol})`}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{`${t("BalanceAmount")} (${CurrencySymbol})`}</TableCell>
                            <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{getCachevalue("isForCancelBill") === "true" ? "" : `${t("Action")} (${CurrencySymbol})`}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.ipPatientBills?.length > 0 ? this.state.ipPatientBills.map((item, index) => (
                            <><TableRow
                                sx={{ backgroundColor: this.state.selectedIndex === index ? Colors?.ThemeLightColor : 'white', cursor: "pointer" }}
                                key={index}
                                onClick={() => {
                                    this.setState({
                                        selectedIndex: this.state.selectedIndex === index ? null : index
                                    })
                                }}>
                                <TableCell className='eMed_Pat_Tbl_one_style'>
                                    <IconButton
                                        size='small'
                                        onClick={() => {
                                            this.setState({
                                                selectedIndex: this.state.selectedIndex === index ? null : index
                                            })
                                        }}
                                    >
                                        {this.state.selectedIndex === index ?
                                            <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style'>{`${item?.ip_number ? item?.ip_number : "-"}
                                     | ${item?.admission_date ? item?.admission_date : "-"}`}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{item?.no_of_bills ? item?.no_of_bills : "-"}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.total_bill_amount ? AmountFormat(item?.total_bill_amount)?.replace(`${CurrencySymbol}`, "") : "0"}</TableCell>
                                <TableCell sx={{ color: item?.balance_amount < 0 ? 'red' : 'green' }} className='eMed_Pat_Tbl_one_style' align='center'>{item?.balance_amount ? AmountFormat(item?.balance_amount)?.replace(`${CurrencySymbol}`, "") : "0"}</TableCell>
                                <TableCell className='eMed_Pat_Tbl_one_style' align='center' sx={{ display: 'flex', justifyContent: "center", alignItems: "center", visibility: getCachevalue("isForCancelBill") === "true" ? "hidden" : "visible" }}>
                                    <Button
                                        disabled={CheckAccessFunc("front_office", "In Patients", "Service Billing", "Bills", "SubTab")?.editAccess && item?.balance_amount != 0 ? (this.state.creditBillPopUp ? true : false) : true}
                                        className='eMed_Pat_tbl_two_btn'
                                        onClick={() => {
                                            item.credit_type = FindCreditType(item?.bill_detail)
                                            this.setState({ creditBillPopUp: true, IpCreditBill: item })
                                        }}
                                    >
                                        <Tooltip placement='top' title={'Receive Payment'} arrow>
                                            <img src={CheckAccessFunc("front_office", "In Patients", "Service Billing", "Bills", "SubTab")?.editAccess && item?.balance_amount != 0 ? ImagePaths.Payment.default : ImagePaths.DisPayment.default} className='eMed_Pat_Bill_img' alt='credit' />
                                        </Tooltip>
                                    </Button>
                                    {this.MenuItem(item, index)}
                                </TableCell>
                            </TableRow>
                                <TableRow>
                                    {this.renderTableOne(item, index)}
                                </TableRow></>
                        )) : <TableRow>
                            <TableCell /><TableCell />
                            <TableCell />
                            <TableCell align='center'>{t("NoRecordsFound")}</TableCell>
                            <TableCell /><TableCell />
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    getCapitalize = (data) => {
        const capitalized = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalized;
    }

    MenuItemTableOne = (data, index, ModuleName) => {
        const { t } = this.props
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let ModifyBillAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.modify_credit_bill ? RoleData?.permission_access?.common?.modify_credit_bill : false) : true
        let cancelBillApproval = RoleData?.is_user ? (RoleData?.permission_access?.common?.cancel_bill_approval ? RoleData?.permission_access?.common?.cancel_bill_approval : false) : true
        const multi_option = [
            { value: "cancelBill", label: t("Cancel Bill") },
            { value: "ModifyBill", label: t("Modify Bill") },// will be sliced when no access
            { value: "ModifyHistory", label: t("Modify History") }
        ]
        if (!ModifyBillAccess) {
            multi_option.splice(1, 1);
        }
        if (!cancelBillApproval || (data?.module === "LAB" && data?.result_status !== "Result Inprogress")) {
            multi_option.splice(0, 1);
        }
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        disabled={ModuleName === "PHARMACY" || data?.bill_type === "cancelled"}
                        onClick={(e) => {
                            this.setState({
                                anchorElTblOne: e.currentTarget,
                                selectedRowTblOne: index,
                                isSelectedTblOne: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelectedTblOne && this.state.selectedRowTblOne == index) ?
                    <Menu
                        anchorEl={this.state.anchorElTblOne}
                        open={Boolean(this.state.anchorElTblOne)}
                        onClose={() => {
                            this.setState({ anchorElTblOne: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option}
                                disabled={option?.value === "ModifyBill" ? ((data?.bill_type === "credit" && data?.payment_status === "not paid" && (data?.module !== "LAB" || data?.result_status === "Result Inprogress") && !data?.assorted_pack_id) ? false : true) : false}
                                onClick={() => {
                                    this.setState({ anchorElTblOne: null }, () => {
                                        if (option?.value === "cancelBill" || option?.value === "ModifyBill") {
                                            if (ModuleName === "IP") {
                                                setCachevalue(true, "isForCancelBill")
                                                setCachevalue(JSON.stringify({ AppointmentID: data?.ip_admission_id, InvoiceID: data?.id, ForCancel: true, AmountNet: data?.total_amount, FromBills: true, isForEditBill: option?.value === "ModifyBill" }), "IPCancelBilldata")
                                                this.props?.history.push({ pathname: "/IPHome/PatientDetails/ServiceBilling", state: { AppointmentID: data?.ip_admission_id, InvoiceID: data?.id, ForCancel: true, AmountNet: data?.total_amount, FromBills: true, isForEditBill: option?.value === "ModifyBill", Reset:true } })
                                            } else if (ModuleName === "LAB") {
                                                setCachevalue(true, "isForCancelBill")
                                                setCachevalue(JSON.stringify({ ForCancel: true, cancelBillData: data, FromBills: true, isForEditBill: option?.value === "ModifyBill" }), "LabCancelBilldata")
                                                this.props?.history.push({ pathname: "/Billing/LabBilling", state: { ForCancel: true, FromBills: true, cancelBillData: data, isForEditBill: option?.value === "ModifyBill" } })
                                            } else if (ModuleName === "RADIOLOGY") {
                                                setCachevalue(true, "isForCancelBill")
                                                setCachevalue(JSON.stringify({ fromModifyBill:  option?.value === "ModifyBill", billSummaryId: data?.id, ForCancel: true, AmountNet: data?.row?.amount_net, FromBills: true, isForEditBill: option?.value === "ModifyBill" }), "RadCancelBillData")
                                                this.props.history?.push({ pathname: '/Billing/RadiologyBilling', state: { fromModifyBill: true, billSummaryId: data?.id, ModifyFromFO: option?.value === "ModifyBill", CancelFromFO: option?.value === "cancelBill", patientData: data, FromIpPatientBillsScreen : true } })
                                            }
                                        } else if (option?.value === "ModifyHistory") {
                                            this.setState({ BillModifyHistoryItem: data })
                                        }
                                    })
                                }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    PaidIn(RowData) {
        let data = RowData?.paid_in ? RowData?.paid_in.split(",") : []
        let isFo = data.includes("FRONT OFFICE")
        let ispharma = data.includes("PHARMACY")
        let isLab = data.includes("LABORATORY")
        let isRadiology = data.includes("RADIOLOGY")
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography display={"inline"} textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"}>{isFo ? "Front Office" : ispharma ? "Pharmacy" : isLab ? "Lab" : isRadiology ? 'Radiology' : '-'}</Typography>
            </Box>
        )
    }

    renderTableOne = (item, index) => {
        const { t } = this.props
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse
                    in={this.state.selectedIndex === index}
                    timeout={'auto'}
                >
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table>
                            <TableHead sx={{ backgroundColor: Colors.Background }}>
                                <TableRow>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DateAndTime")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("BillNumber")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("BillType")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Paid In")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Module")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("DoctorName")}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{`${t("TotalBillAmount")} (${CurrencySymbol})`}</TableCell>
                                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.bill_detail?.length > 0 ? item?.bill_detail.map((subItem, subIndex) => {
                                    let billType = subItem?.bill_type ? this.getCapitalize(subItem.bill_type) : "-";
                                    return (
                                        <TableRow key={subIndex}>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.bill_date ? subItem.bill_date : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.bill_number ? subItem.bill_number : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"} >{billType ? (billType === "Credit") ? `Credit - ( ${subItem?.credit_type ? subItem?.credit_type : "-"} )` : billType : '-'}</Typography>
                                                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"} >{(subItem?.bill_type === "Bill" || subItem?.bill_type === "Regular") ? "Paid" :(subItem?.bill_type === "cancelled" && subItem?.payment_status === "not paid") ? "Cashless" : (subItem?.payment_status ? subItem?.payment_status : "-")}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center' sx={{ textTransform: 'capitalize' }}>{this.PaidIn(subItem)}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"} > {subItem?.module ? subItem.module : '-'}</Typography>
                                                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8rem"} >{
                                                        (subItem?.module === "PHARMACY" && localGetItem("multiPharmacy") === "true") ? ContentInTooltip(subItem?.pharmacy_name ? subItem?.pharmacy_name : "-", 10, "0.8rem") :
                                                            (subItem?.module === "LAB" && localGetItem("multiLaboratory") === "true") ? ContentInTooltip(subItem?.laboratory_name ? subItem?.laboratory_name : "-", 10, "0.8rem") : ""}</Typography>
                                                </Box></TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style'>{subItem?.doctor_name ? subItem.doctor_name : '-'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{subItem?.amount_net ? AmountFormat(subItem.amount_net)?.replace(`${CurrencySymbol}`, "") : '0'}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center' sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                                <Button
                                                    //    disabled={CheckAccessFunc("front_office", "Home", "Service Billing", "Credit Bill", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_op : true}
                                                    className='eMed_Pat_tbl_two_btn'
                                                    onClick={() => {
                                                        this.setState({ ipBillId: subItem?.id }, () => { this.getIpPatientBillPrint(subItem?.module, subItem?.laboratory_id ? subItem?.laboratory_id : (subItem?.pharmacy_id ? subItem?.pharmacy_id : null)) })
                                                    }}
                                                >
                                                    <Tooltip placement='top' title={'Print'} arrow>
                                                        <img src={ImagePaths.PrintIcons.default} className='eMed_Pat_Bill_img' alt='print' />
                                                    </Tooltip>
                                                </Button>
                                                <Button
                                                    className='eMed_Pat_tbl_two_btn'
                                                    onClick={() => {
                                                        this.setState({
                                                            isBillView: true,
                                                            serviceList: subItem.service_data,
                                                            selectedBillDetails: subItem
                                                        })
                                                    }}
                                                >
                                                    <Tooltip placement='top' title={'View Bill'} arrow>
                                                        <img src={ImagePaths.View.default} className='eMed_Pat_Bill_img' alt='viewBill' />
                                                    </Tooltip>
                                                </Button>
                                                {this.MenuItemTableOne(subItem, subIndex, subItem.module)}
                                            </TableCell>
                                        </TableRow>)
                                }) : <TableRow>
                                    <TableCell /><TableCell />
                                    <TableCell />
                                    <TableCell align='center'>{t("NoRecordsFound")}</TableCell>
                                    <TableCell /><TableCell />
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Box>

                </Collapse>
            </TableCell>
            </>
        )
    }
    viewBillPopupClose = () => {
        this.setState({
            isBillView: false
        })
    }

    billingCalculation = () => {
        let params = this.state.serviceList?.length > 0 ? this.state.serviceList : []
        var subAmt = 0
        let amount = 0;

        params.forEach(element => {
            amount = amount + (element.service_amount)
            subAmt = amount ? amount.toFixed(2) : 0
        })

        return AmountFormat(subAmt)
    }

    customFooter = () => {
        var { t } = this.props
        var { selectedBillDetails } = this.state
        return (
            <Box component={'div'} className='eMed_billview_table_footer'>
                <Box component={'div'} position={'relative'} left={"1.1vw"}>
                    <Box component={'div'} className='eMed_bill_view_tbl_footer'>
                        <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.85vw" }}>{t("SubAmount")}</Typography>
                        <Typography mt={0.5} fontSize={"0.85vw"} mr={'0.8vw'}>{this.billingCalculation()}</Typography>
                    </Box>
                    <Box component={'div'} className='eMed_bill_view_tbl_footer'>
                        <Typography mt={0.5} fontSize={"0.85vw"} sx={{ color: Colors.SecondaryText, fontSize: "0.8vw" }}>{t("concession") + " %"}</Typography>
                        <Typography mt={0.5} fontSize={"0.85vw"} mr={'0.8vw'}>{CommonValidation.formatAmount(selectedBillDetails.discount_percentage)}</Typography>
                        <Typography mt={0.5} fontSize={"0.85vw"} mr={'0.8vw'}>{AmountFormat(selectedBillDetails.discount_amount)}</Typography>
                    </Box>
                    <Box component={'div'} className='eMed_bill_view_tbl_footer' borderTop={"2px solid gray"}>
                        <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.85vw" }} fontWeight='600'>{t("TotalAmount")}</Typography>
                        <Typography mt={0.5} fontSize={"0.8vw"} mr={'0.85vw'}>{AmountFormat(selectedBillDetails.amount_net)}</Typography>
                    </Box>
                </Box>
            </Box>
        )
    }
    renderViewBillComponent = () => {
        var { t } = this.props
        return (
            <Box margin={1} className='eMed_bill_View_card'>
                <TableContainer className='eMed_bill_view_table'>
                    <Table stickyHeader>
                        <TableHead sx={{ backgroundColor: Colors.Background }}>
                            <TableRow>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Description")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{t("Unit")}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{`${t("RateUnit")} (${CurrencySymbol})`}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header'>{`${t("concession")} (${CurrencySymbol})`}</TableCell>
                                <TableCell className='eMed_Pat_Bill_Tbl_Header' align='right'>{`${t("TotalAmount")} (${CurrencySymbol})`}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.serviceList?.length > 0 && this.state.serviceList.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className='eMed_Pat_Tbl_one_style'>{item.service_type}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style'>{typeof (item?.service_units) === "number" ? item?.service_units : (item?.service_units?.split(" ")[1] == "hrs" ? item?.service_units?.split(" ")[0] : item?.service_units)}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style'>{(item?.gst_percentage && this.state.is_gst_enabled) ? `${AmountFormat(CommonValidation.CalculateGSTExclude(item.service_rate_per_unit, item?.gst_percentage) || 0)?.replace(`${CurrencySymbol}`, "")} + ${item?.gst_percentage}% GST` : AmountFormat(item.service_rate_per_unit)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style'>{
                                        <Tooltip title={item.individual_discount_comments ? `Discount Comments : ${item.individual_discount_comments}` : ""} placement='top' arrow>
                                            <div style={{ fontSize: "0.8rem", fontWeight: 600 }}>{AmountFormat(item.discount_amount || 0)?.replace(`${CurrencySymbol}`, "")}</div>
                                        </Tooltip>
                                    }</TableCell>
                                    <TableCell className='eMed_Pat_Tbl_one_style' align='right'>{AmountFormat(item.service_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.customFooter()}
            </Box>
        )
    }

    ClosesuccessPopUp = () => {
        this.setState({
            receiptID: null,
            pharmacReceipt: null
        })
    }
    HistoryPopClose() { this.setState({ BillModifyHistoryItem: {} }) }

    render() {
        const { t } = this.props
        let serviceAmt = {
            image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("BillAmount"),
            lineTwo: this.state.service_amount ? AmountFormat(this.state.service_amount) : `${CurrencySymbol} 0.00`
        }

        let PaidDetails = {
            image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("Paid Amount"),
            lineTwo: this.state.paid_amount ? AmountFormat(this.state.paid_amount) : `${CurrencySymbol} 0.00`
        }

        let PendingDetails = {
            image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t("BalAmt"),
            lineTwo: this.state.balance_amount ? AmountFormat(this.state.balance_amount) : `${CurrencySymbol} 0.00`,
            colorCode: +this.state.balance_amount > 0 ? 'green' : +this.state.balance_amount === 0 ? "balck" : 'red'
        }

        let ApproxDetails = {
            image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t('ApproxAmount'),
            lineTwo: this.state.ApproxAmount ? `${AmountFormat(this.state.ApproxAmount)}` : `${CurrencySymbol} 0.00`
        }

        let AdvanceDetails = {
            image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            height: "1.3vw",
            width: "1.3vw",
            lineOne: t('AdvanceAmount'),
            lineTwo: this.state.advance_amount ? `${AmountFormat(this.state.advance_amount)}` : `${CurrencySymbol} 0.00`
        }
        let attender = {
            lineOne: t("AttenderName"),
            lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
        }
        let ClaimDetails = {
            image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
            width: "1.3vw",
            height: "1.3vw",
            lineOne: t('Claimed Amount'),
            lineTwo: `${this.state.ApprovedAmt ? AmountFormat(this.state.ApprovedAmt) : `${CurrencySymbol} 0.00`}`
        }
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: "74vh" }}>
                <Box component={'div'} className='' height={'9.5vh'}>
                    <Box component={'div'} >
                        <Box component={'div'} display={'flex'}>
                            <Box component={'div'} className='eMed_nur_notes_details eMed_bill_header_one'>
                                <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
                            </Box>
                            {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
                            <CommonPatientCard details={AdvanceDetails} />
                            <CommonPatientCard details={serviceAmt} showOnecard={true} smallWidth={true} />
                            <CommonPatientCard details={PaidDetails} showOnecard={true} smallWidth={true} />
                            <CommonPatientCard details={PendingDetails} showOnecard={true} smallWidth={true} />
                            <CommonPatientCard details={ApproxDetails} smallWidth={true} />
                            <CommonPatientCard details={ClaimDetails} smallWidth={true} />
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'}>
                    <Box component={'div'} bgcolor={"white"} border={"1px solid lightgray"} margin={'0.2vw 0.8vw'} height={"64vh"} overflow={'scroll'}>
                        {this.renderTable()}
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.creditBillPopUp ?
                    <CreditBillCompletePoP
                        Module_ID_Key_Name={"ip_admission_id"}
                        Module_ID_Key_Value={this.state.IpCreditBill?.ip_admission_id}
                        modules={['ip', 'lab', 'pharmacy', 'ot','radiology']}
                        CrebillPosted={this.creditBillPost.bind(this)}
                        BillData={{ ...this.state.IpCreditBill, ...this.state.PatientDetails }}
                        ClosePopUp={this.ClosePopUp.bind(this)}
                        CollectedIn={"IP"}
                        FromFrontOffice={true}
                    /> : null}
                {this.state.isBillView ?
                    <CommonPopUp width={"57vw"} height={"75vh"}
                        backgroundColor={Colors.white}
                        popUpClose={this.viewBillPopupClose.bind(this)}
                        title={"View Bill Details"}
                        component={this.renderViewBillComponent.bind(this)}
                    />
                    : null
                }
                {
                    this.state.CreditTypeChangePopup ? <CreditTypeChangePop BillData={this.state.dataForTypeChage} CrebillTypeChaged={this.CrebillTypeChaged.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} /> : null
                }
                {
                    Object.keys(this.state.BillModifyHistoryItem).length > 0 ? <EditHistoryPopup BillType={this.state.BillModifyHistoryItem?.module} BillId={this.state.BillModifyHistoryItem?.id} SelectedBill={this.state.BillModifyHistoryItem} HistoryPopClose={this.HistoryPopClose.bind(this)} /> : null
                }
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.receiptID !== null || this.state.pharmacReceipt !== null ?
                    <BillSuccessPoPUp creditreceipt={this.state.receiptID} phrma_creditreceipt={this.state.pharmacReceipt} ClosePopUp={this.ClosesuccessPopUp.bind(this)} /> : null}
            </Box>
        )
    }
}

export default withTranslation()(IpPatientBills)