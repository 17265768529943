import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import {
    Accordion, AccordionSummary, IconButton, Typography, Autocomplete,
    TextField, Box, InputAdornment, AccordionDetails, Table, TableRow,
    TableCell, TableHead, TableBody, Tooltip, Button, Modal, Paper, Select, MenuItem,
    OutlinedInput,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { CurrencySymbol } from "../../../../Utility/Constants";
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { DateTime } from 'luxon';
import { AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../../Styles/Colors';
import moment from 'moment';
import PercentIcon from '@mui/icons-material/Percent';
import { AddCircle } from '@material-ui/icons';
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { localGetItem } from "../../../../Utility/Services/CacheProviderService";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const Unit_Types = [
    { label: `${CurrencySymbol}`, value: 'rupee' },
    { label: '%', value: 'percentage' }
]

const lineItem = {
    display_name: "",
    amount_type: "rupee",
    discount_amount: "",
    discount_percentage: "",
    is_doctor_wise: false,
    service_amount: 0,
    service_charges: {},
    service_discount_edit: false,
    service_id: null,
    service_name: "",
    service_rate_edit: false,
    service_rate_per_unit: 0,
    service_total_amount: 0,
    service_type: "",
    service_unit: 0,
    service_unit_edit: false,
    total_price: 0,
}

class MultiOPBilling extends Component {
    constructor(props) {
        super(props);
        this.testNameRef = React.createRef();
        this.state = {
            openAccordion: false,
            editRate: false,
            editUnit: false,
            radDetails: {},
            overAllDiscountAmt: '',
            overAllDiscountPercent: '',
            totalBillAmt: 0,
            docSplitPopup: false,
            radList: [],
            serviceData: [],
            templateDataList: [],
            opBillingList: [lineItem],
            errorMessages: [""],
            payableAmount: 0,
            totalServiceAmount: 0,
            discountReason: "",
            discountReasonPopup: false,
            is_gst_enabled: JSON.parse(localGetItem("is_gst_enabled")),
            totalDiscountedAmount: '',
            totalDiscountedPercentage: '',
            totalDiscountedPercentage_w_r: '',
            discountPercentDoctorFee_w_r: '',
            discountPercentHospitalFee_w_r: '',
            docPercent_w_r: '',
            hospitalPercent_w_r: '',
            concessionRestrictAmount: 0,
            isDeleteAll: false,
            ClearClicked: false,
            isDeleteCliked: false,
            deleteIndex: null,
            deleteService: ""
        }
    }

    componentDidMount = () => {
        this.getServiceListData();
        this.gettemplateData()
        let concession = localGetItem('billing_concession_type')
        let showCode = JSON.parse(localGetItem('op_ip_service_code')) ? JSON.parse(localGetItem('op_ip_service_code')) : false
        this.setState({
            billConcessionType: concession,
            showCode: showCode
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.onStateChange(this.state);
        }
        if (prevProps.triggerSignal !== this.props.triggerSignal) {
            this.setState({
                openAccordion: false
            }, () => {
                this.onClearHandler();
            })
        }
    }


    getServiceListData = () => {
        const { errorMessage } = this.props;
        try {
            let startDate = new Date()
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let invoice_date = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}`
            RestAPIService.getAll(Serviceurls.FO_NEW_OP_SERVICE_CONFIG + '?billing_time=' + Aptime + '&billing_date=' + invoice_date)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const list = response.data.data || [];
                        const dataList = list?.reduce((acc, cur) => {
                            const servicesWithDisplayName = cur?.data?.map(service => ({
                                ...service,
                                display_name: `${service?.service_type ? service?.service_type : '-'} - ${service?.service_name ? service?.service_name : '-'} - ${service?.op_service_code ? service?.op_service_code : '-'} `
                            }));
                            return [...acc, ...servicesWithDisplayName];
                        }, []);
                        this.setState({
                            serviceData: dataList,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    gettemplateData = () => {
        const { errorMessage } = this.props;
        try {
            let startDate = new Date()
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let invoice_date = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}`
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSRCTEMPLATE + '?billing_time=' + Aptime + '&billing_date=' + invoice_date)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const list = response.data.data || [];
                        this.setState({
                            templateDataList: list,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    renderAutoComplete = (label, statekey, list, showValue) => {
        const { t } = this.props;
        return (
            <Autocomplete
                disableClearable
                openOnFocus
                value={this.state[statekey] ? this.state[statekey] : null}
                sx={{ width: "16dvw" }}
                size="small"
                options={list?.length > 0 ? list : []}
                getOptionLabel={option => typeof (option) === "string" ? option : option[showValue]}
                onChange={(event, newValue) => {
                    this.setState({
                        [statekey]: newValue,
                    })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t(label)}
                        autoComplete="off"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiradBilling_${label}`
                        }}
                    />
                )}
            />
        )
    }

    convertTimeToDecimal(time) {
        let [hours, minutes] = time?.split(':').map(Number);
        return hours * 60 + minutes;
    }

    CalculateHourAmount(ratePerHour, changedTime, fixedTime, minTime, minAmount) {
        let fixedTimes = this.convertTimeToDecimal(fixedTime);
        let changedTimes = this.convertTimeToDecimal(changedTime);
        let minTimes = this.convertTimeToDecimal(minTime ? minTime : '00:00')
        let changeAndFixedTime = changedTimes - fixedTimes
        let time = changeAndFixedTime > 0 ? changeAndFixedTime : 0
        let timeCalculateTime = (time === 0 && minTimes === 0) ? 0 : Math.ceil(time / minTimes) === Infinity ? 0 : Math.ceil(time / minTimes)
        let totalCost = ((+ratePerHour) + (timeCalculateTime * (minAmount ? minAmount : 0)))
        // let minTimeHourConvert = minTimes / 60
        // let totalCost = Math.ceil((changedTimes / fixedTimes) - minTimeHourConvert) * ratePerHour;

        return totalCost;
    }

    onChangeTableHandler = (e, key) => {
        try {
            const { name, value } = e.target
            let { opBillingList } = this.state
            switch (name) {
                case 'rateUnit':
                    if ((CommonValidation.NumberWithDot(value) && value >= 1) || value === "") {
                        if (((opBillingList[key]?.service_catagory__catagory_name === "Consultation") || (opBillingList[key]?.service_catagory__catagory_name === 'OP Procedures')) && (opBillingList[key]?.service_fee?.doctor_fee || opBillingList[key]?.service_fee?.hospital_fee)) {
                            opBillingList[key]['totalAmount'] = opBillingList[key]["time_taken"] ? this.CalculateHourAmount(value, opBillingList[key]['time_taken'], (opBillingList[key]["old_time_taken"] ? opBillingList[key]["old_time_taken"] : opBillingList[key]['time_taken']), opBillingList[key]['minimum_time'], opBillingList[key]["min_amount"]) : (opBillingList[key]['unit'] * value)
                            opBillingList[key]['amount'] = +value
                            opBillingList[key]['service_fee']['doctor_fee'] = CommonValidation.calculatePercentage(opBillingList[key]['service_fee']['doctor_percentage'], (value * opBillingList[key]['unit']))
                            opBillingList[key]['service_fee']['hospital_fee'] = CommonValidation.calculatePercentage(opBillingList[key]['service_fee']['hospital_percentage'], (value * opBillingList[key]['unit']))
                            opBillingList[key]['service_fee']['total_amt'] = opBillingList[key]['unit'] * +value
                            opBillingList[key]['service_fee']['discount_hospital_fee'] = opBillingList[key]['service_fee']['discount_hospital_percentage'] ? CommonValidation.calculatePercentage(opBillingList[key]['service_fee']['discount_hospital_percentage'], opBillingList[key]['service_fee']['hospital_fee']) : 0;
                            opBillingList[key]['service_fee']['discount_doctor_fee'] = opBillingList[key]['service_fee']['discount_doctor_percentage'] ? CommonValidation.calculatePercentage(opBillingList[key]['service_fee']['discount_doctor_percentage'], opBillingList[key]['service_fee']['doctor_fee']) : 0;
                            opBillingList[key]['discountAmount'] = opBillingList[key]['service_fee']['discount_doctor_fee'] + opBillingList[key]['service_fee']['discount_hospital_fee']
                            opBillingList[key]['discountPercentage'] = CommonValidation.amountPercentageWithoutRoundoff(opBillingList[key]['discountAmount'], opBillingList[key]['service_fee']['total_amt'])
                            opBillingList[key]['discountPercentage_w_r'] = CommonValidation.amountPercentage(opBillingList[key]['discountAmount'], opBillingList[key]['service_fee']['total_amt'])

                        } else {
                            opBillingList[key]['totalAmount'] = opBillingList[key]["time_taken"] ? this.CalculateHourAmount(value, opBillingList[key]['time_taken'], (opBillingList[key]["old_time_taken"] ? opBillingList[key]["old_time_taken"] : opBillingList[key]['time_taken']), opBillingList[key]['minimum_time'], opBillingList[key]["min_amount"]) : (opBillingList[key]['unit'] * value)
                            opBillingList[key]['discountAmount'] = CommonValidation.calculatePercentage(opBillingList[key]['totalAmount'], opBillingList[key]['discountPercentage'])
                            opBillingList[key]['discountPercentage'] = CommonValidation.amountPercentageWithoutRoundoff(opBillingList[key]['discountAmount'], opBillingList[key]['totalAmount'])
                            opBillingList[key]['discountPercentage_w_r'] = CommonValidation.amountPercentage(opBillingList[key]['discountAmount'], opBillingList[key]['totalAmount'])
                            opBillingList[key]['amount'] = +value
                        }
                        this.setState({ opBillingList }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: '',
                                totalDiscountedPercentage_w_r: '',
                            })
                        })
                    }
                    break;
                case 'percentage':
                    if ((CommonValidation.DecimalNumber(value) && +value >= 1 && +value <= 100) || value === "") {
                        opBillingList[key]['discountAmount'] = CommonValidation.calculatePercentage(value, opBillingList[key]['totalAmount'])
                        opBillingList[key]['discountPercentage'] = value
                        opBillingList[key]['discountPercentage_w_r'] = value
                        this.setState({ opBillingList }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: '',
                                totalDiscountedPercentage_w_r: '',
                            })
                        })
                    }
                    break;
                case 'discountAmount':
                    let totalAmount = opBillingList[key]['totalAmount']
                    if ((CommonValidation.NumberWithDot(value) && +value >= 1 && value <= totalAmount) || value === "") {
                        opBillingList[key]['discountPercentage'] = CommonValidation.amountPercentageWithoutRoundoff(value, opBillingList[key]['totalAmount'])
                        opBillingList[key]['discountPercentage_w_r'] = CommonValidation.amountPercentage(value, opBillingList[key]['totalAmount'])
                        opBillingList[key]['discountAmount'] = value
                        this.setState({ opBillingList }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: '',
                                totalDiscountedPercentage_w_r: '',
                            })
                        })
                    }
                    break;
                case 'totalDiscountedPercentage':
                    let percentRestrict = +this.state.billConcessionType === 1 ? 100 : CommonValidation.amountPercentageWithoutRoundoff(this.state.concessionRestrictAmount, this.state.totalServiceAmount)
                    if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= percentRestrict) || value === "") {
                        this.state.totalDiscountedAmount = CommonValidation.calculatePercentage(value, this.state.totalServiceAmount)
                        this.setState({
                            totalDiscountedPercentage: value,
                            totalDiscountedPercentage_w_r: value
                        }, () => {
                            if (+value === 100) {
                                this.setState({
                                    isCard: false, isCash: false,
                                    isUPI: false, isBankTranfer: false,
                                    isCheque: false, CashAmount: "",
                                    CardAmount: "", CardTransactionNumber: "",
                                    UPINumber: "", UPIAmount: "",
                                    BankTfrAmount: "", BankTfrNumber: "",
                                    ChequeNumber: "", ChequeAmount: "",
                                })
                            }
                        })
                    }
                    break;
                case 'totalDiscountedAmount':
                    if ((CommonValidation.NumberWithDot(value) && value >= 1 && value <= this.state.concessionRestrictAmount) || value === "") {
                        this.state.totalDiscountedPercentage = CommonValidation.amountPercentageWithoutRoundoff(value, this.state.totalServiceAmount)
                        this.state.totalDiscountedPercentage_w_r = CommonValidation.amountPercentage(value, this.state.totalServiceAmount)
                        this.setState({
                            totalDiscountedAmount: value
                        }, () => {
                            if (+this.state.totalDiscountedPercentage === 100) {
                                this.setState({
                                    isCard: false, isCash: false,
                                    isUPI: false, isBankTranfer: false,
                                    isCheque: false, CashAmount: "",
                                    CardAmount: "", CardTransactionNumber: "",
                                    UPINumber: "", UPIAmount: "",
                                    BankTfrAmount: "", BankTfrNumber: "",
                                    ChequeNumber: "", ChequeAmount: "",
                                })
                            }
                        })
                    }
                    break;
                case "serviceSearch":
                    this.setState({
                        serviceSearch: value
                    }, () => {
                        // if (this.state.serviceSearch === "") {
                        //     this.getServiceTemplateData()
                        //     this.setState({
                        //         service: ""
                        //     })
                        // } else {
                        //     this.getServiceTemplateData()
                        // }
                        if (this.state.serviceSearch === "") {
                            // this.getServiceTemplateData()
                            this.setState({
                                service: "",
                                serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew)
                            })
                        } else {
                            // this.getServiceTemplateData()
                            this.setState({
                                service: "",
                                serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew)?.filter((list) => (list?.template_name?.toLowerCase()?.includes(this.state.serviceSearch?.toLowerCase())))
                            })
                        }
                    })
                    break;
                case "descriptionSerach":
                    this.setState({
                        descriptionSerach: value
                    }, () => {
                        // if (this.state.descriptionSerach === "") {
                        //   // this.getServiceDescriptionData()
                        //   this.setState({
                        //     newDescriptionName: "",
                        //     description: "",
                        //     newDescription: [],
                        //     dupNewDescription: '[]',
                        //     searchServiceName: '',
                        //   })
                        // } else {
                        //   // this.getServiceDescriptionData()
                        // }
                        if (this.state.descriptionSerach === "") {
                            // this.getServiceTemplateData()
                            this.setState({
                                newDescriptionName: "",
                                description: "",
                                newDescription: [],
                                dupNewDescription: '[]',
                                searchServiceName: '',
                                serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew)
                            })
                        } else {
                            // this.getServiceTemplateData()
                            this.setState({
                                newDescriptionName: "",
                                description: "",
                                newDescription: [],
                                dupNewDescription: '[]',
                                searchServiceName: '',
                                serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew)?.filter((list) => (list?.service_type?.toLowerCase()?.includes(this.state.descriptionSerach?.toLowerCase())))
                            })
                        }
                    })
                    break;
                case 'serviceCodeSearch':
                    this.setState({ serviceCodeSearch: value })
                    break;
                default:
                    this.setState({
                        [name]: value
                    })
                    break;
            }
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    discountReasonPopup = () => {
        try {
            return (
                <Modal open={this.state.discountReasonPopup}>
                    <Box className='eMed_Discount_Reason'>
                        <Box component={'div'} height={'2vw'} display={'flex'} m={'1vw'} justifyContent={'space-between'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>OP Bill Concession Reason</Typography>
                        </Box>
                        <Box m={'1vw'}>
                            <TextField
                                fullWidth
                                multiline
                                label='Reason *'
                                rows={3}
                                value={this.state.discountReason}
                                onChange={(e) => {
                                    this.setState({ discountReason: e.target.value })
                                }}
                            />
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                variant='outlined'
                                sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({ discountReasonPopup: false, discountReason: '' })
                                }}
                            >Close</Button>
                            <Button
                                variant='contained'
                                sx={{ width: '5vw', height: '2vw' }}
                                onClick={() => {
                                    if (this.state.discountReason !== '') {
                                        this.setState({ discountReasonPopup: false })
                                    } else {
                                        this.props.errorMessage("Reason is mandatory", 'error')
                                    }
                                }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    concessionManditoryPopup = (names) => {
        if ((this.state.totalDiscountedPercentage || this.state.totalDiscountedAmount) && (this.state.discountReason === "")) {
            if (names === 'totalDiscountedPercentage' || names === 'totalDiscountedAmount') {
                this.setState({ discountReasonPopup: true })
            }
        }
    }

    inputTextBox = (names, value, key, placeholder, height, diasble) => {
        let search = names === "serviceSearch" || names === "descriptionSerach" || names === 'serviceCodeSearch'
        return (
            <TextField
                autoComplete='off'
                size='small'
                className={search ? 'eMed_bill_search' : 'eMed_discount_txt'}
                placeholder={placeholder}
                disabled={(this.state.corporatePay || this.state.forCancel) ? true : diasble}
                inputProps={{ style: { height: height, fontSize: '0.8vw' } }}
                name={names}
                value={value}
                onChange={(e) => { this.onChangeTableHandler(e, key) }}
            />
        )
    }

    textBox = (names, value, width, height = "", placeholder, index) => {
        try {
            return (
                <TextField
                    autoComplete='off'
                    InputProps={{
                        style: height ? { height: height } : {},
                        endAdornment: <InputAdornment position="end">{placeholder}</InputAdornment>
                    }}
                    size='small'
                    sx={{ width: width }}
                    name={names}
                    value={value}
                    onChange={(e) => this.onChangeTableHandler(e, names, index)}
                    inputProps={{ emed_tid: names }}
                    onBlur={(e) => { this.concessionManditoryPopup(names) }}
                    disabled={((names === "totalDiscountedAmount" || names === "totalDiscountedPercentage") && this.state.totalServiceAmount === 0)}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    resetBillSummary = () => {
        this.setState({
            billSummaryList: [lineItem],
            errorMessages: [""],
            payableAmount: 0,
            netAmount: 0,
            roundOff: 0,
            discountAmount: 0,
            discountPercentage: 0,
            netAmount: 0,
        })
    }

    addShare = (item, index) => {
        this.setState({
            docAmt: item?.service_fee?.original_doctor_fee ? item.service_fee?.original_doctor_fee : item.service_fee?.doctor_fee ? item.service_fee?.doctor_fee : '',
            docPercent: item.service_fee?.doctor_percentage ? item.service_fee?.doctor_percentage : '',
            docPercent_w_r: item.service_fee?.doctor_percentage ? CommonValidation.formatAmount(+item.service_fee?.doctor_percentage) : '',
            hospitalAmt: item?.service_fee?.original_hospital_fee ? item.service_fee?.original_hospital_fee : item.service_fee?.hospital_fee ? item.service_fee?.hospital_fee : '',
            hospitalPercent: item.service_fee?.hospital_percentage ? item.service_fee?.hospital_percentage : '',
            hospitalPercent_w_r: item.service_fee?.hospital_percentage ? CommonValidation.formatAmount(item.service_fee?.hospital_percentage) : '',
            totalBillPercent: '100',
            rowIndex: index,
            selectedData: item,
            dupliSelectedData: JSON.stringify(item),
        }, () => {
            this.setState({ docSplitPopup: true, totalBillAmt: +this.state.docAmt + +this.state.hospitalAmt })
        })
    }

    amtCalculate = (e, name) => {
        let states = JSON.parse(JSON.stringify(this.state))
        let value = e.target.value ? e.target.value : "0"
        let serviceFee = JSON.parse(JSON.stringify(states.selectedData?.service_fee))
        switch (name) {
            case 'doctorAmt':
                if (CommonValidation.RangeDecimalNumber(value) || value == '') {
                    serviceFee.total_amt = (+value) + (+states.hospitalAmt)
                    states.selectedData.amount = serviceFee.total_amt
                    states.selectedData.totalAmount = states.selectedData?.amount * (+states.selectedData?.unit)

                    this.setState({
                        docAmt: +value,
                        selectedData: states.selectedData
                    }, () => {
                        let totalAmt = (+value) + (+states.hospitalAmt)
                        this.setState({
                            totalBillAmt: (+value) + (+states.hospitalAmt)
                        }, () => {
                            this.setState({
                                docPercent: totalAmt ? +((+value / totalAmt) * 100) : 0,
                                docPercent_w_r: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                                hospitalPercent: totalAmt ? +((+states.hospitalAmt / totalAmt) * 100) : 0,
                                hospitalPercent_w_r: totalAmt ? +((+states.hospitalAmt / totalAmt) * 100).toFixed(2) : 0,
                            })
                        })
                    })
                }
                break;
            case 'hospitalAmt':
                if (CommonValidation.RangeDecimalNumber(value) || value == '') {
                    serviceFee.total_amt = (+states.docAmt) + (+value)
                    states.selectedData.amount = serviceFee.total_amt
                    states.selectedData.totalAmount = states.selectedData?.amount * (+states.selectedData?.unit)

                    this.setState({
                        hospitalAmt: +value,
                        selectedData: this.state.selectedData
                    }, () => {
                        let totalAmt = (+states.docAmt) + (+value)
                        this.setState({
                            totalBillAmt: (+states.docAmt) + (+value)
                        }, () => {
                            this.setState({
                                docPercent: totalAmt ? +((+states.docAmt / totalAmt) * 100) : 0,
                                docPercent_w_r: totalAmt ? +((+states.docAmt / totalAmt) * 100).toFixed(2) : 0,
                                hospitalPercent: totalAmt ? +((+value / totalAmt) * 100) : 0,
                                hospitalPercent_w_r: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                            })
                        })
                    })
                }
                break;
            default:
                break;
        }
    }

    newShareSplitUp = () => {
        try {
            let { opBillingList, selectedData } = this.state
            opBillingList[this.state.rowIndex].amount = this.state.totalBillAmt ? +(+this.state.totalBillAmt / opBillingList[this.state.rowIndex].unit).toFixed(2) : 0
            opBillingList[this.state.rowIndex].service_fee.doctor_percentage = this.state.docPercent ? +this.state.docPercent : 0
            opBillingList[this.state.rowIndex].service_fee.doctor_percentage_w_r = this.state.docPercent_w_r ? +this.state.docPercent_w_r : 0
            opBillingList[this.state.rowIndex].service_fee.hospital_percentage = this.state.hospitalPercent ? +this.state.hospitalPercent : 0
            opBillingList[this.state.rowIndex].service_fee.hospital_percentage_w_r = this.state.hospitalPercent_w_r ? +this.state.hospitalPercent_w_r : 0
            opBillingList[this.state.rowIndex].service_fee.original_doctor_fee = this.state.docAmt ? +this.state.docAmt : 0
            opBillingList[this.state.rowIndex].service_fee.original_hospital_fee = this.state.hospitalAmt ? +this.state.hospitalAmt : 0
            opBillingList[this.state.rowIndex].service_fee.discount_hospital_percentage = this.state.hospitalAmt ? opBillingList[this.state.rowIndex]?.service_fee.discount_hospital_percentage : 0
            opBillingList[this.state.rowIndex].service_fee.discount_hospital_percentage_w_r = this.state.hospitalAmt ? opBillingList[this.state.rowIndex]?.service_fee.discount_hospital_percentage_w_r : 0
            opBillingList[this.state.rowIndex].service_fee.discount_doctor_percentage = this.state.docAmt ? opBillingList[this.state.rowIndex]?.service_fee.discount_doctor_percentage : 0
            opBillingList[this.state.rowIndex].service_fee.discount_doctor_percentage_w_r = this.state.docAmt ? opBillingList[this.state.rowIndex]?.service_fee.discount_doctor_percentage_w_r : 0
            this.setState({ opBillingList }, () => {
                this.setState({ docSplitPopup: false }, () => {
                    this.CalculateServiceValues(this.state.rowIndex)
                })
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }


    splitScreenPopup = () => {
        return (
            <Modal open={this.state.docSplitPopup}>
                <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "31vw", height: "60vh" }}>
                    <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                        <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>
                            Split-Up
                        </Typography>
                        <div className="eMed_DialogBox_Close">
                            <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.closeSplitPopup} />
                        </div>
                    </div>
                    <hr />
                    <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                        <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>Doctor Consultation</Typography>
                        {/* <div style={{display:'flex'}}>
                                        <Typography sx={{ fontSize: '1vw' }}>Select Type</Typography>
                                        <Select 
                                            size='small'
                                            sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                                            
                                        ><MenuItem>%</MenuItem></Select>
                                        </div> */}
                    </div>
                    <Box>
                        <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} pl={'0.5vw'}>
                            <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600}> Rate / Unit</Typography>
                                <Typography fontWeight={'600'} pl={'1.5vw'}>{`${AmountFormat(this.state.totalBillAmt / this.state.selectedData?.unit)}`}</Typography>
                                <Typography pl={'0.5vw'} marginTop={"0.3vw"} fontSize={"0.7vw"} color={'grey'}>{`${(this.state.is_gst_enabled && this.state.selectedData?.gst_percentage) ? `+ ${this.state.selectedData?.gst_percentage} % GST` : ""}`}</Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>Unit</Typography>
                                <Typography fontWeight={'600'} pl={'1.5vw'}>{this.state.selectedData?.unit ? this.state.selectedData?.unit : "-"}</Typography>
                            </Box>
                        </Box>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Doctor Share</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.docAmt} onChange={(e) => this.amtCalculate(e, 'doctorAmt')}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.docPercent_w_r}></TextField>
                            </div>
                        </div>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Hospital Share</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.hospitalAmt} onChange={(e) => { this.amtCalculate(e, 'hospitalAmt') }}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.hospitalPercent_w_r}></TextField>
                            </div>
                        </div>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Total</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Amount' value={AmountFormat(this.state.totalBillAmt || 0)}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.totalBillPercent}></TextField>
                            </div>
                        </div>
                    </Box>
                    <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            variant='outlined'
                            sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                            onClick={this.closeSplitPopup}
                        >Close</Button>
                        <Button
                            variant='contained'
                            sx={{ width: '5vw', height: '2vw' }}
                            // onClick={() => {this.postSplitShare()}}
                            onClick={() => this.newShareSplitUp()}
                        >Save</Button>
                    </Box>
                </Paper>
            </Modal>
        )
    }

    closeSplitPopup = () => {
        this.setState({ docSplitPopup: false, selectedData: JSON.parse(this.state.dupliSelectedData) })
    }

    validCheck = (data) => {
        const { opBillingList } = this.state;
        let duplicate = false;
        if (opBillingList?.length > 0) {
            opBillingList?.forEach((element) => {
                if (((element?.service_type === data?.service_type) && (element?.service_name === data?.service_name))) {
                    duplicate = true
                }
            })
        }
        return duplicate;
    }

    getCurrentTimeTotalAmt = (timeSlots, serviceFee, otherservices) => {
        let emergencyFee = "";
        let stateTime = DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_24_WITH_SECONDS)

        const [stateHours, stateMinutes, stateSeconds] = stateTime.split(":").map(Number);
        const stateDate = new Date();
        stateDate.setHours(stateHours);
        stateDate.setMinutes(stateMinutes);
        stateDate.setSeconds(stateSeconds);

        for (const slot of timeSlots) {
            const startTime = new Date();
            const endTime = new Date();
            const [startHours, startMinutes, startPeriod] = slot?.start_time?.match(/(\d+):(\d+)\s*(AM|PM)/i).slice(1);
            const [endHours, endMinutes, endPeriod] = slot?.end_time?.match(/(\d+):(\d+)\s*(AM|PM)/i).slice(1);

            startTime.setHours(
                startPeriod === "PM" ? (parseInt(startHours) % 12) + 12 : parseInt(startHours) % 12
            );
            startTime.setMinutes(parseInt(startMinutes));
            startTime.setSeconds(0);

            endTime.setHours(
                endPeriod === "PM" ? (parseInt(endHours) % 12) + 12 : parseInt(endHours) % 12
            );
            endTime.setMinutes(parseInt(endMinutes));
            endTime.setSeconds(0);

            if (stateDate >= startTime && stateDate <= endTime) {
                emergencyFee = serviceFee ? slot : (otherservices ? (slot.emergency_amt || slot.emergency_amt == '' ? (slot.emergency_amt == '' ? 0 : slot.emergency_amt) : ((slot.total_amt || slot.total_amt == '') ? slot.total_amt == '' ? 0 : slot.total_amt : slot.total_amt)) : (slot.total_amt || slot.total_amt == '' ? (slot.total_amt == '' ? 0 : slot.total_amt) : (slot.emergency_amt || slot.emergency_amt == '' ? (slot.emergency_amt == '' ? 0 : slot.emergency_amt) : slot.emergency_amt)));
                break;
            }
        }
        return emergencyFee;
    };

    handleChangeSrv = (index, value) => {
        console.log(index, value, "from onchange")
        const { t } = this.props;
        let states = this.state
        if (value) {
            let object = value
            let ServiceCharges = object.service_charges[0]
            if ((object.service_catagory__catagory_name === "Consultation")) {
                if (object.is_holiyday && (states.patientData?.patient_type !== "review_patient")) {
                    let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                    let newPatient = ServiceCharges?.new_patient?.total_amt;
                    let oldPatient = ServiceCharges?.old_patient?.total_amt;
                    let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                    let emergencyFee = object.is_emergency ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee) : null;

                    object.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : object.is_emergency ? emergencyFee !== '' ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee, true) : states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient : states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                    object.totalAmount = holidayFee !== '' ? holidayFee : object.is_emergency ? emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                    object.amount = object.totalAmount

                } else if ((!object.is_holiyday && object.is_emergency) && (states.patientData?.patient_type !== "review_patient")) {
                    let emergencyFee = this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee);
                    let newPatient = ServiceCharges?.new_patient?.total_amt;
                    let oldPatient = ServiceCharges?.old_patient?.total_amt;
                    let reviewPatient = ServiceCharges?.review_patient?.total_amt;

                    object.service_fee = emergencyFee !== '' ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee, true) : states.patientData.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                    object.totalAmount = emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                    object.amount = object.totalAmount
                } else {
                    let newPatient = ServiceCharges?.new_patient?.total_amt;
                    let oldPatient = ServiceCharges?.old_patient?.total_amt;
                    let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                    object.totalAmount = states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                    object.amount = object.totalAmount
                    object.service_fee = states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                }
            } else if ((object.service_catagory__catagory_name === "OP Procedures")) {
                let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                let emergencyFee = object.is_emergency ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee) : null;
                let serviceFee = ServiceCharges?.service_fee?.total_amt

                if (object.is_holiyday && (states.patientData?.patient_type !== "review_patient")) {
                    object.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : object.is_emergency ? emergencyFee !== '' ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee, true) : ServiceCharges?.service_fee : ServiceCharges?.service_fee
                    object.totalAmount = holidayFee !== '' ? holidayFee : object.is_emergency ? emergencyFee !== '' ? emergencyFee : serviceFee : serviceFee
                    object.amount = object.totalAmount

                    // =====> Commented for flow stopped <==== //
                    // object.amount = holidayFee !== '' ? holidayFee : serviceFee
                    // object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                    // object.end_date = null
                    // object.changed_start_time = null
                    // object.changed_end_time = null
                    // object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken) : holidayFee !== '' ? holidayFee : serviceFee
                    // object.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : ServiceCharges?.service_fee

                } else if ((!object.is_holiyday && object.is_emergency) && (states.patientData?.patient_type !== "review_patient")) {

                    object.service_fee = emergencyFee !== '' ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee, true) : ServiceCharges?.service_fee
                    object.totalAmount = emergencyFee !== '' ? emergencyFee : serviceFee
                    object.amount = object.totalAmount

                    // =====> Commented for flow stopped <==== //
                    // object.amount = emergencyFee !== '' ? emergencyFee : serviceFee
                    // object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                    // object.end_date = null
                    // object.changed_start_time = null
                    // object.changed_end_time = null
                    // object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken) : emergencyFee !== '' ? emergencyFee : serviceFee
                    // object.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : ServiceCharges?.service_fee

                } else {

                    object.totalAmount = serviceFee
                    object.amount = object.totalAmount
                    object.service_fee = ServiceCharges?.service_fee

                    // =====> Commented for flow stopped <==== //
                    // object.amount = serviceFee
                    // object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                    // object.end_date = null
                    // object.changed_start_time = null
                    // object.changed_end_time = null
                    // object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken) : serviceFee
                    // object.service_fee = ServiceCharges?.service_fee

                }
            } else {
                let otherEmergencyFee = object.is_emergency ? this.getCurrentTimeTotalAmt(ServiceCharges.emergency_fee, false, true) : null;
                if (object.is_holiyday && (states.patientData?.patient_type !== "review_patient")) {
                    object.amount = ServiceCharges.holiday_fee !== '' ? ServiceCharges.holiday_fee : object.is_emergency ? otherEmergencyFee !== '' ? otherEmergencyFee : ServiceCharges.service_amount : ServiceCharges.service_amount
                    object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                    object.end_date = null
                    object.changed_start_time = null
                    object.changed_end_time = null
                    object.old_time_taken = object.time_taken
                    object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.holiday_fee !== '' ? ServiceCharges.holiday_fee : otherEmergencyFee !== '' ? otherEmergencyFee : ServiceCharges.service_amount
                    object.service_fee = ServiceCharges

                } else if ((!object.is_holiyday && object.is_emergency) && (states.patientData?.patient_type !== "review_patient")) {
                    object.amount = otherEmergencyFee !== '' ? otherEmergencyFee : ServiceCharges.service_amount
                    object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                    object.end_date = null
                    object.changed_start_time = null
                    object.changed_end_time = null
                    object.old_time_taken = object.time_taken
                    object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object.minimum_time, ServiceCharges.minimum_amount) : otherEmergencyFee !== '' ? otherEmergencyFee : ServiceCharges.service_amount
                    object.service_fee = ServiceCharges
                } else {
                    object.amount = ServiceCharges.service_amount
                    object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                    object.end_date = null
                    object.changed_start_time = null
                    object.changed_end_time = null
                    object.old_time_taken = object.time_taken
                    object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.service_amount
                    object.service_fee = ServiceCharges
                }
            }
            object.min_amount = ServiceCharges.minimum_amount ? +ServiceCharges.minimum_amount : 0
            object.unit = "1"
            object.discountPercentage = ""
            object.discountAmount = ""
            object.noDiscountAmount = ""
            object.individual_discount_comments = ""
            object.is_auto_billing = false
            object.isSplitBlock = false
            let flag = false;
            this.state.opBillingList.forEach(element => {
                if (element.service_name === object.service_name && element.service_type === object.service_type && element?.amount === object?.amount || element.service_name === object.service_name && element.service_type === object.service_type && element?.totalAmount === object?.totalAmount) {
                    flag = true
                }
            })
            if (!flag) {
                let list = this.state.opBillingList
                list[index] = object
                if (index === list?.length - 1) {
                    list = [...list, lineItem]
                }
                this.state.opBillingList = list
                this.setState({
                    opBillingList: this.state.opBillingList
                }, () => {
                    this.setState({
                        totalDiscountedAmount: '',
                        totalDiscountedPercentage: ''
                    })
                    // this.onClearHandler()
                })
            } else {
                this.props.errorMessage("Service Type Already Exist", 'error')
                // this.onClearHandler()
            }
        }
    }

    renderSrvSearch = (index, item) => {
        const { t } = this.props;
        const { errorMessages } = this.state;
        return (
            <Autocomplete
                value={item.display_name || ""}
                size="small"
                onChange={(event, newValue) => { this.handleChangeSrv(index, newValue) }}
                options={this.state.serviceData || []}
                getOptionLabel={option => typeof (option) === "string" ? option : option?.display_name}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={(index === this.state.opBillingList?.length - 1) ? t("Search Service") : ""}
                        variant="standard"
                        autoComplete="off"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiradBilling_srvSrch`
                        }}
                        error={!!errorMessages[index]}
                        helperText={errorMessages[index] || ""}
                    />}
                sx={{ width: "30vw" }}
                disableClearable
                renderOption={(props, item) => (
                    <Box {...props}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: "flex-start" }}>
                            <Typography sx={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.service_name}</Typography>
                            <Typography sx={{ fontSize: "0.8vw" }}>{`${item?.service_catagory__catagory_name} ${item?.op_service_code ? ` - ( ${item?.op_service_code} )` : ""}`}</Typography>
                        </Box>
                    </Box>

                )}
            />
        )
    }

    CalculateServiceValues = (index) => {
        try {
            let { opBillingList } = this.state
            opBillingList[index].totalAmount = Math.round(opBillingList[index].unit * opBillingList[index].amount)
            opBillingList[index].service_fee.total_amt = Math.round(opBillingList[index].unit * opBillingList[index].amount)
            opBillingList[index].service_fee.original_doctor_fee = CommonValidation.calculatePercentage(opBillingList[index]?.service_fee?.doctor_percentage, opBillingList[index]?.service_fee?.total_amt)
            opBillingList[index].service_fee.original_hospital_fee = CommonValidation.calculatePercentage(opBillingList[index]?.service_fee?.hospital_percentage, opBillingList[index]?.service_fee?.total_amt)
            opBillingList[index].service_fee.discount_hospital_fee = opBillingList[index].service_fee?.discount_hospital_percentage ? CommonValidation.calculatePercentage(opBillingList[index].service_fee?.discount_hospital_percentage, opBillingList[index].service_fee.original_hospital_fee) : 0;
            opBillingList[index].service_fee.discount_doctor_fee = opBillingList[index].service_fee?.discount_doctor_percentage ? CommonValidation.calculatePercentage(opBillingList[index].service_fee?.discount_doctor_percentage, opBillingList[index].service_fee.original_doctor_fee) : 0;
            opBillingList[index].discountAmount = opBillingList[index]?.service_fee?.discount_doctor_fee + opBillingList[index]?.service_fee?.discount_hospital_fee
            opBillingList[index].discountPercentage = CommonValidation.amountPercentageWithoutRoundoff(opBillingList[index]?.discountAmount, opBillingList[index]?.service_fee?.total_amt)
            opBillingList[index].discountPercentage_w_r = CommonValidation.amountPercentage(opBillingList[index]?.discountAmount, opBillingList[index]?.service_fee?.total_amt)
            opBillingList[index].service_fee.doctor_fee = CommonValidation.formatAmount(opBillingList[index].service_fee.original_doctor_fee - (opBillingList[index].service_fee.discount_doctor_fee ? opBillingList[index].service_fee.discount_doctor_fee : 0))
            opBillingList[index].service_fee.hospital_fee = CommonValidation.formatAmount(opBillingList[index].service_fee.original_hospital_fee - (opBillingList[index].service_fee.discount_hospital_fee ? opBillingList[index].service_fee.discount_hospital_fee : 0))
            opBillingList[index].discountAmount = opBillingList[index]?.service_fee?.discount_doctor_fee + opBillingList[index]?.service_fee?.discount_hospital_fee
            opBillingList[index].discountPercentage = CommonValidation.amountPercentageWithoutRoundoff(opBillingList[index]?.discountAmount, opBillingList[index]?.service_fee?.total_amt)
            opBillingList[index].discountPercentage_w_r = CommonValidation.amountPercentage(opBillingList[index]?.discountAmount, opBillingList[index]?.service_fee?.total_amt)
            this.setState({ opBillingList }, () => {
                this.setState({
                    totalDiscountedAmount: '',
                    totalDiscountedPercentage: '',
                    totalDiscountedPercentage_w_r: '',
                })
                //  this.calculateTotalDiscount() 
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    onChangeUnit = (item, key, index) => {
        try {
            let { opBillingList } = this.state
            switch (key) {
                case 'increase':
                    if (item.unit >= 1) {
                        item.unit++
                        item.totalAmount = item.unit * item.amount
                        if (((item?.service_catagory__catagory_name === "Consultation") || (item?.service_catagory__catagory_name === 'OP Procedures')) && (item?.service_fee?.doctor_fee || item?.service_fee?.hospital_fee || item?.service_fee?.discount_doctor_percentage || item?.service_fee?.discount_hospital_percentage)) {
                            this.setState({ opBillingList }, () => {
                                this.CalculateServiceValues(index)
                            })
                        } else {
                            item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
                            item.discountPercentage = CommonValidation.amountPercentageWithoutRoundoff(item.discountAmount, item.totalAmount)
                            item.discountPercentage_w_r = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
                            this.setState({ opBillingList }, () => {
                                // this.calculateTotalDiscount()
                                this.setState({
                                    totalDiscountedAmount: '',
                                    totalDiscountedPercentage: '',
                                    totalDiscountedPercentage_w_r: '',
                                })
                            })
                        }
                    }
                    break;
                case 'decrease':
                    if (item.unit > 1) {
                        item.unit--
                        item.totalAmount = item.unit * item.amount
                        if (((item?.service_catagory__catagory_name === "Consultation") || (item?.service_catagory__catagory_name === 'OP Procedures')) && (item?.service_fee?.doctor_fee || item?.service_fee?.hospital_fee || item?.service_fee?.discount_doctor_percentage || item?.service_fee?.discount_hospital_percentage)) {
                            this.setState({ opBillingList }, () => {
                                this.CalculateServiceValues(index)
                            })
                        } else {
                            item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
                            item.discountPercentage = CommonValidation.amountPercentageWithoutRoundoff(item.discountAmount, item.totalAmount)
                            item.discountPercentage_w_r = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
                            this.setState({ opBillingList }, () => {
                                // this.calculateTotalDiscount()
                                this.setState({
                                    totalDiscountedAmount: '',
                                    totalDiscountedPercentage: '',
                                    totalDiscountedPercentage_w_r: '',
                                })
                            })
                        }
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    shareValue = () => {
        try {
            let states = this.state
            let doctorFee = 0; let discountDoctor = 0;
            let hospitalFee = 0; let discountHospital = 0
            let serviceFee = states.selectedData?.service_fee

            doctorFee = CommonValidation.formatAmount(serviceFee?.original_doctor_fee ? serviceFee?.original_doctor_fee : 0)
            discountDoctor = CommonValidation.formatAmount(serviceFee?.discount_doctor_fee ? serviceFee?.discount_doctor_fee : 0)
            hospitalFee = CommonValidation.formatAmount(serviceFee?.original_hospital_fee ? serviceFee?.original_hospital_fee : 0)
            discountHospital = CommonValidation.formatAmount(serviceFee?.discount_hospital_fee ? serviceFee?.discount_hospital_fee : 0)

            return { doctorFee, discountDoctor, hospitalFee, discountHospital };
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    onChangeSymbol = (e) => {
        try {
            let states = this.state
            if (states.selectedData?.service_charges?.length > 0) {
                let serviceFee = states.selectedData?.service_fee
                serviceFee.discount_doctor_fee = 0
                serviceFee.discount_hospital_fee = 0

            } else {
                let serviceFee = states.selectedData?.service_fee
                serviceFee.discount_doctor_fee = 0
                serviceFee.discount_hospital_fee = 0
                // states.selectedData.discount_doctor_fee = 0
                // states.selectedData.discount_hospital_fee = 0
            }
            this.setState({
                selectedType: e.target.value,
                discountDoctorFee: '',
                discountHospitalFee: '',
                discountPercentDoctorFee: '',
                discountPercentDoctorFee_w_r: '',
                discountPercentHospitalFee: '',
                discountPercentHospitalFee_w_r: '',
                selectedData: this.state.selectedData
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    feeValue = () => {
        try {
            let doctorValue = 0; let hospitalValue = 0;
            let duplicateSelectedValue = JSON.parse(this.state.dupliSelectedData)
            let serviceFee = duplicateSelectedValue?.service_fee
            doctorValue = serviceFee?.original_doctor_fee
            hospitalValue = serviceFee?.original_hospital_fee

            return { doctorValue, hospitalValue };
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    onChangeDiscountHandler = (e, names) => {
        try {
            let value = e.target.value
            let fee = this.feeValue()
            let states = this.state
            switch (names) {
                case 'discountDoctorFee':
                    if (this.state.selectedType === 'rupee') {
                        if ((CommonValidation.NumberWithDot(+value) && +value <= fee.doctorValue) || value === '') {
                            this.state.discountPercentDoctorFee = CommonValidation.amountPercentageWithoutRoundoff(+value, fee.doctorValue)
                            this.state.discountPercentDoctorFee_w_r = CommonValidation.amountPercentage(+value, fee.doctorValue)
                            this.setState({ discountDoctorFee: +value }, () => {
                                let serviceFee = states.selectedData?.service_fee
                                serviceFee.discount_doctor_fee = value ? value : 0
                                if (!serviceFee.discount_hospital_fee && !serviceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ states })
                            })
                        }
                    } else {
                        if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
                            this.state.discountDoctorFee = CommonValidation.calculatePercentage(+value, fee.doctorValue)
                            this.setState({ discountPercentDoctorFee: value, discountPercentDoctorFee_w_r: value }, () => {
                                let serviceFee = states.selectedData?.service_fee
                                serviceFee.discount_doctor_fee = value ? CommonValidation.calculatePercentage(value, fee.doctorValue) : 0
                                if (!serviceFee.discount_hospital_fee && !serviceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ states })
                            })
                        }
                    }
                    break;
                case 'discountHospitalFee':
                    if (this.state.selectedType === 'rupee') {
                        if ((CommonValidation.NumberWithDot(value) && value <= fee.hospitalValue) || value === '') {
                            this.state.discountPercentHospitalFee = CommonValidation.amountPercentageWithoutRoundoff(+value, fee.hospitalValue)
                            this.state.discountPercentHospitalFee_w_r = CommonValidation.amountPercentage(+value, fee.hospitalValue)
                            this.setState({ discountHospitalFee: +value }, () => {
                                let serviceFee = states.selectedData?.service_fee
                                serviceFee.discount_hospital_fee = value ? value : 0
                                if (!serviceFee.discount_hospital_fee && !serviceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ states })
                            })
                        }
                    } else {
                        if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
                            this.state.discountHospitalFee = CommonValidation.calculatePercentage(+value, fee.hospitalValue)
                            this.setState({ discountPercentHospitalFee: value, discountPercentHospitalFee_w_r: value }, () => {
                                let serviceFee = states.selectedData?.service_fee
                                serviceFee.discount_hospital_fee = value ? CommonValidation.calculatePercentage(value, fee.hospitalValue) : 0
                                if (!serviceFee.discount_hospital_fee && !serviceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ states })
                            })
                        }
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    splitUpTextBox = (names, values, label, width) => {
        try {
            return (
                <TextField
                    size='small'
                    sx={{ width: width }}
                    value={values}
                    label={label}
                    autoComplete='off'
                    onChange={(e) => this.onChangeDiscountHandler(e, names)}
                    InputProps={{
                        endAdornment: this.state.selectedType === 'rupee' ?
                            // <CurrencyRupee color='primary'></CurrencyRupee> :
                            CurrencySymbol :
                            <PercentIcon color='primary' />
                    }}
                />
            )
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    onSaveDiscountAmount = () => {
        try {
            let states = this.state
            let serviceFee = states.selectedData?.service_fee
            let totalDiscountAmount = (+states.discountDoctorFee) + (+states.discountHospitalFee)
            let total = 0; let discount = 0;
            let discountDoctor = this.state.discountPercentDoctorFee ? +this.state.discountPercentDoctorFee : serviceFee?.discount_doctor_fee ? serviceFee?.discount_doctor_fee : 0
            let discountHospital = this.state.discountPercentHospitalFee ? +this.state.discountPercentHospitalFee : serviceFee?.discount_hospital_fee ? serviceFee?.discount_hospital_fee : 0
            // if (discountDoctor !== 0 && discountHospital !== 0) {
            let doctorAmount = CommonValidation.calculatePercentage(states.discountPercentDoctorFee, serviceFee?.original_doctor_fee)
            let hospitalAmount = CommonValidation.calculatePercentage(states.discountPercentHospitalFee, serviceFee?.original_hospital_fee)
            discount = doctorAmount + hospitalAmount
            total = serviceFee?.original_doctor_fee + serviceFee?.original_hospital_fee
            let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            let discountPercent = CommonValidation.amountPercentageWithoutRoundoff(discount, total)
            let discountPercent_w_r = CommonValidation.amountPercentage(discount, total)
            states.selectedData.discountAmount = states.selectedType === 'rupee' ? ((+states.discountDoctorFee) + (+states.discountHospitalFee)) : discount
            states.selectedData.discountPercentage = states.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(totalDiscountAmount, total) : discountPercent
            states.selectedData.discountPercentage_w_r = states.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, total) : discountPercent_w_r
            // } else if (discountDoctor !== 0 && discountHospital === 0) {
            //     discount = +this.state.discountPercentDoctorFee
            //     let doctorAmount = CommonValidation.calculatePercentage(states.discountPercentDoctorFee, serviceFee?.original_doctor_fee)
            //     let hospitalAmount = CommonValidation.calculatePercentage(states.discountPercentHospitalFee, serviceFee?.original_hospital_fee)
            //     discount = doctorAmount + hospitalAmount
            //     total = serviceFee?.original_doctor_fee + serviceFee?.original_hospital_fee
            //     let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            //     let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            //     let discountPercent = doctorPercentage + hospitalPercentage
            //     let discountPercentAmount = CommonValidation.calculatePercentage(discount, (+total))
            //     states.selectedData.discountAmount = states.selectedType === 'rupee' ? ((+states.discountDoctorFee) + (+states.discountHospitalFee)) : discountPercentAmount
            //     states.selectedData.discountPercentage = states.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(totalDiscountAmount, total) : discountPercent
            // } else {
            //     discount = +this.state.discountPercentHospitalFee
            //     let doctorAmount = CommonValidation.calculatePercentage(states.discountPercentDoctorFee, serviceFee?.original_doctor_fee)
            //     let hospitalAmount = CommonValidation.calculatePercentage(states.discountPercentHospitalFee, serviceFee?.original_hospital_fee)
            //     discount = doctorAmount + hospitalAmount
            //     total = serviceFee?.original_doctor_fee + serviceFee?.original_hospital_fee
            //     let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            //     let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            //     let discountPercent = doctorPercentage + hospitalPercentage
            //     let discountPercentAmount = CommonValidation.calculatePercentage(discount, (+total))
            //     states.selectedData.discountAmount = states.selectedType === 'rupee' ? ((+states.discountDoctorFee) + (+states.discountHospitalFee)) : discountPercentAmount
            //     states.selectedData.discountPercentage = states.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(totalDiscountAmount, total) : discountPercent
            // }

            serviceFee.discount_doctor_fee = states.selectedType === 'rupee' ? +states.discountDoctorFee : CommonValidation.calculatePercentage(states.discountPercentDoctorFee, serviceFee?.original_doctor_fee)
            serviceFee.discount_doctor_percentage = states.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.discountDoctorFee, serviceFee?.original_doctor_fee) : +states.discountPercentDoctorFee
            serviceFee.discount_doctor_percentage_w_r = states.selectedType === 'rupee' ? CommonValidation.amountPercentage(states.discountDoctorFee, serviceFee?.original_doctor_fee) : +states.discountPercentDoctorFee_w_r
            serviceFee.discount_hospital_fee = states.selectedType === 'rupee' ? +states.discountHospitalFee : CommonValidation.calculatePercentage(states.discountPercentHospitalFee, serviceFee?.original_hospital_fee)
            serviceFee.discount_hospital_percentage = states.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.discountHospitalFee, serviceFee?.original_hospital_fee) : +states.discountPercentHospitalFee
            serviceFee.discount_hospital_percentage_w_r = states.selectedType === 'rupee' ? CommonValidation.amountPercentage(states.discountHospitalFee, serviceFee?.original_hospital_fee) : +states.discountPercentHospitalFee_w_r
            serviceFee.doctor_fee = ((serviceFee?.original_doctor_fee) - (serviceFee.discount_doctor_fee ? serviceFee.discount_doctor_fee : +states.discountDoctorFee))
            serviceFee.hospital_fee = ((serviceFee?.original_hospital_fee) - (serviceFee?.discount_hospital_fee ? serviceFee?.discount_hospital_fee : +states.discountHospitalFee))
            serviceFee.amount_type = this.state.selectedType
            serviceFee.total_amt = serviceFee.doctor_fee + serviceFee.hospital_fee
            states.selectedData.individual_discount_comments = states.IndivDiscountComments
            states.selectedData.service_fee = serviceFee
            states.opBillingList[this.state.discountIndex] = states.selectedData
            if (states.discountDoctorFee > 0 || states.discountHospitalFee > 0 || states.discountPercentDoctorFee > 0 || states.discountPercentHospitalFee > 0) {
                states.selectedData.isSplitBlock = true
            }

            this.setState({
                states,
                serviceDiscountpopup: false,
                discountIndex: null
            }, () => {
                // this.calculateTotalDiscount()
                this.setState({
                    IndivDiscountComments: '',
                    discountDoctorFee: '',
                    discountHospitalFee: '',
                    discountPercentDoctorFee: '',
                    discountPercentDoctorFee_w_r: '',
                    discountPercentHospitalFee: '',
                    discountPercentHospitalFee_w_r: '',
                    selectedType: 'rupee',
                    totalDiscountedAmount: '',
                    totalDiscountedPercentage: '',
                    totalDiscountedPercentage_w_r: '',
                })
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    serviceIndividualDiscountPopup = () => {
        try {
            let states = this.state
            let fee = this.shareValue()
            let doctorShare = this.state.selectedType === 'rupee' ? this.state.discountDoctorFee : this.state.discountPercentDoctorFee
            let doctorShare_w_r = this.state.selectedType === 'rupee' ? this.state.discountDoctorFee : this.state.discountPercentDoctorFee_w_r
            let hospitalShare = this.state.selectedType === 'rupee' ? this.state.discountHospitalFee : this.state.discountPercentHospitalFee
            let hospitalShare_w_r = this.state.selectedType === 'rupee' ? this.state.discountHospitalFee : this.state.discountPercentHospitalFee_w_r
            return (
                <Modal open={this.state.serviceDiscountpopup}>
                    <Box className='eMed_Service_Discount'>
                        <Box m={'1vw'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Split-Up</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
                            <Box display={'flex'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600} alignSelf={'center'}>Doctor Consultation - </Typography>
                                <Typography color={'gray'} fontSize={'0.8vw'} alignSelf={'center'}>{
                                    states.patientData?.patient_type === 'new_patient' ? ` New Patient` :
                                        states.patientData?.patient_type === 'review_patient' ? ` Review Patient` :
                                            states.patientData?.patient_type === 'old_patient' ? ` Old Patient` : null
                                }</Typography>
                            </Box>
                            <Box>
                                <Typography color={'gray'}>Bill Amount</Typography>
                                <Typography fontWeight={600} color={Colors.SecondaryText}>{states.selectedData?.totalAmount ? AmountFormat((states.selectedData?.totalAmount.toFixed(2))) : AmountFormat(states.selectedData?.total_amt ? states.selectedData?.total_amt.toFixed(2) : states.selectedData?.totalAmount.toFixed(2))}</Typography>
                            </Box>
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            {/* {((+fee.doctorFee === 0) && (+fee.hospitalFee === 0)) ?
                                <Typography alignSelf={'center'} color={'red'} fontSize={'0.8vw'}>Hospital and Doctor Share Not Configured</Typography> : null} */}
                            <Typography fontWeight={600} color={Colors.SecondaryText}>Select Type:
                                <Select
                                    size='small'
                                    sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                                    value={this.state.selectedType}
                                    onChange={(e) => { this.onChangeSymbol(e) }}
                                >
                                    {Unit_Types.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item?.label}</MenuItem>
                                    ))}
                                </Select>
                            </Typography>
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'space-evenly'}>
                            {this.splitUpTextBox('discountDoctorFee', doctorShare_w_r, 'Doctor Discount', '13.5vw')}
                            {this.splitUpTextBox('discountHospitalFee', hospitalShare_w_r, 'Hospital Discount', '13.5vw')}
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
                            <Box>
                                <Typography fontSize={'0.8vw'} color={'gray'}>Doctor Share</Typography>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>
                                    {`${fee.doctorFee} - ${fee.discountDoctor ? fee.discountDoctor : 0} = ${((fee.doctorFee) - (+fee.discountDoctor)) ? AmountFormat((fee.doctorFee) - (+fee.discountDoctor)) : 0}`}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontSize={'0.8vw'} color={'gray'}>Hospital Share</Typography>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>
                                    {`${fee.hospitalFee} - ${fee.discountHospital ? fee.discountHospital : 0} = ${((fee.hospitalFee) - (+fee.discountHospital)) ? AmountFormat((fee.hospitalFee) - (+fee.discountHospital)) : 0}`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ paddingX: '0.5vw' }}>
                            <TextField
                                label={"Discount Comments"}
                                sx={{ width: '31vw' }}
                                multiline={true}
                                onChange={(e) => {
                                    this.setState({
                                        IndivDiscountComments: e.target.value
                                    })
                                }}
                                inputProps={{ maxLength: 250 }}
                                disabled={(!doctorShare && !hospitalShare)}
                                value={this.state.IndivDiscountComments}
                                rows={2} />
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-end'} m={'1vw'}>
                            <Button variant='outlined' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({
                                        serviceDiscountpopup: false,
                                        discountDoctorFee: '',
                                        discountHospitalFee: '',
                                        discountPercentDoctorFee: '',
                                        discountPercentDoctorFee_w_r: '',
                                        discountPercentHospitalFee: '',
                                        discountPercentHospitalFee_w_r: '',
                                        IndivDiscountComments: '',
                                        selectedType: 'rupee',
                                        selectedData: JSON.parse(this.state.dupliSelectedData),
                                    })
                                }}>Close</Button>
                            <Button variant='contained' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                                onClick={() => { this.onSaveDiscountAmount() }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }


    onClickAddDiscount = (item, index) => {
        try {
            let serviceFee = item?.service_fee

            let discountDoctorFee = serviceFee?.discount_doctor_fee ? serviceFee?.discount_doctor_fee : 0
            let discountHospitalFee = serviceFee?.discount_hospital_fee ? serviceFee?.discount_hospital_fee : 0
            let discountDoctorPercent = serviceFee?.discount_doctor_percentage ? serviceFee?.discount_doctor_percentage : 0
            let discountDoctorPercent_w_r = serviceFee?.discount_doctor_percentage ? CommonValidation.formatAmount(serviceFee?.discount_doctor_percentage) : 0
            let discountHospitalPercent = serviceFee?.discount_hospital_percentage ? serviceFee?.discount_hospital_percentage : 0
            let discountHospitalPercent_w_r = serviceFee?.discount_hospital_percentage ? CommonValidation.formatAmount(serviceFee?.discount_hospital_percentage) : 0

            let doctorFee = serviceFee?.doctor_fee ? serviceFee?.doctor_fee?.toFixed(2) : 0
            let hospitalFee = serviceFee?.hospital_fee ? serviceFee?.hospital_fee?.toFixed(2) : 0

            serviceFee.original_doctor_fee = (serviceFee.original_doctor_fee ? serviceFee.original_doctor_fee : +doctorFee)
            serviceFee.original_hospital_fee = (serviceFee.original_hospital_fee ? serviceFee.original_hospital_fee : +hospitalFee)
            serviceFee.share_discount = true
            this.setState({
                serviceDiscountpopup: true,
                selectedData: JSON.parse(JSON.stringify(item)),
                dupliSelectedData: JSON.stringify(item),
                selectedType: item?.service_fee?.amount_type,
                discountIndex: index,
            }, () => {
                if (this.state.selectedType === 'rupee') {
                    this.setState({
                        discountDoctorFee: discountDoctorFee,
                        discountHospitalFee: discountHospitalFee,
                    })
                } else {
                    this.setState({
                        discountPercentDoctorFee: discountDoctorPercent,
                        discountPercentDoctorFee_w_r: discountDoctorPercent_w_r,
                        discountPercentHospitalFee: discountHospitalPercent,
                        discountPercentHospitalFee_w_r: discountHospitalPercent_w_r,
                    })
                }
            })
            this.setState({
                IndivDiscountComments: item?.individual_discount_comments ? item?.individual_discount_comments : ""
            })

        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    billingCalculation = (type) => {
        let amount = 0; let payableAmount = 0;
        switch (type) {
            case 'newAmount':
                let noDiscountAmount = 0;
                this.state.opBillingList.forEach(element => {
                    noDiscountAmount = noDiscountAmount + (element.unit * +element.amount)
                })
                return AmountFormat(noDiscountAmount)
            case 'discountAmount':
                let discountedAmount = 0;
                this.state.opBillingList.forEach(element => {
                    discountedAmount = discountedAmount + (element.discountAmount)
                })
                return AmountFormat(discountedAmount);
            case 'totalServiceAmount':
                // let totalAmt = 0;
                // this.state.opBillingList.forEach(element => {
                //     totalAmt = totalAmt + ((element.service_fee?.hospital_fee || (element.service_fee?.hospital_fee === 0)) ? element.service_fee?.hospital_fee : (element.totalAmount - element.discountAmount))
                //     amount = amount + (element.totalAmount - element.discountAmount)
                //     this.state.totalServiceAmount = +amount
                //     this.state.concessionRestrictAmount = (+this.state.billConcessionType === 2) ? +totalAmt : +amount
                // })
                console.log(this.state.opBillingList, "dsfg");
                return AmountFormat(this.state.totalServiceAmount)
            case 'roundOff':
                this.state.roundOff = CommonValidation.roundOff(this.state.totalServiceAmount, this.state.totalDiscountedAmount)
                return this.state.roundOff;
            case 'payableAmount':
                let totalAmt = 0;
                this.state.opBillingList.forEach(element => {
                    if (element?.id) {
                        totalAmt = totalAmt + ((element.service_fee?.hospital_fee || (element.service_fee?.hospital_fee === 0)) ? element.service_fee?.hospital_fee : ((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude((element?.totalAmount - element.discountAmount), element?.gst_percentage) : (element?.totalAmount - element.discountAmount)))
                        amount = amount + +((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude((element?.totalAmount - element.discountAmount), element?.gst_percentage) : (element?.totalAmount - element.discountAmount))
                        this.state.totalServiceAmount = +amount
                        this.state.concessionRestrictAmount = (+this.state.billConcessionType === 2) ? +totalAmt : +amount
                    }
                })
                let discount = this.state.totalDiscountedAmount ? this.state.totalDiscountedAmount : 0
                this.state.roundOff = CommonValidation.roundOff(this.state.totalServiceAmount, this.state.totalDiscountedAmount)
                payableAmount = this.state.totalServiceAmount - discount
                this.state.payableAmount = Math.round(+payableAmount)
                return AmountFormat(this.state.payableAmount)

            default:
                break;
        }
    }

    popupClose = (data) => {
        if (data) {
            this.setState({
                isDeleteAll: false,
                ClearClicked: false
            })
        }
        this.setState({
            isDeleteCliked: false,
            deleteIndex: null,
            deleteService: ""
        })
    }

    onClearHandler = () => {
        let states = {
            openAccordion: false,
            editRate: false,
            editUnit: false,
            radDetails: {},
            overAllDiscountAmt: '',
            overAllDiscountPercent: '',
            totalBillAmt: 0,
            docSplitPopup: false,
            radList: [],
            serviceData: [],
            templateDataList: [],
            opBillingList: [lineItem], // Assuming lineItem is defined elsewhere
            errorMessages: [""],
            payableAmount: 0,
            totalServiceAmount: 0,
            discountReason: "",
            discountReasonPopup: false,
            is_gst_enabled: JSON.parse(localGetItem("is_gst_enabled")),
            totalDiscountedAmount: '',
            totalDiscountedPercentage: '',
            totalDiscountedPercentage_w_r: '',
            discountPercentDoctorFee_w_r: '',
            discountPercentHospitalFee_w_r: '',
            docPercent_w_r: '',
            hospitalPercent_w_r: '',
            concessionRestrictAmount: 0,
            isDeleteAll: false,
            ClearClicked: false,
            isDeleteCliked: false,
            deleteIndex: null,
            deleteService: ""
        };

        this.setState({
            ...states
        }, () => {
            this.getServiceListData();
            this.gettemplateData();

            let concession = localGetItem('billing_concession_type');
            let showCode = JSON.parse(localGetItem('op_ip_service_code')) ? JSON.parse(localGetItem('op_ip_service_code')) : false;

            this.setState({
                billConcessionType: concession,
                showCode: showCode
            });
        });
    }


    handleDelete = () => {
        this.state.opBillingList.splice(this.state.deleteIndex, 1)
        this.setState({
            editRate: false,
            editUnit: false,
            totalBillAmt: 0,
            docSplitPopup: false,
            opBillingList: this.state.opBillingList,
            errorMessages: [""],
            isDeleteCliked: false,
        })
    }

    TimeFieldCalculation = (item) => {
        let { opBillingList } = this.state
        let Hours = (item.hour == "" ? "00" : (item.hour).toString().length < 2 ? ("0" + item.hour) : item.hour) + ":" + (item.minute == "" ? "00" : (item.minute).toString().length < 2 ? ("0" + item.minute) : item.minute) + ":" + "00"
        item.totalAmount = this.CalculateHourAmount(item.amount, Hours, item.old_time_taken, item?.minimum_time, item?.min_amount)
        item.time_taken = Hours.split(":")[0] + ":" + Hours.split(":")[1]
        this.setState({ opBillingList }, () => {
            item.field = false
            this.setState({
                opBillingList
            })
        })
    }

    TimeFieldEnable = (Time, item) => {
        if (item.is_edit_unit && this.state.editUnit) {
            let { opBillingList } = this.state
            item.hour = Time.split(":")[0];
            item.minute = Time.split(":")[1]
            this.setState({
                opBillingList
            }, () => {
                item.field = true
                this.setState({
                    opBillingList
                })
            })
        }
    }

    buttonOneAction = () => {
        let { selectedService } = this.state;
        if (!selectedService?.procedure_start_time && !selectedService?.procedure_end_time) {
            selectedService["start_date"] = (selectedService?.changed_start_time == null ? this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date) : selectedService?.changed_start_time);
            selectedService["end_date"] = selectedService?.changed_end_time;
        } else {
            selectedService["start_date"] = selectedService?.start_date
            selectedService["end_date"] = selectedService?.end_date
        }
        this.setState({
            selectedService
        }, () => {
            this.setState({ Timepopup: false })
        })
    }

    buttonTwoAction = (item) => {
        let { opBillingList, ChangedUnit, timeFormate } = this.state
        let changedTime = ChangedUnit == 0 ? item.service_unit_count : ChangedUnit
        if (this.state?.selectedService?.end_date && this.state?.selectedService?.end_date != null && !timeFormate) {
            var timeParts = changedTime?.split(":");
            var hours = parseFloat(timeParts[0]);
            var minutes = parseFloat(timeParts[1]);
            if (hours >= 0 || minutes >= 0) {
                item.totalAmount = this.CalculateHourAmount(item.amount, changedTime, item.old_time_taken, item?.minimum_time, item?.min_amount)
                item.time_taken = changedTime
                item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
                item.discountPercentage = CommonValidation.amountPercentageWithoutRoundoff(item.discountAmount, item.totalAmount)
                item.discountPercentage_w_r = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
                item.changed_start_time = item.start_date
                item.changed_end_time = item.end_date
                this.setState({ opBillingList, Timepopup: false }, () => {
                    this.setState({
                        totalDiscountedAmount: '',
                        totalDiscountedPercentage: '',
                        totalDiscountedPercentage_w_r: '',
                    })
                    // this.calculateTotalDiscount()
                })
            } else {
                this.props?.errorMessage("Enter Correct End Date/Time", 'error')
            }
        } else {
            this.props?.errorMessage("Enter Correct End Date/Time", 'error')
        }
    }

    CalculateTimeDiff = () => {
        var startTime = new Date(this.state?.selectedService?.start_date);
        var endTime = new Date(this.state?.selectedService?.end_date);

        const startTimes = new Date(startTime);
        const endTimes = new Date(endTime);

        if (endTimes > startTimes) {
            this.setState({
                timeFormate: false
            })
        } else {
            this.setState({
                timeFormate: true
            })
        }

        startTime.setSeconds(0);
        endTime.setSeconds(0);

        var timeDiffMillis = endTime - startTime;

        var hours = Math.floor(timeDiffMillis / (1000 * 60 * 60));
        var minutes = Math.floor((timeDiffMillis % (1000 * 60 * 60)) / (1000 * 60));

        let formattedHours = hours > 9 ? hours.toString() : `0${hours}`;
        let formattedMinutes = minutes > 9 ? minutes.toString() : `0${minutes}`;

        let changedTime = `${formattedHours}:${formattedMinutes}:00`;
        this.setState({
            ChangedUnit: changedTime
        })
    }

    DateConvertion = (date) => {
        let outputDateString = date.getFullYear() + '-' +
            ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        return outputDateString
    }

    TimeConvertion = (inputDate) => {
        let outputDateString = inputDate.getFullYear() + '-' +
            ('0' + (inputDate.getMonth() + 1)).slice(-2) + '-' +
            ('0' + inputDate.getDate()).slice(-2) + 'T' +
            ('0' + inputDate.getHours()).slice(-2) + ':' +
            ('0' + inputDate.getMinutes()).slice(-2) + ':' +
            ('0' + inputDate.getSeconds()).slice(-2);
        return moment(outputDateString)
    }


    templateSelection = () => {
        let states = this.state

        return (
            <Autocomplete
                disableClearable
                openOnFocus
                value={null}
                sx={{ width: "16dvw" }}
                size="small"
                disabled={this.props?.PatientDetails === null}
                options={this.state.templateDataList?.length > 0 ? this.state.templateDataList : []}
                getOptionLabel={option => typeof (option) === "string" ? option : option?.template_name}
                onChange={(event, newValue) => {
                    if (newValue?.template_name !== "") {
                        let templateFlag = false;
                        this.state.opBillingList.forEach(element => {
                            if (newValue?.template_name === element?.template_name) {
                                templateFlag = true
                            }
                        })
                        let serviceList = []
                        newValue?.service_data.forEach((element) => {
                            let ServiceCharges = element.service_charges[0]
                            if ((element.service_catagory__catagory_name === "Consultation")) {
                                if (element.is_holiyday && (states.patientData?.patient_type !== "review_patient")) {
                                    let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                                    let newPatient = ServiceCharges?.new_patient?.total_amt;
                                    let oldPatient = ServiceCharges?.old_patient?.total_amt;
                                    let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                                    let emergencyFee = element.is_emergency ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee) : null;

                                    element.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : element.is_emergency ? emergencyFee !== '' ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee, true) : states.patientData.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient : states.patientData.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                                    element.totalAmount = holidayFee !== '' ? holidayFee : element.is_emergency ? emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "new_patient" ? +newPatient : +oldPatient : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                                    element.amount = element.totalAmount

                                } else if ((!element.is_holiyday && element.is_emergency) && (states.patientData?.patient_type !== "review_patient")) {
                                    let emergencyFee = this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee);
                                    let newPatient = ServiceCharges?.new_patient?.total_amt;
                                    let oldPatient = ServiceCharges?.old_patient?.total_amt;
                                    let reviewPatient = ServiceCharges?.review_patient?.total_amt;

                                    element.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : states.patientData.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                                    element.totalAmount = emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                                    element.amount = element.totalAmount
                                } else {
                                    let newPatient = ServiceCharges?.new_patient?.total_amt;
                                    let oldPatient = ServiceCharges?.old_patient?.total_amt;
                                    let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                                    element.service_fee = states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                                    element.totalAmount = states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                                    element.amount = element.totalAmount
                                }
                            } else if ((element.service_catagory__catagory_name === "OP Procedures")) {
                                let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                                let emergencyFee = element.is_emergency ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee) : null;
                                let serviceFee = ServiceCharges?.service_fee?.total_amt

                                if (element.is_holiyday && (states.patientData?.patient_type !== "review_patient")) {

                                    element.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : element.is_emergency ? emergencyFee !== '' ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee, true) : ServiceCharges?.service_fee : ServiceCharges?.service_fee
                                    element.totalAmount = holidayFee !== '' ? holidayFee : element.is_emergency ? emergencyFee !== '' ? emergencyFee : serviceFee : serviceFee
                                    element.amount = element.totalAmount
                                    // ====> This flow has stopped <===== //
                                    // element.amount = holidayFee !== '' ? holidayFee : serviceFee
                                    // element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                    // element.end_date = null
                                    // element.changed_start_time = null
                                    // element.changed_end_time = null
                                    // element.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : ServiceCharges?.service_fee
                                    // element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken) : holidayFee !== '' ? holidayFee : serviceFee
                                    // element.amount = element.totalAmount
                                } else if ((!element.is_holiyday && element.is_emergency) && (states.patientData?.patient_type !== "review_patient")) {

                                    element.service_fee = emergencyFee !== '' ? this.getCurrentTimeTotalAmt(ServiceCharges?.emergency_fee, true) : ServiceCharges?.service_fee
                                    element.totalAmount = emergencyFee !== '' ? emergencyFee : serviceFee
                                    element.amount = element.totalAmount
                                    // ====> This flow has stopped <===== //
                                    // element.amount = emergencyFee !== '' ? emergencyFee : serviceFee
                                    // element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                    // element.end_date = null
                                    // element.changed_start_time = null
                                    // element.changed_end_time = null
                                    // element.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : ServiceCharges?.service_fee
                                    // element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken) : emergencyFee !== '' ? emergencyFee : serviceFee
                                    // element.amount = element.totalAmount

                                } else {
                                    element.service_fee = ServiceCharges?.service_fee
                                    element.totalAmount = serviceFee
                                    element.amount = element.totalAmount
                                    // ====> This flow has stopped <===== //
                                    // element.amount = serviceFee
                                    // element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                    // element.end_date = null
                                    // element.changed_start_time = null
                                    // element.changed_end_time = null
                                    // element.service_fee = ServiceCharges?.service_fee
                                    // element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken) : serviceFee
                                    // element.amount = element.totalAmount
                                }
                            } else {
                                let otherEmergencyFee = element.is_emergency ? this.getCurrentTimeTotalAmt(ServiceCharges.emergency_fee, false, true) : null;
                                if (element.is_holiyday) {
                                    element.amount = (ServiceCharges.holiday_fee !== '') ? ServiceCharges.holiday_fee : element.is_emergency ? (otherEmergencyFee !== '') ? otherEmergencyFee : ServiceCharges.service_amount : ServiceCharges.service_amount
                                    element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                    element.end_date = null
                                    element.changed_start_time = null
                                    element.changed_end_time = null
                                    element.old_time_taken = element.time_taken
                                    element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken, element.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.holiday_fee !== '' ? ServiceCharges.holiday_fee : otherEmergencyFee !== '' ? otherEmergencyFee : ServiceCharges.service_amount
                                    element.service_fee = { doctor_fee: '', hospital_fee: '', service_amount: element.amount }
                                } else if (!element.is_holiyday && element.is_emergency) {
                                    element.amount = otherEmergencyFee !== '' ? otherEmergencyFee : ServiceCharges.service_amount
                                    element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                    element.end_date = null
                                    element.changed_start_time = null
                                    element.changed_end_time = null
                                    element.old_time_taken = element.time_taken
                                    element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken, element.minimum_time, ServiceCharges.minimum_amount) : otherEmergencyFee !== '' ? otherEmergencyFee : ServiceCharges.service_amount
                                    element.service_fee = { doctor_fee: '', hospital_fee: '', service_amount: element.amount }
                                } else {
                                    element.amount = ServiceCharges.service_amount
                                    element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                    element.end_date = null
                                    element.changed_start_time = null
                                    element.changed_end_time = null
                                    element.old_time_taken = element.time_taken
                                    element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken, element.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.service_amount
                                    element.service_fee = { doctor_fee: '', hospital_fee: '', service_amount: element.amount }
                                }
                            }
                            element.min_amount = ServiceCharges.minimum_amount ? +ServiceCharges.minimum_amount : 0
                            element.unit = "1"
                            element.discountPercentage = ""
                            element.discountPercentage_w_r = ""
                            element.discountAmount = ""
                            // element.totalAmount = +element.amount
                            element.template_name = newValue?.template_name
                            element.templateId = newValue?.id
                            element.is_auto_billing = false
                            element.isSplitBlock = false
                            element.noDiscountAmount = ""
                            element.individual_discount_comments = ""
                            element.display_name = `${element?.service_type ? element?.service_type : '-'} - ${element?.service_name ? element?.service_name : '-'} - ${element?.op_service_code ? element?.op_service_code : '-'} `

                            if (!templateFlag) {
                                serviceList.push(element)
                            } else {
                                this.props?.errorMessage("Service Template Already Exist", 'error')
                                //    this.onClearHandler()
                            }
                        })
                        let BillList = this.state.opBillingList
                        BillList = BillList.concat(serviceList).filter((list) => list?.id)
                        BillList.push(lineItem)
                        this.setState({
                            opBillingList: BillList
                        }, () => {
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: ''
                            })
                            // this.calculateTotalDiscount()
                            //    this.onClearHandler()
                        }
                        )
                    } else {
                        this.props?.errorMessage("Please, Select Service Template", 'error')
                    }

                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={"Select Template"}
                        autoComplete="off"
                        size="small"
                        disabled={this.props?.PatientDetails === null}
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiBilling_template`
                        }}
                    />
                )}
            />
        )
    }

    render() {
        console.log(this.state);
        let states = this.state
        const { t } = this.props;
        const { openAccordion, opBillingList } = this.state;
        let AppointmentDate = this.props.isFromQuickBilling ? this.formatDateToDDMMYYYY(new Date()) : this.state.patientData?.appointment_date?.split("T")[0];
        return (
            <Accordion expanded={openAccordion} sx={{ border: '1px solid #F5F8F7', marginX: "0.3vw" }}>
                <AccordionSummary sx={{
                    flexDirection: 'row-reverse',
                    '& .MuiAccordionSummary-content': {
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }
                }}
                    expandIcon={
                        <IconButton color='primary'
                            onClick={() => {
                                if (this.props?.PatientDetails === null) {
                                    this.props?.errorMessage("Select the patient for billing.", 'error')
                                } else {
                                    this.setState((prevState) => ({ openAccordion: !prevState.openAccordion }))
                                }
                            }}
                            emed_tid="emedhub_expandBtn_testID">
                            <ExpandMoreIcon />
                        </IconButton>}
                >
                    <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"}>Outpatient</Typography>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={"10px"}>
                        {this.templateSelection()}
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={"5px"}>
                            {+this.state.billConcessionType === 1 ? null : <Box component={'span'}>
                                <Tooltip placement='top' title={`Maximum Concession Should be ${CurrencySymbol} ${this.state.concessionRestrictAmount}`}>
                                    <ErrorOutlineIcon color='primary' sx={{ height: '1.3vw', width: '1.3vw' }} />
                                </Tooltip>
                            </Box>}
                            <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"}>Concession:</Typography>
                            {this.textBox('totalDiscountedPercentage', this.state.totalDiscountedPercentage_w_r, '6dvw', '', '%')}
                            {this.textBox('totalDiscountedAmount', this.state.totalDiscountedAmount, '6dvw', '', CurrencySymbol)}
                            {this.state.totalDiscountedPercentage || this.state.totalDiscountedAmount ?
                                <Tooltip placement='top' title={this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason}>
                                    <Box onClick={() => this.setState({ discountReasonPopup: true, discountReason: this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason })} component={'img'} src={ImagePaths.LabReportOff.default} width={'1.5vw'} height={'1.5vw'} sx={{ cursor: 'pointer' }} alignSelf={'center'} />
                                </Tooltip> :
                                <Box component={'img'} src={ImagePaths.BillTransUser.default} width={'1vw'} height={'1vw'} alignSelf={'center'} />
                            }
                        </Box>
                        <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"} sx={{ width: "9vw", textAlign: 'end' }}>{
                            this.billingCalculation('payableAmount')

                        }
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='rad_bill_tbl_head' sx={{ display: "flex", alignItems: "center" }} align="center">
                                    <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_all_dlt_btn'
                                        onClick={() => { this.setState({ isDeleteAll: this.state.opBillingList?.length > 0 ? true : false }) }}>
                                        <Box component={'img'} src={ImagePaths.Delete.default} className='Lab_Home_DeleteIcon' alt='Delete' />
                                    </IconButton>
                                    <Typography fontWeight={600} fontSize={'0.9dvw'}>Service Description</Typography>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align='center'>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} fontSize={'0.9dvw'}>Units</Typography>
                                        <Tooltip placement='top' title='Edit'>
                                            <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_unt_btn' onClick={() => this.setState({ editUnit: !this.state.editUnit })}>
                                                <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align="center">
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} fontSize={'0.9dvw'}>Rate Per Unit</Typography>
                                        <Tooltip placement='top' title='Edit'>
                                            <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_rate_btn'
                                                onClick={() => {
                                                    this.setState({ editRate: !this.state.editRate }, () => {
                                                        if (this.state.editRate && this.state.editDiscount) {
                                                            this.setState({
                                                                editDiscount: false
                                                            })
                                                        }
                                                    })
                                                }}
                                            >
                                                <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align="center">
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} fontSize={'0.9dvw'}>Discount</Typography>
                                        <Tooltip placement='top' title='Edit'>
                                            <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_rate_btn' onClick={() => {
                                                this.setState({ editDiscount: !this.state.editDiscount }, () => {
                                                    if (this.state.editRate && this.state.editDiscount) {
                                                        this.setState({
                                                            editRate: false
                                                        })
                                                    }
                                                })
                                            }} >
                                                <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align='right'>
                                    <Typography fontWeight={600} fontSize={'0.9dvw'}>Total Amount</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.opBillingList?.length > 0 ? this.state.opBillingList?.map((item, index) => {
                                let serviceName = `${item?.service_type ? item?.service_type : '-'} - ${item?.service_name ? item?.service_name : '-'}`
                                return (
                                    <TableRow key={index}>
                                        <TableCell sx={{ display: "flex", alignItems: "center", columnGap: "10px" }} align="center">
                                            <IconButton size='small' height={'1.2dvw'} sx={{ visibility: item?.id ? "visible" : "hidden" }}
                                                onClick={() => this.setState({ isDeleteCliked: true, deleteIndex: index, deleteService: item.service_type })}>
                                                <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2dvw'} width={'1.2dvw'} />
                                            </IconButton>
                                            {this.renderSrvSearch(index, item)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item?.time_taken == null || item?.time_taken == undefined ?
                                                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    {
                                                        this.state.editUnit && item?.is_edit_unit ?
                                                            <Box component={'div'} display={'flex'} flexDirection={'row'}>
                                                                <Button className='eMed_Bill_add_btn' emed_tid='rad_bill_dec_btn'
                                                                    onClick={() => { this.onChangeUnit(item, 'decrease', index) }}
                                                                >
                                                                    <Typography>-</Typography>
                                                                </Button>
                                                                <Typography paddingRight={"1dvw"}>{item.unit}</Typography>
                                                                <Button className='eMed_Bill_add_btn' emed_tid='rad_bill_inc_btn'
                                                                    onClick={() => { this.onChangeUnit(item, 'increase', index) }}
                                                                >
                                                                    <Typography>+</Typography>
                                                                </Button>
                                                            </Box> :
                                                            <Typography>{item?.unit ? item?.unit : 0}</Typography>
                                                    }
                                                </Box> :
                                                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    {
                                                        this.state.editUnit && item.field && item.is_edit_unit ?
                                                            <div className='eMed_Time_field'>
                                                                <OutlinedInput
                                                                    value={item?.hour}
                                                                    onChange={(e) => {
                                                                        var today = new Date();
                                                                        today.setHours(23, 59, 59, 999);
                                                                        var AptDate = DateTime.fromJSDate(today).toFormat('yyyy-MM-dd')
                                                                        var Aptime = DateTime.fromJSDate(today).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

                                                                        var startDate = this.state.patientData?.appointment_date ? this.state.patientData?.appointment_date : `${DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')}T${DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_24_WITH_SECONDS)}`;
                                                                        var endDate = (AptDate + "T" + Aptime);
                                                                        let maxHours = this.FindTimeDifference(startDate, endDate)

                                                                        if ((CommonValidation.numberOnly(e.target.value) && +e.target.value <= maxHours || +e.target.value == "")) {
                                                                            item.hour = +e.target.value;
                                                                            this.setState({ opBillingList })
                                                                        }
                                                                    }}
                                                                    style={{ width: "3.5vw", height: "1.7vw", fontSize: "0.9vw" }}
                                                                />
                                                                <OutlinedInput
                                                                    value={item?.minute}
                                                                    onChange={(e) => {
                                                                        if ((CommonValidation.numberOnly(e.target.value) && +e.target.value <= 59 || +e.target.value == "")) {
                                                                            item.minute = +e.target.value;
                                                                            this.setState({ minuteField: e.target.value })
                                                                        }
                                                                    }}
                                                                    style={{ width: "3.5vw", height: "1.7vw", fontSize: "0.9vw" }}
                                                                />
                                                                <IconButton onClick={() => { this.TimeFieldCalculation(item) }} size="small" ><img className="eMed_Tick_img" src={ImagePaths.Tick.default} alt={"Tick"} /></IconButton>
                                                            </div> :
                                                            <div style={{ display: "flex", cursor: "pointer", paddingTop: "0.25vw" }}>
                                                                <div onClick={() => { this.TimeFieldEnable(item?.time_taken, item) }} style={{ paddingRight: "0.25vw", fontSize: "0.95vw", color: this.state.editUnit ? "rgb(2, 128, 123)" : null }}>{item?.time_taken.length > 5 ? item.time_taken.slice(0, 5) : item?.time_taken}</div>
                                                                <div className='eMed_Time_cont' onClick={() => { this.setState({ Timepopup: this.state.editUnit && item.is_edit_unit ? true : false, selectedService: item, selectedIndex: index }) }}>
                                                                    {this.state.editUnit ?
                                                                        <div className='eMed_Time_cont'>
                                                                            <img className='eMed_Time_img' src={ImagePaths.HighlightedClock.default} alt='edit' />
                                                                        </div> :
                                                                        <div className='eMed_Time_cont'>
                                                                            <img className='eMed_Time_img' src={ImagePaths.NormalClock.default} alt='edit' />
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                    }
                                                </Box>
                                            }
                                        </TableCell>
                                        <TableCell align="center" >
                                            {this.state.editRate && item.is_edit_amount && ((item?.service_catagory__catagory_name === 'Consultation') || (item?.service_catagory__catagory_name === 'OP Procedures')) && ((item?.service_fee?.doctor_fee && item?.service_fee?.hospital_fee) || (item?.service_fee?.discount_doctor_fee > 0 || item?.service_fee?.discount_hospital_fee > 0) || (item?.service_fee?.doctor_percentage === 100 || item?.service_fee?.hospital_percentage === 100)) ?
                                                <>
                                                    <Button size='small' sx={{ textTransform: "capitalize" }} disabled={this.state.disableSplitSharePopup} onClick={() => { this.addShare(item, index) }}>{`Add Share ${item.amount ? `(${AmountFormat(item?.amount)}) ${(item?.gst_percentage && this.state.is_gst_enabled) ? `+ ${item?.gst_percentage} % GST` : ""}` : `${CurrencySymbol} 0.00`}`}</Button>
                                                </>
                                                : this.state.editRate && item.is_edit_amount ?
                                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        {this.inputTextBox('rateUnit', item.amount, index, "0", "0.7vw", false)}
                                                        <Typography fontSize={"0.9vw"}>{(item?.gst_percentage && this.state.is_gst_enabled) ? `+ ${item?.gst_percentage} % GST` : ""}</Typography>

                                                    </Box>
                                                    :
                                                    <Typography component={'div'} position={'relative'}>{AmountFormat(item.amount ? item.amount : 0)}</Typography>
                                            }
                                        </TableCell>
                                        <TableCell align="center" display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            {
                                                this.state.editDiscount && item.is_edit_discount && ((item?.service_catagory__catagory_name === 'Consultation') || (item?.service_catagory__catagory_name === 'OP Procedures')) && ((item?.service_fee?.doctor_fee && item?.service_fee?.hospital_fee) || (item?.service_fee?.discount_doctor_fee > 0 || item?.service_fee?.discount_hospital_fee > 0) || (item?.service_fee?.doctor_percentage === 100 || item?.service_fee?.hospital_percentage === 100)) ?
                                                    <>
                                                        <Button
                                                            startIcon={<AddCircle />}
                                                            endIcon={item?.discountAmount ? `(${AmountFormat(item?.discountAmount)})` : null}
                                                            sx={{ textTransform: 'capitalize', height: '1.5vw' }}
                                                            onClick={() => { this.onClickAddDiscount(item, index) }}
                                                        >Add Discount</Button> </>

                                                    :
                                                    this.state.editDiscount && item.is_edit_discount ?
                                                        <>
                                                            {this.inputTextBox('percentage', item.discountPercentage_w_r, index, "0 %", "0.7vw", false)}
                                                            {this.inputTextBox('discountAmount', item.discountAmount, index, `${CurrencySymbol} 0`, "0.7vw", false)}
                                                        </> :
                                                        <Typography position={'relative'}>{AmountFormat(item.discountAmount ? item.discountAmount : 0)}</Typography>
                                            }
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box component={'div'} display={'flex'} justifyContent={'flex-end'} >
                                                {(this.state.is_gst_enabled && item?.gst_percentage) ? <Tooltip placement='top' arrow title={(this.state.is_gst_enabled && item?.gst_percentage) ? `Inclusive of ${item?.gst_percentage}% GST` : ""}>
                                                    <ReportGmailerrorredIcon color='primary' sx={{ marginRight: '0.5vw' }} />
                                                </Tooltip> : null}
                                                <Typography>{AmountFormat(+((this.state.is_gst_enabled && item?.gst_percentage) ? CommonValidation.CalculateGSTInclude((item.totalAmount - item.discountAmount), item?.gst_percentage) : (item.totalAmount - item.discountAmount)).toFixed(2) || 0)}</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            }) :
                                <TableRow>
                                    <TableCell colSpan={6} align='center'>No Records To Be Shown</TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </AccordionDetails>
                {this.state.docSplitPopup ? this.splitScreenPopup() : null}
                {this.serviceIndividualDiscountPopup()}
                {this.discountReasonPopup()}
                {this.state.isDeleteAll && this.state.opBillingList.length > 0 ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")} ${t("All")} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this, "all")}
                    removeData={this.onClearHandler.bind(this)}
                /> : null}
                {this.state.isDeleteCliked ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")} ${this.state.deleteService ? this.state.deleteService : "-"} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.handleDelete.bind(this)}
                /> : null}
                {this.state.Timepopup ?
                    <Modal open={true}>
                        <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "37vw", height: "12vw" }}>
                            <div id="emedhub_popup_header" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <Tooltip placement='top' title={states?.selectedService?.service_name} arrow>
                                    <Typography sx={{ color: "#616161", fontWeight: 600 }}>{`Kindly Select Date and Time of ${states?.selectedService?.service_name?.length > 22 ?
                                        states?.selectedService?.service_name.slice(0, 20) + "..." : states?.selectedService?.service_name}`}
                                    </Typography>
                                </Tooltip>
                                <IconButton onClick={() => { this.buttonOneAction() }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                            </div>
                            <div className='emedhub_DatePicker_container'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={states?.selectedService?.start_date}
                                        name={"datePicker"}
                                        open={this.state.startTimeOpen}
                                        onOpen={() => { this.setState({ startTimeOpen: true }) }}
                                        onClose={() => { this.setState({ startTimeOpen: false }) }}
                                        inputFormat='DD-MM-YYYY hh:mm A'
                                        label={t('Start Date & Time')}
                                        minDate={AppointmentDate}
                                        maxDate={new Date()}
                                        minTime={AppointmentDate === this.DateConvertion(new Date(states?.selectedService?.start_date)) ? this.props.isFromQuickBilling ? this.formatDateToDDMMYYYY(new Date()) : moment(this.state.patientData?.appointment_date) : null}
                                        onChange={
                                            (newDate) => {
                                                opBillingList[states?.selectedIndex]["start_date"] = newDate
                                                this.setState({
                                                    opBillingList
                                                }, () => { this.CalculateTimeDiff() })
                                            }}
                                        renderInput={(params) => <TextField onClick={() => { this.setState({ startTimeOpen: true }) }} onKeyDown={(e) => { e.preventDefault() }} style={{ width: '17vw' }} size='small' {...params} />}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={states?.selectedService?.end_date ? states?.selectedService?.end_date : null}
                                        name={"datePicker"}
                                        open={this.state.endTimeOpen}
                                        onOpen={() => { this.setState({ endTimeOpen: true }) }}
                                        onClose={() => { this.setState({ endTimeOpen: false }) }}
                                        inputFormat='DD-MM-YYYY hh:mm A'
                                        label={t('End Date & Time')}
                                        minDate={states?.selectedService?.start_date}
                                        maxDate={new Date()}
                                        minTime={this.DateConvertion(new Date(states?.selectedService?.start_date)) === this.DateConvertion(new Date(states?.selectedService?.end_date)) ? this.TimeConvertion(new Date(states?.selectedService?.start_date)) : null}
                                        onChange={
                                            (newDate) => {
                                                opBillingList[states?.selectedIndex]["end_date"] = newDate ? newDate : null
                                                this.setState({
                                                    opBillingList
                                                }, () => { this.CalculateTimeDiff() })
                                            }}
                                        renderInput={(params) => <TextField onClick={() => { this.setState({ endTimeOpen: true }) }} onKeyDown={(e) => { e.preventDefault() }} style={{ width: '17vw' }} size='small' {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "1vw" }}>
                                <Button size="small" sx={{ marginRight: "1vw", textTransform: "capitalize" }} variant={"outlined"} onClick={() => { this.buttonOneAction() }}>{"Cancel"}</Button>
                                <Button variant="contained" size="small" sx={{ textTransform: "capitalize" }} onClick={() => { this.buttonTwoAction(this.state?.selectedService) }}>{"Add"}</Button>
                            </div>
                        </Paper>
                    </Modal>
                    : null
                }
            </Accordion>
        )
    }
}
export default withTranslation()(MultiOPBilling);
