import { Autocomplete, Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails, ConsultantPatientCard } from '../../../../Components/Common Components/CommonComponents'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { DataGrid } from '@mui/x-data-grid'
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { Bucket_Name } from '../../../../Utility/Constants'
import PrintPreviewPop from '../../../../Components/Common Components/PrintPreviewPop'

const Order_Type = [
    { label: 'All', value: 'All' },
    { label: 'Prescription', value: 'Prescription' },
    { label: 'Lab Test', value: 'Labtest' },
    { label: 'Radiology Test', value: 'Radiologytest' },
    { label: 'Investigation', value: 'Investigation' },
    { label: 'Glass Prescription', value: 'Glass Prescription' },
]

let AWS = require('aws-sdk');
let s3 = new AWS.S3({
    signatureVersion: 'v4',
    region: "ap-south-1",
    params: { Bucket: Bucket_Name },
});

export default class PrintOrderReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientData: {},
            withOutAppointment: {},
            printOrderData: [],
            page: 0,
            pageSize: 10,
            startDate: '',
            endDate: '',
            dateIndex: 0,
            orderTypeList: Order_Type,
            selectedOrderType: Order_Type[0],
            common_uhid: false,
            printPreviewPop: false,
            printPreviewData: '',
            pdfName: ''
        }
    }

    componentDidMount() {
        let patientData = this.props.fromIP ? JSON.parse(getCachevalue('IppatientData')) : JSON.parse(getCachevalue('patientData'))
        let appointmentDetail = JSON.parse(getCachevalue("withoutAppointment"));
        this.setState({
            patientData: patientData,
            withOutAppointment: appointmentDetail
        }, () => {
            this.getPrintOrderReportData()
        })
    }

    getPrintOrderReportData = () => {
        try {
            let query = ''
            if (this.state.patientData?.doctor_id) {
                query = `&doctor_id=${this.state.patientData?.doctor_id}`
            }
            if (this.props.fromIP) {
                query = query + `&ip_admission_id=${this.state.patientData?.id}`
            }
            let params = `?patient_id=${this.state.patientData?.patient_id}&from_date=${this.state.startDate}&to_date=${this.state.endDate}&test=${this.state.selectedOrderType?.value}${query}`
            RestAPIService.getAll(Serviceurls.FO_PRINT_ORDER + params)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            printOrderData: response.data.data,
                            common_uhid: response?.data?.common_uhid ? response?.data?.common_uhid : false,
                        })
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPrintOrderPrintData = (item) => {
        try {
            let params = ''
            if (item?.order === "Prescription") {
                item?.service_type ?
                    params = Serviceurls.OPT_PRESCRIPTION_PRINT + `?prescription_id=${item?.prescription_id}&language=${"english"}&doctor_id=${item?.doctor_id}&appointment_id=${item?.appointment_id}`
                    :
                    params = Serviceurls.PRINT_PRESCRIPTION + `?prescription_id=${item?.prescription_id}&language=${"english"}&doctor_id=${item?.doctor_id}&appointment_id=${item?.appointment_id}`
            } else if (item?.order === "Radiologytest") {
                params = Serviceurls.RAD_RESULT_PRINT + `?id=${item?.id ? item?.id : null}&radiology_id=${item?.radiology_id ? item?.radiology_id : null}&export_type=pdf`
            } else {
                params = Serviceurls.DOC_PRINT_ORDER_REPORT + `?patient_id=${item?.patient_id}&appointment_id=${item?.appointment_id}&from_date=${this.state.startDate}&to_date=${this.state.endDate}&test=${item?.order}&export_type=pdf`
            }
            // let params = `?patient_id=${this.state.patientData?.patient_id}&appointment_id=${item?.appointment_id}&from_date=${this.state.startDate}&to_date=${this.state.endDate}&test=${item?.order}&export_type=pdf`

            RestAPIService.getAllTwo(params)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    async getS3Key(doc_key, doc_name) {
        try {
            const response = await RestAPIService.getAllTwo(Serviceurls.AWS_URL_GET + "?doc_key=" + encodeURIComponent(doc_key));
    
            if (response.data.status === "success") {
                const Data = response.data;
                this.setState({
                    printPreviewPop: true,
                    printPreviewData: Data.data,
                    pdfName: doc_name ? doc_name : 'PDF Preview'
                });
                return(Data.data)
            }
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: "Error fetching S3 URL"
            });
        }
    }

    closePrintPreview = () => {
        this.setState({
            printPreviewPop: false,
            printPreviewData: '',
            pdfName: ''
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    filterDate = (From, To, Index) => {
        this.setState({
            startDate: From,
            endDate: To,
            dateIndex: Index
        })
    }

    onClearPrintOrder = () => {
        try {
            this.setState({
                selectedOrderType: Order_Type[0],
                startDate: '',
                endDate: '',
                dateIndex: 0
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRightScreen = () => {
        try {
            return (
                <Box component={'div'} height={this.props.fromIP ? '62vh' : '68vh'}>
                    <Box component={'div'} height={'2.5rem'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Print Order Report</Typography>
                    </Box>
                    <Box component={'div'} height={this.props.fromIP ? '50vh' : '56vh'} sx={{ background: Colors.white }} p={'1vw'}>
                        <Box component={'div'} width={'21vw'}>
                            <CommonDatePicker
                                FromDate={this.state.startDate}
                                ToDate={this.state.endDate}
                                DateIndex={this.state.dateIndex}
                                SelectedDates={this.filterDate.bind(this)}
                            // HideAllMenu={true}
                            />
                        </Box>
                        <Box mt={'1vw'}>
                            <Autocomplete
                                clearIcon
                                size='small'
                                sx={{ width: '20vw' }}
                                autoComplete='off'
                                defaultValue={'All'}
                                options={this.state.orderTypeList}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.label}
                                value={this.state.selectedOrderType}
                                onChange={(event, newValue) => this.setState({ selectedOrderType: newValue })}
                                renderInput={(params) => <TextField {...params} label='Select Order Type' />}
                            />
                        </Box>
                    </Box>
                    <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3rem'}>
                        <Button
                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                            variant='outlined'
                            onClick={() => this.onClearPrintOrder()}
                        >Clear</Button>
                        <Button
                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                            variant='contained'
                            onClick={() => this.getPrintOrderReportData()}
                        >Search</Button>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderLeftScreen = () => {
        try {
            this.state.printOrderData?.forEach((element, index) => element.sno = index + 1)
            const columns = [
                { field: 'appointment_date', headerName: `${this.props.fromIP ? "Date & Time" : "Appointment Date & Time"}`, flex: 0.25 },
                { field: 'doctor_name', headerName: 'Doctor Name', flex: 0.25 },
                { field: 'order', headerName: 'Order Type', flex: 0.25 },
                {
                    field: 'action', headerName: 'Action', flex: 0.25,
                    renderCell: ({ row }) => (
                        <Box>
                            <Button sx={{ textTransform: 'capitalize' }} className='Common_Btn_Min_Width'
                                onClick={() => this.getPrintOrderPrintData(row)}
                            >Print</Button>
                            {(row?.order === "Radiologytest" && row?.result_document?.length > 0) ?
                                <Tooltip placement="top" title={"View Document"} arrow><IconButton onClick={() => { this.getS3Key(row?.result_document[0]?.document_file, row?.result_document[0]?.document_name) }}><Box component={'img'} src={ImagePaths.LabViewButton.default} height={'1.5vw'} width={'1.5vw'} /></IconButton></Tooltip>
                                : null}
                        </Box>), disableExport: true
                },
            ]
            let branchFeild = {
                field: 'branch_name', headerName: 'Branch Name', flex: 0.20, renderCell: ({ row }) => {
                    return (<div>{row?.branch_name?.length > 18 ? <Tooltip placement="top" title={row?.branch_name} arrow><div>{row?.branch_name.slice(0, 15) + "..."}</div></Tooltip> : row.branch_name ? row.branch_name : "-"}</div>)
                }
            }
            if (this.state.common_uhid) {
                columns.splice(2, 0, branchFeild)
            }
            return (
                <Box component={'div'} height={this.props.fromIP ? '62vh' : '68vh'}>
                    <DataGrid
                        columns={columns}
                        rows={this.state.printOrderData}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        sx={{ backgroundColor: Colors.white }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Box className='eMed_conf_nodata'>
                                    {'No Records To Be Shown'}
                                </Box>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        let data = {
            lineOne: "Emergency Contact Person",
            lineTwo: `${this.state.patientData?.attender_name ? this.state.patientData?.attender_name : "-"}`,
            lineFour: 'Emergency Mobile No',
            LineFive: `${this.state.patientData?.attender_mobile ? this.state.patientData?.attender_mobile : "-"}`
        }
        let consultant = {
            lineOne: "Consultant Name",
            lineTwo: `${this.state.patientData?.doctor_name ? this.state.patientData?.doctor_name : '-'}`,
        }
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={this.props.fromIP ? '77vh' : '79vh'}>
                <Box component={'div'}>
                    <Box component={'div'} pl={'0.8vw'} pt={'0.1vw'} display={'flex'}>
                        <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
                            <CommonPatientDetails data={this.state.patientData} showDetailed={this.props.fromIP ? true : false} />
                        </Box>
                        <CommonPatientCard details={data} showDetailed={true} />
                        {this.props.fromIP ? <ConsultantPatientCard name={consultant} /> : null}
                    </Box>
                    <Box component={'div'} p={'0.5vw 0.8vw'} display={'flex'} gap={'0.5vw'}>
                        <Box component={'div'} flex={0.7}>
                            {this.renderLeftScreen()}
                        </Box>
                        <Box component={'div'} flex={0.3} border={'1px solid lightgray'}>
                            {this.renderRightScreen()}
                        </Box>
                    </Box>
                </Box>
                {this.state.printPreviewPop ?
                    <PrintPreviewPop
                        Data={this.state.printPreviewData}
                        fromRadTest={true}
                        Title={this.state.pdfName}
                        BillData={this.state.selectedRow}
                        ClosePopUp={() => { this.closePrintPreview() }}
                        history={this.props.history}
                    /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
