import { Box, Button, Drawer, Tooltip, Autocomplete, Stack, Typography, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import './RadRpts.css'
import { Colors } from '../../../Styles/Colors'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { DataGrid } from '@mui/x-data-grid'
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DateTime } from 'luxon'
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { formatDate } from "../../../Components/CommonFunctions/CommonFunctions";
import { withTranslation } from 'react-i18next';

const RadTatReport = (props) => {
    const [state, setState] = useState({
        tatData: [],
        tatTime: 0,
        page: 0,
        pageSize: 10,
        isLoader: false,
        filterDatas: {
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            serviceName: null,
            serviceType: null,
            serviceNameList: [],
            serviceTypeList: []
        },
        filterOpen: false,
        triggerAction: null,
        isErrorMsg: false,
        isErrorMsgText: "",
        disableBtn: false,
    })

    const { t } = props;

    useEffect(() => {
        getTatReport();
        getServiceConfigData();
    }, []);

    useEffect(() => {
        if (state.triggerAction) {
            getTatReport();
            setState((prevState) => ({
                ...prevState,
                triggerAction: null
            }))
        }
    }, [state.triggerAction]);

    const errorMessage = (message) => {
        setState((prevState) => ({
            ...prevState,
            isErrorMsg: true,
            isErrorMsgText: message
        }))
    }

    const msgClose = () => {
        setState((prevState) => ({
            ...prevState,
            isErrorMsg: false,
            isErrorMsgText: "",
            successMsg: false,
            successMsgText: "",
        }))
    }

    const setDisableBtn = (key) => {
        setState((prevState) => ({
            ...prevState,
            disableBtn: key
        }))
    }

    const LoaderFunction = (key) => {
        setState((prevState) => ({
            ...prevState,
            isLoader: key
        }))
    }

    // api calls
    const generateUrl = () => {
        const { filterDatas } = state;

        return Serviceurls.RAD_TAT_REPORT +
            `?from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}&service_type=${filterDatas.serviceType || ""}&service_name=${filterDatas.serviceName || ""}`
    }

    const getTatReport = () => {
        try {
            LoaderFunction(true);
            const url = generateUrl();
            RestAPIService.getAll(url)
                .then(response => {
                    if (response.data.status === "success") {
                        LoaderFunction(false);
                        const Data = response.data;
                        Data?.data?.forEach((element, index) => {
                            element["sno"] = index + 1;
                            element["display_name"] = `${element?.service_type} - ${element?.service_name}`
                        })
                        setState((prevState) => ({
                            ...prevState,
                            tatData: Data?.data || [],
                            tatTime: Data?.total_hours || 0,
                        }))
                    }
                }).catch(e => {
                    LoaderFunction(false);
                    errorMessage(e?.response?.data?.message || e?.message)
                })

        } catch (e) {
            LoaderFunction(false);
            errorMessage(e.message)
        }
    }

    const getServiceConfigData = () => {
        try {
            RestAPIService.getAll(Serviceurls.RAD_SERVICE_CONFIG)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const filter_data = response?.data?.data?.reduce((acc, cur) => {
                            acc?.serviceTypeData?.add(cur?.service_type);
                            cur?.data?.forEach(item => acc?.serviceNameData?.add(item?.service_name));
                            return acc;
                        }, {
                            serviceNameData: new Set(),
                            serviceTypeData: new Set()
                        })
                        setState((prevState) => ({
                            ...prevState,
                            filterDatas: {
                                ...prevState.filterDatas,
                                serviceNameList: [...filter_data.serviceNameData],
                                serviceTypeList: [...filter_data.serviceTypeData]
                            }
                        }))
                    }
                }).catch((error) => {
                    errorMessage(error?.response?.data?.message || error.message)
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    const getTatReportPrint = () => {
        if (state.disableBtn) return;
        try {
            const url = generateUrl();
            setDisableBtn(true);
            RestAPIService.getAll(url + "&export_type=pdf")
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setDisableBtn(false);
                }).catch((error) => {
                    errorMessage(error.message)
                    setDisableBtn(false);
                })
        } catch (error) {
            errorMessage(t("PrintError"))
            setDisableBtn(false);
        }
    }

    const getTatReportexcel = () => {
        if (state.disableBtn) return;
        try {
            const url = generateUrl();
            setDisableBtn(true);
            RestAPIService.excelGet(url + "&export_type=excel")
                .then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' });
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        setDisableBtn(false);
                    }
                }).catch((error) => {
                    errorMessage(error.message)
                    setDisableBtn(false);
                })
        } catch (error) {
            errorMessage(t("PrintError"))
            setDisableBtn(false);
        }
    }

    const handleStateChange = (statekey, value) => {
        setState((prevState) => ({
            ...prevState,
            [statekey]: value
        }))
    }

    // filter starts
    const closeFilter = (fromSrch) => {
        setState((prevState) => ({
            ...prevState,
            filterOpen: false,
            triggerAction: fromSrch ? "search" : null
        }))
    }

    const FilterDate = (from, to, index) => {
        setState((prevState) => ({
            ...prevState,
            filterDatas: {
                ...prevState.filterDatas,
                fromDate: from,
                toDate: to,
                dateIndex: index
            }
        }))
    }

    const renderAutoComplete = (label, statekey, list) => {
        return (
            <Box className='eMed_rts_dropdown'>
                <Autocomplete
                    size='small'
                    options={list}
                    getOptionLabel={(option) => (option)}
                    value={state.filterDatas[statekey]}
                    onChange={(event, newValue) => {
                        setState((prevState) => ({
                            ...prevState,
                            filterDatas: {
                                ...prevState.filterDatas,
                                [statekey]: newValue
                            }
                        }))
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                        />
                    )}
                />
            </Box>)
    }

    const renderFilterScreen = () => {
        const { filterDatas } = state;
        const { t } = props;

        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={closeFilter} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={filterDatas.fromDate}
                            ToDate={filterDatas.toDate}
                            DateIndex={filterDatas.dateIndex}
                            SelectedDates={FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    {renderAutoComplete(t("Select Service Type"), "serviceType", filterDatas.serviceTypeList)}
                    {renderAutoComplete(t("Select Service Name"), "serviceName", filterDatas.serviceNameList)}
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            setState((prevState) => ({
                                ...prevState,
                                filterDatas: {
                                    ...prevState.filterDatas,
                                    fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                    toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                    dateIndex: 1,
                                    serviceName: null,
                                    serviceType: null,
                                },
                                triggerAction: "clear"
                            }));
                        }}
                        disabled={state.triggerAction === "clear"}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        disabled={state.triggerAction === "search"}
                        onClick={() => closeFilter(true)}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }
    // filter ends

    const gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true}/>
            </Box>
        )
    }

    const columns = [
        {
            field: "first_name", headerName: "Patient Details", flex: 0.2,
            renderCell: ({ row }) => (<Box component={'div'}>
                <CommonPatientDetails disablePhoto={true} data={row} />
            </Box>)
        },
        {
            field: 'display_name', headerName: 'Service Type & Name', flex: 0.16,
            renderCell: ({ row }) => (<Box component={'div'}>
                    {row?.display_name?.length > 25 ? <Tooltip placement='top' title={row?.display_name} arrow>
                        <div>{row?.display_name?.slice(0, 25) + '...'}</div></Tooltip> :
                        row?.display_name ? row?.display_name : '-'}
                </Box>) 
        },
        {
            field: 'invoice_date', headerName: 'Bill Date & Time', flex: 0.16, headerAlign: "center", align: "center",
            renderCell: ({ row }) => (<Box>{row?.invoice_date ? row?.invoice_date : '-'}</Box>)
        },
        {
            field: 'complete_date', headerName: 'Completed Date & Time', flex: 0.16, headerAlign: "center", align: "center",
            renderCell: ({ row }) => (<Box>{row?.complete_date ? row?.complete_date : '-'}</Box>)
        },
        {
            field: 'dispatch_date', headerName: 'Dispatched Date & Time', flex: 0.16, headerAlign: "center", align: "center",
            renderCell: ({ row }) => (<Box>{row?.dispatch_date ? row?.dispatch_date : '-'}</Box>)
        },
        {
            field: 'turn_around_time', headerName: 'Turn Around Time', flex: 0.16, headerAlign: "center", align: "center",
            renderCell: ({ row }) => (<Box>{row?.turn_around_time ? row?.turn_around_time : '-'}</Box>)
        }]

    return (
        <Box component={'div'}>
            <Box component={'div'} className='rad_Rpts_container' sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='rad_rpts_header_card'>
                    <Box component={'div'} className='rad_rpts_cards'>
                        {AmountsCard("Avg TAT Time", `${state.tatTime}`, true)}
                    </Box>
                    <Box component={'div'} className='rad_rpts_btns'>
                        <Tooltip placement='top' title={'Filter'} arrow>
                            <Button className='Common_Btn_Min_Width' sx={{ height: '2vw' }} emed_tid='rad_rpts_flt_pat'
                                onClick={() => handleStateChange("filterOpen", true)}
                            >
                                <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
                            </Button>
                        </Tooltip>
                        <Tooltip placement='top' title={'Print'} arrow>
                            <Button className='Common_Btn_Min_Width' sx={{ height: '2vw' }} emed_tid='rad_rpts_prt_pat'
                                disabled={state.disableBtn} onClick={() => getTatReportPrint()}
                            >
                                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
                            </Button>
                        </Tooltip>
                        <Tooltip placement='top' title={'Export'} arrow>
                            <Button className='Common_Btn_Min_Width' sx={{ height: '2vw' }} emed_tid='rad_rpts_exp_pat'
                                disabled={state.disableBtn} onClick={() => getTatReportexcel()}
                            >
                                <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} />
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
                <Box component={'div'} className='rad_rpts_content_card'>
                    <DataGrid
                        sx={{ backgroundColor: Colors.white }}
                        columns={columns}
                        rows={state.tatData}
                        getRowId={(row) => row['sno']}
                        page={state.page}
                        pageSize={state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: gridToolBar,
                            NoRowsOverlay: () => (
                                <Box className='eMed_conf_nodata'>
                                    {'No Records To Be Shown'}
                                </Box>
                            )
                        }}
                        onPageChange={(newPage) => handleStateChange("page", newPage)}
                        onPageSizeChange={(newPageSize) => handleStateChange("pageSize", newPageSize)}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={state.isLoader}
                    />
                </Box>
            </Box>
            {state.isErrorMsg && <ToastMsg
                severity={'error'}
                msg={state.isErrorMsgText}
                msgPop={msgClose.bind(this)}
            />}

            <Drawer
                anchor={'right'}
                open={state.filterOpen}
                ModalProps={{ onBackdropClick: closeFilter }}
            >
                {renderFilterScreen()}
            </Drawer>
        </Box>
    )
}

export default withTranslation()(RadTatReport)